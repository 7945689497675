import React, { Component } from 'react';
import { Form, Modal, Button, InputGroup, Row, Badge} from 'react-bootstrap';
import I18n from 'i18n-js';
import AvainiaCore from 'avainia-core-api';
import { Upload } from '../../multiview/Icon/Icon.js';
import Error from '../../multiview/Error/Error.js';
import Loading from '../../multiview/Loading/Loading.js';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';

const baseState = {
  manufacturer: '',
  name: '',
  code: '',
  description: '',
  price: 0,
  measurements: '',
  external_link: '',
  loss_percentage: 0,
  package_size: 0,
  tags: [],
  product_photo: undefined,
  product_context_photo: undefined,
  product_photo_preview: '',
  product_context_photo_preview: '',
  error: false,
  loading: false,
};

class CreateProductModal extends Component {
  constructor(props) {
    super(props);

    let statevars = {...baseState};
    let dupe = props.productToDuplicate;
    if(dupe){
      statevars.manufacturer = dupe.manufacturer;
      statevars.name = dupe.name;
      statevars.code = dupe.code;
      statevars.description = dupe.description;
      statevars.price = dupe.price;
      statevars.measurements = dupe.measurements;
      statevars.external_link = dupe.external_link;
      statevars.loss_percentage = dupe.loss_percentage;
      statevars.package_size = dupe.package_size;
      statevars.tags = dupe.tags;
    }

    this.state = {
      ...statevars
    };
    
  }

  onChange = (e) => { this.setState({ [e.target.name]: e.target.value }); }

  handleImageChange = (e) => {
    if (e.target.files[0]) {
      const { name } = e.target;
      const reader = new FileReader();
      const file = e.target.files[0];

      reader.onloadend = () => {
        this.setState((prevState) => ({
          [name]: file,
          [`${name}_preview`]: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    }
  }

  save = (e) => {
    if (this.state.loading) { return; }

    const tagsArr = [];
    this.state.tags.map((tag) => {
      return tagsArr.push(tag.id);
    });
    this.setState({ loading: true, error: false }, () => {
      const payload = {
        product_category_id: this.props.selectedCategory.id,
        manufacturer: this.state.manufacturer,
        name: this.state.name,
        code: this.state.code,
        description: this.state.description,
        price: this.state.price,
        measurements: this.state.measurements,
        external_link: this.state.external_link,
        loss_percentage: this.state.loss_percentage,
        package_size: this.state.package_size,
        tags: tagsArr
      };

      if (this.state.product_photo) { payload.product_photo = this.state.product_photo; }
      if (this.state.product_context_photo) { payload.product_context_photo = this.state.product_context_photo; }

      const formData = new FormData();

      Object.keys(payload).forEach((key) => {
        formData.append(key, payload[key]);
      });

      const api = new AvainiaCore(LocalStorageService.getToken);
      api.productsCreate(formData).then((result) => {
        if (result.error) { return this.setState({ error: result.error, loading: false }); }
        return this.setState(baseState, () => {
          this.props.successCallback(result);
          this.props.onHide();
        });
      });
    });
  }

  productTagAdd = (e) => {
    // eslint-disable-next-line eqeqeq
    const productTag = this.props.productTags.find((x) => x.id == e.target.value);

    // in case option 0 is selected
    if (productTag === undefined) {
      return false;
    }
    // eslint-disable-next-line eqeqeq
    if (this.state.tags.find((x) => x.id == e.target.value)) {
      return false;
    }
    this.setState((prevState) => ({
        tags: [
          ...prevState.tags,
          {
            id: productTag.id,
            name: productTag.name,
          },
        ],

    }));
  }

  productTagHide = (value) => {
    let selectedProductTags = this.state.tags;
    selectedProductTags = selectedProductTags.filter((x) => x.id !== value);

    this.setState({
        tags: selectedProductTags,
    });
  }

  render() {
    if (this.state.loading) { return <Loading inline />; } // TODO remove because modal?

    return <Modal show={true} onHide={this.props.onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{ I18n.t('views.products.create-new-product') }</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        { this.state.error && <Error error={ this.state.error } inline /> }

        <Form.Group controlId="form-manufacturer">
          <Form.Label>{ I18n.t('views.products.manufacturer') }</Form.Label>
          <Form.Control type="text" name="manufacturer" value={ this.state.manufacturer } onChange={ this.onChange } isInvalid={ !this.state.manufacturer } />
        </Form.Group>
        <Form.Group controlId="form-name">
          <Form.Label>{ I18n.t('views.products.name') }</Form.Label>
          <Form.Control type="text" name="name" value={ this.state.name } onChange={ this.onChange } isInvalid={ !this.state.name } />
        </Form.Group>
        <Form.Group controlId="form-code">
          <Form.Label>{ I18n.t('views.products.code') }</Form.Label>
          <Form.Control type="text" name="code" value={ this.state.code } onChange={ this.onChange } isInvalid={ !this.state.code } />
        </Form.Group>
        <Form.Group controlId="form-description">
          <Form.Label>{ I18n.t('views.products.description') }</Form.Label>
          <Form.Control as="textarea" name="description" value={ this.state.description } onChange={ this.onChange } rows="3" />
        </Form.Group>
        <Form.Group controlId="form-price">
          <Form.Label>{ I18n.t('views.products.price') }</Form.Label>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text id="inputGroupPrepend">€</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control type="text" name="price" value={ this.state.price } onChange={ this.onChange } isInvalid={ isNaN(this.state.price) } />
          </InputGroup>
        </Form.Group>
        <Form.Group controlId="form-measurements">
          <Form.Label>{ I18n.t('views.products.measurements') }</Form.Label>
          <Form.Control type="text" name="measurements" value={ this.state.measurements } onChange={ this.onChange } />
        </Form.Group>
        <Form.Group controlId="form-external_link">
          <Form.Label>{ I18n.t('views.products.external_link') }</Form.Label>
          <Form.Control type="text" name="external_link" value={ this.state.external_link } onChange={ this.onChange } />
        </Form.Group>
        <Form.Group controlId="form-loss-percentage">
          <Form.Label>{ I18n.t('views.products.loss_percentage') }</Form.Label>
          <Form.Control type="text" name="loss_percentage" value={ this.state.loss_percentage } onChange={ this.onChange } />
        </Form.Group>
        <Form.Group controlId="form-package-size">
          <Form.Label>{ I18n.t('views.products.package_size') } (m2/m)</Form.Label>
          <Form.Control type="text" name="package_size" value={ this.state.package_size } onChange={ this.onChange } />
        </Form.Group>
        <Form.Group controlId="form-category">
          <Form.Label>{ I18n.t('views.products.productcategory') }</Form.Label>
          <Form.Control type="text" value={ this.props.selectedCategory.name } readOnly />
        </Form.Group>
        <Form.Group controlId="form-tags">
          <Form.Label column sm="2">{I18n.t('views.products.producttags-label')}</Form.Label>
          <Form.Control as="select" onChange={ this.productTagAdd }>
              <option key={0} value={null}>{I18n.t('views.products.select-tag')}</option>
              { this.props.productTags.map((tag) => <option key={ tag.id } value={ tag.id }>{ tag.name }</option>) }
          </Form.Control>
          <Row style={ { marginLeft: '0', marginBottom: '1rem' } }>
              {this.state.tags && this.state.tags.map((selectedProductTag) => {
                const productTag = this.state.tags.find((x) => x.id === selectedProductTag.id);
                return (<>
                    <Badge pill variant="secondary" style={ { lineHeight: '0', padding: '0.4rem', margin: '0.1rem 0.25rem 0.1rem 0.25rem' } }>
                        { productTag.name }
                        <Button variant="secondary" style={ { padding: '0.4rem', margin: '0 0 0 0.2rem', borderRadius: '50%', lineHeight: '0.75' } } onClick={ () => { this.productTagHide(selectedProductTag.id); } }>X</Button>
                    </Badge>
                  </>);
              })
              }
          </Row>
        </Form.Group>
        <Form.Group>
          <Form.Control type="file" id="product_photo" className="inputfile" onChange={ this.handleImageChange } name="product_photo" />
          <label htmlFor="product_photo" className="wide"><Upload /> { I18n.t('general.choose-photo') }</label>
          { this.state.product_photo_preview && <img className="product-preview-image" alt="preview" src={ this.state.product_photo_preview } /> }
        </Form.Group>
        <Form.Group>
          <Form.Control type="file" id="product_context_photo" className="inputfile" onChange={ this.handleImageChange } name="product_context_photo" />
          <label htmlFor="product_context_photo" className="wide"><Upload /> { I18n.t('general.choose-context-photo') }</label>
          { this.state.product_context_photo_preview && <img className="product-preview-image" alt="preview" src={ this.state.product_context_photo_preview } /> }
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={ this.save } data-todo="enable/disable based on validation">{I18n.t('general.modal-form-save')}</Button>
        <Button variant="secondary" onClick={this.props.onHide}>{I18n.t('general.modal-close')}</Button>
      </Modal.Footer>
    </Modal>;
  }
}

export default CreateProductModal;
