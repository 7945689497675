import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Form, Button, Badge } from 'react-bootstrap';
import I18n from 'i18n-js';
import AvainiaCore from 'avainia-core-api';
import { Edit, Trash, Upload } from '../../multiview/Icon/Icon.js';
import ApartmentModal from './ApartmentModal.js';
import AvainiaTable from '../../multiview/AvainiaTable/AvainiaTable.js';
import Error from '../../multiview/Error/Error.js';
import Success from '../../multiview/Success/Success.js';
import Loading from '../../multiview/Loading/Loading.js';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';
import apartments_csv from '../../../importExamples/apartments.csv';

const Modals = {
  apartment: 1,
};

class CondominiumApartmentsTab extends Component {
  constructor(props) {
    super(props);

    let error = false;
    let success = false;
    const t = this.props.apartmenttemplates;
    if (typeof t === 'undefined' || !t.length) {
      error = 16;
    }

    this.state = {
      apartments: [],
      stairwell: '',
      apartment_number: '',
      apartment_template_id: error ? -1 : t[0].id,
      redirect: false,
      modalize: false,
      modal: false,
      secondaryLoading: false,
      secondaryError: false,
      loading: true,
      error,
      success,
      requiredError: false,
      apartmentCSV: null,
    };
  }

  componentDidMount() {
    const { id } = this.props.condominium;
    const api = new AvainiaCore(LocalStorageService.getToken);

    api.condominiumApartmentsGet(id).then((apartments) => {
      if (apartments.error) { return this.setState({ error: apartments.error }); }

      this.setState({ apartments, loading: false });
    });
  }

  apartmentCSVChange = (e) => {
    this.setState({ apartmentCSV: null });
    if (e.target.files[0] && e.target.files[0].name.match(/.(csv)$/i)) {
      const reader = new FileReader();
      const file = e.target.files[0];

      reader.onloadend = () => {
        this.setState({
          apartmentCSV: file
        });
      };

      reader.readAsDataURL(file);
    }
  }

  uploadCSV = () => {
    const formData = new FormData();
    let data = this.state.apartmentCSV;
    formData.append('csv_data', data);
    formData.append('condominium', this.props.condominium.id);
    let _this = this;

    const api = new AvainiaCore(LocalStorageService.getToken);
    api.csvImport('apartments', formData).then((res) => {
      console.log(res);
      if(res.code === 200){
        let url = '/apartments';
        window.location = url;
      } else {
        _this.setState({error: 'import-error'});
      }
    });
  }

  // Natural sorting of stairwell + apartment number by ChatGPT
  naturalSort = (arr) => {
    const re = /(\d+)|(\D+)/g;
    arr.sort((a, b) => {
      const aParts = String(a.stairwell + a.apartment_number).match(re);
      const bParts = String(b.stairwell + b.apartment_number).match(re);

      while (aParts.length > 0 && bParts.length > 0) {
        const [aPart, bPart] = [aParts.shift(), bParts.shift()];

        if (/^\d+$/.test(aPart) && /^\d+$/.test(bPart)) {
          const [numA, numB] = [parseInt(aPart, 10), parseInt(bPart, 10)];
          if (numA !== numB) return numA - numB;
        } else {
          if (aPart !== bPart) return aPart < bPart ? -1 : 1;
        }
      }
      return aParts.length - bParts.length;
    });
  }

  create = () => {
    if (this.state.loading) { return; }

    if (!this.state.stairwell | !this.state.apartment_number || !this.state.apartment_template_id) {
      this.setState({ error: 35, requiredError: I18n.t('general.required') });
      return;
    }

    const payload = {
      stairwell: this.state.stairwell,
      apartment_number: this.state.apartment_number,
      apartment_template_id: this.state.apartment_template_id,
    };

    const api = new AvainiaCore(LocalStorageService.getToken);
    api.condominiumApartmentCreate(this.props.condominium.id, payload).then((apartment) => {
      if (apartment.error) { return this.setState({ error: apartment.error }); }

      const success = I18n.t('success.creation', {
        stairwell: apartment.stairwell,
        apartment_number: apartment.apartment_number
      });

      const { apartments } = this.state;
      apartments.push(apartment);
      this.naturalSort(apartments);

      this.setState({ stairwell: '', apartment_number: '', apartments, loading: false, requiredError: false, error: false , success});
    });
  }

  delete = (targetId) => {
    if (this.state.loading) { return; }
    // TODO: Confirm
    this.setState({ loading: 1, error: false }, () => {
      const api = new AvainiaCore(LocalStorageService.getToken);
      api.condominiumApartmentDelete(this.props.condominium.id, targetId).then((result) => {
        if (result.error) { return this.setState({ error: result.error }); }

        window.location.reload(); // TODO: Improve
      });
    });
  }

  hideModal = (e) => { this.setState({ modal: false }); }

  activateAptModal = (e) => { this.setState({ modal: Modals.apartments }); }

  onChange = (e) => { this.setState({ [e.target.name]: e.target.value }); }

  modalize = (apartment) => { this.setState({ modal: Modals.apartment, modalize: apartment }); }

  getApartmentTemplateNameById = (id) => {
    // eslint-disable-next-line eqeqeq
    const target = this.props.apartmenttemplates.find((x) => x.id == id);
    return target ? target.name : '';
  }

  renderActions = (cell, row) => {
    return <>
      <Trash data-todo="TODO: CONFIRM" onClick={ () => { this.delete(row.id); } } />
      <Edit onClick={ () => { this.modalize(row.id); } } />
    </>;
  }

  render() {
    if (this.state.loading) { return <Loading inline />; }
    if (this.state.navigate) { return <Redirect to={ `/condominium/${this.props.condominium.id}/apartments/${this.state.navigate}` } push={ true } />; }

    return <div className="App-container">
      { this.state.error && <Error inline error={ this.state.error } /> }
      <h1>{ I18n.t('views.condominiums.tab-apartments') }</h1>

      <AvainiaTable data={ this.state.apartments } keyField="id" rowClickHandler={ (e, rowData) => { this.setState({ navigate: rowData.id }); } } columns={ [
        { dataField: 'id', text: I18n.t('general.id'), headerStyle: { width: '60px' } },
        { dataField: 'stairwell', text: I18n.t('views.condominiums.apartments.stairwell') },
        { dataField: 'apartment_number', text: I18n.t('views.condominiums.apartments.apartment_number') },
        { dataField: 'apartment_template_id', text: I18n.t('views.condominiums.apartments.apartment_template_id'), formatter: (cell, row) => <>{ this.getApartmentTemplateNameById(row.apartment_template_id) }</> },
        { dataField: 'actions', text: I18n.t('general.table-actions'), headerStyle: { width: '100px' }, formatter: this.renderActions },
      ] } />
      { this.state.success && <Success inline success={ this.state.success } /> }

      <b>{ I18n.t('views.condominiums.apartments.stairwell') }</b>
      <Form.Control type="text" onChange={ this.onChange } name="stairwell" value={ this.state.stairwell } />
      { this.state.requiredError && <div><Badge variant="danger">{ this.state.requiredError }</Badge></div> }

      <b>{ I18n.t('views.condominiums.apartments.apartment_number') }</b>
      <Form.Control type="text" onChange={ this.onChange } name="apartment_number" value={ this.state.apartment_number } />
      { this.state.requiredError && <div><Badge variant="danger">{ this.state.requiredError }</Badge></div> }

      <b>{ I18n.t('views.condominiums.apartments.apartment_template_id') }</b>
      <Form.Control as="select" onChange={ this.onChange } name="apartment_template_id">
        { this.props.apartmenttemplates.map((x) => <option value={ x.id } key={ x.id }>{ x.name }</option>) }
      </Form.Control>

      <div className="actions largebuffer">
        <Button
          className="right"
          size="sm"
          data-todo="TODO: permissions"
          onClick={ this.create }
        >
          { I18n.t('views.condominiums.apartments.button-create') }
        </Button>
      </div>

      <b>{ I18n.t('views.condominiums.apartments.import_apartmentdata') }</b>
      <p>{ I18n.t('views.condominiums.apartments.import_apartmentdata_info') } <a style={{color:"#f3db3d"}} href={apartments_csv}>{ I18n.t('views.condominiums.apartments.import_apartmentdata_here') }</a></p>
      <Form.Control
              type="file"
              id="import_apartments"
              className="upload-button"
              onChange={this.apartmentCSVChange}
              name="import_apartments"
              ref={(ref) => this.import_apartments = ref}
              style={{display:"none"}}
            />
      <div>
        {!this.state.apartmentCSV && <Button className="right" size="sm">
          <label style={{margin:0, cursor:"pointer"}} htmlFor="import_apartments"><Upload /> Valitse tiedosto</label>
        </Button>}
        {this.state.apartmentCSV && <Button className="right" size="sm" onClick={ this.uploadCSV }>
          { I18n.t('views.condominiums.apartments.import_apartmentdata_button') } {this.state.apartmentCSV.name}
        </Button>}
        {this.state.apartmentCSV && <Button style={{marginLeft:4}} className="right btn-dark" size="sm" onClick={() => {this.setState({apartmentCSV : null})}}>
          { I18n.t('general.cancel') }
        </Button>}
      </div>

      <ApartmentModal
        apartment={ this.state.modalize }
        show={ this.state.modal === Modals.apartment }
        onHide={ this.hideModal }
        successCallback={ this.successCallback }
        condominium={ this.props.condominium }
      />
    </div>;
  }
}

export default CondominiumApartmentsTab;
