import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import I18n from 'i18n-js';
import { Form, Button, Tab } from 'react-bootstrap';
import AvainiaCore from 'avainia-core-api';
import Error from '../../multiview/Error/Error.js';
import Loading from '../../multiview/Loading/Loading.js';
import AvainiaTable from '../../multiview/AvainiaTable/AvainiaTable.js';
import ModalCompanyCreate from '../../multiview/Modals/ModalCompanyCreate.js';
import ModalCompanyEdit from '../../multiview/Modals/ModalCompanyEdit.js';
import PanelUserCategories from '../../multiview/Panels/PanelUserCategories.js';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';
import { TopbarContext } from '../../../contexts/TopbarContext.js';
import AvainiaTableHeading from '../../multiview/AvainiaTable/AvainiaTableHeading.js';

const Modals = {
  editCompany: 1,
  createCompany: 2,
};

const Tabs = {
  companies: "companies",
  usertypes: "usertypes",
  allusers: "allusers",
};

class Companies extends Component {
  static contextType = TopbarContext;

  constructor(props) {
    super(props);

    this.state = {
      users: [],
      companies: [],
      companiesFiltered: [],
      userCategories: [],
      companyToEdit: false,
      search: '',
      modal: false,
      loading: true,
      error: false,
    };
  }

  componentDidMount() {
    const api = new AvainiaCore(LocalStorageService.getToken);
    api.companiesGet().then((companies) => {
      if (companies.error) {
        return this.setState({ error: companies.error });
      }

      api.usersGet().then((users) => {
        if (users.error) { return this.setState({ error: users.error }); }

        api.userCategoriesGet().then((userCategories) => {
          if (userCategories.error) { return this.setState({ error: userCategories.error }); }

          this.setState({
            users,
            companies,
            companiesFiltered: companies,
            userCategories,
            loading: false,
          }, () => {
            const tabs = [
              <Tab key={Tabs.companies} eventKey={Tabs.companies} title={I18n.t('views.companies.companies')} />,
              <Tab key={Tabs.usertypes} eventKey={Tabs.usertypes} title={I18n.t('usercategories.heading')} />,
              <Tab key={Tabs.allusers} eventKey={Tabs.allusers} title={I18n.t('general.users')} />,
            ];
            this.context.setTopbar(tabs, false);
          });
        });
      });
    });
  }

  search = (e) => {
    const { companies } = this.state;
    const term = e.target.value;
    const searchableFields = ['name', 'code', 'type'];

    const companiesFiltered = companies.filter((obj) => {
      return Object.keys(obj)
        .filter((key) => searchableFields.includes(key))
        .some((key) => {
          return obj[key].toLowerCase().includes(term.toLowerCase());
        });
    });

    this.setState({ companiesFiltered });
  };

  hideModal = (e) => {
    this.setState({ modal: 0, companyToEdit: null });
  };

  addCompanyCallback = (company) => {
    const { companies } = this.state;
    companies.push(company);
    this.setState({ companies });
  };

  editCompanyCallback = (company) => {
    const companies = this.state.companies.filter(
      (x) => x.id !== this.state.companyToEdit.id,
    );

    companies.push(company);
    companies.sort((a, b) => {
      return a.id > b.id ? 1 : -1;
    });

    this.setState({ companies, companiesFiltered: companies });
  };

  removeCompanyCallback = (id) => {
    const companies = this.state.companies.filter((x) => x.id !== id);
    this.setState({ companies, companiesFiltered: companies });
  };

  handleCompanyRowClick = (e, rowData) => {
    const companyToEdit = this.state.companies.find((x) => x.id === rowData.id);
    this.setState({ companyToEdit, modal: Modals.editCompany });
  };

  createCompany = (e) => {
    this.setState({ modal: Modals.createCompany });
  };

  render() {
    if (this.state.error) {
      return <Error error={this.state.error} />;
    }
    if (this.state.loading) {
      return <Loading />;
    }

    const user = LocalStorageService.getUser();
    const ownerManager = user && user.isOwnerManager(); // TODO: Replace with real checks

    if (!ownerManager) { return <Redirect to="/projects" push={false} />; }

    return (
      <div className="App-container">
        {this.context.tab === Tabs.companies && <div>
          <h1>{I18n.t('views.companies.companies')}</h1>

          <Button onClick={this.createCompany} variant="primary">
            {I18n.t('views.companies.add-company')}
          </Button>

          <Form.Control
            type="text"
            onChange={this.search}
            placeholder={I18n.t('views.companies.search')}
          />

          <AvainiaTable
            keyField="id"
            data={this.state.companiesFiltered}
            rowClickHandler={this.handleCompanyRowClick}
            defaultSorted={[{ dataField: 'name', order: 'asc' }]}
            columns={[
              { dataField: 'id', text: I18n.t('general.id') },
              {
                dataField: 'name',
                text: I18n.t('views.companies.name'),
                sort: true,
              },
              { dataField: 'code', text: I18n.t('views.companies.code') },
              {
                dataField: 'type',
                text: I18n.t('views.companies.type'),
                formatter: (cell, row) => I18n.t(`constants.companyTypes.${cell}`),
              },
            ]}
          />

          {this.state.modal === Modals.createCompany && (
            <ModalCompanyCreate
              userCategories={this.state.userCategories}
              addCompanyCallback={this.addCompanyCallback}
              onHide={this.hideModal}
            />
          )}

          {this.state.modal === Modals.editCompany && (
            <ModalCompanyEdit
              userCategories={this.state.userCategories}
              editCompanyCallback={this.editCompanyCallback}
              removeCompanyCallback={this.removeCompanyCallback}
              companyToEdit={this.state.companyToEdit}
              onHide={this.hideModal}
            />
          )}
        </div>}

        {this.context.tab === Tabs.usertypes && <PanelUserCategories />}

        {this.context.tab === Tabs.allusers && <div>
          <AvainiaTableHeading title={I18n.t('general.users')} />

          <AvainiaTable
            keyField='id'
            data={this.state.users}
            columns={[
              { dataField: 'id', text: I18n.t('general.id') },
              { dataField: 'name', text: I18n.t('general.user') },
              { dataField: 'email', text: I18n.t('general.email') },
            ]}
          />
        </div>}
      </div>
    );
  }
}

export default Companies;
