import React, { Component, Fragment, useState } from 'react';
import I18n from 'i18n-js';
import { Button, FormControl, Modal, Form, Spinner, Badge } from 'react-bootstrap';
import AvainiaCore from 'avainia-core-api';
import Error from '../Error/Error.js';
import Loading from '../Loading/Loading.js';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import './ModalDocumentAdd.scss';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';

const Steps = {
  documentType: 1,
  fields: 2,
};

const CondominiumHiddenFieldTypes = ['phase', 'subject', 'state', 'info', 'position'];

const AvainiaField = (props) => {
  const { field, updateFieldData, handleDateChange, selectedDates } = props;
  const [value, setValue] = useState('');

  if (field.is_automatic) {
    return false;
  }

  const style = CondominiumHiddenFieldTypes.includes(field.key) ? {display:"none"} : null;

  if (field.type === 'keywords') {
    const options = field.keywords
      .filter((kw) => !kw.deactivated)
      .sort((a, b) => { return a.text > b.text ? 1 : -1; })
      .map((kw) => <option key={`${field.id}_${kw.id}`}>{kw.text}</option>);

    return <Form.Control as="select" value={value} style={style} onChange={(e) => {
      setValue(e.target.value);
      updateFieldData(field.id, e.target.value);
    }}>

    { options }
    </Form.Control>;
  }

  if (field.type === 'text') {
    return <FormControl style={style} placeholder={I18n.t(`fields.${field.key}`, { defaultValue: field.key })} type="text" value={value}
      onChange={(e) => {
        setValue(e.target.value);
        updateFieldData(field.id, e.target.value);
      }}
    />;
  }

  if (field.type === "date") {
    const date = selectedDates[field.id]

    return <Form.Group>
      <DatePicker
        placeholderText={field.key}
        className="form-control"
        todayButton={I18n.t('general.today')}
        selected={date}
        onChange={(newDate) => handleDateChange(field.id, newDate) }
        showTimeSelect
        timeFormat="HH:mm"
        timeIntervals={15}
        dateFormat="yyyy.MM.dd HH:mm"
      />
    </Form.Group>;
  }

  return false;
};

class ModalDocumentAdd extends Component {
  constructor(props) {
    super(props);

    this.state = {
      allFields: [],
      dates: {},
      documentData: {},
      documentTypes: [],
      error: false,
      loading: true,
      maxImages: 100,
      requiredError: false,
      selectedDocumentType: false,
      step: Steps.documentType,
      selectedFile: null,
      selectedFiles: [],
      fieldData: {}
    };
  }

  componentDidMount = () => {
    const api = new AvainiaCore(LocalStorageService.getToken);
    api.documentTypesGet().then((documentTypes) => {
      if (documentTypes.error) { return this.setState({ error: documentTypes.error }); }

      api.fieldsGet().then((allFields) => {
        if (allFields.error) { return this.setState({ error: allFields.error }); }

        this.setState({
          allFields,
          documentTypes,
          loading: false,
        });
      });
    });
  }

  handleDateChange = (id, date) => {
    this.setState({
      dates:  {...this.state.dates, [id]: date },
    }, () => {this.updateFieldData(id, moment(date).format('YYYY-MM-DD HH:mm:ss'));});
  };

  handleSelectedFile = (e) => {
    const max = this.state.maxImages;
    const { files } = e.target;
    const currentFiles = this.state.selectedFiles.length;
    const incomingFiles = files.length;

    if (currentFiles + incomingFiles > max) {
      this.setState({ error: 25 });
    } else {
      this.setState({ error: false });

      for (let i = 0; i < files.length; i++) {
        this.setState((prevState) => ({
          selectedFiles: [...prevState.selectedFiles, files[i]],
        }));
      }
    }
  }

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  updateFieldData = (fieldId, fieldValue) => {
    this.setState((prevState) => ({
      fieldData: {
        ...prevState.fieldData,
        [fieldId]: fieldValue,
      },
    }));
  }

  nextStep = () => {
    if (this.state.step === Steps.documentType) {
      // eslint-disable-next-line eqeqeq
      const selectedDocumentType = this.state.documentTypes.find((dt) => dt.id == this.state.selectedDocumentType);
      this.setState({
        step: Steps.fields,
        selectedDocumentType,
      });
    }
  }

  // TODO: Check for empty fields
  storeDocument = () => {
    if (this.state.selectedFiles === undefined || this.state.selectedFiles.length === 0) {
      console.log("general.required");
      this.setState({ requiredError: I18n.t('general.required') });
      return;
    }

    if (!this.state.fieldData) { 
      console.log("this.state.fieldData");
      return; 
    }

    if (!this.state.selectedDocumentType) { 
      console.log("this.state.selectedDocumentType");
      return; 
    }

    if (!this.state.selectedDocumentType.id) { 
      console.log("this.state.selectedDocumentType.id");
      return; 
    }

    this.setState({ loading: true }, () => {
      const fieldData = Object.entries(this.state.fieldData);
      const files = this.state.selectedFiles;

      const api = new AvainiaCore(LocalStorageService.getToken);
      const promises = files.map((file) => {
        const payload = {
          projectId: this.props.project.id,
          documentTypeId: this.state.selectedDocumentType.id,
          folderId: this.props.parentFolder ? this.props.parentFolder.id : null,
          file,
          fields: [],
          keywords: [],
        };

        fieldData.forEach((fieldSet) => {
        // eslint-disable-next-line eqeqeq
          const field = this.state.allFields.find((f) => f.id == fieldSet[0]);
          if (field.type === 'keywords') {
            const kw = field.keywords.find((keyword) => keyword.text === fieldSet[1]);

            // kw is not found likely means user selected and then de-selected a value
            if (kw) {
              const keywordId = kw.id;
              payload.keywords.push({
                field_id: field.id,
                id: keywordId,
                text: fieldSet[1],
              });
            }
          } else if (field.type === 'text') {
            payload.fields.push({
              field_id: field.id,
              text: fieldSet[1],
            });
          } else if (field.type === "date") {
            payload.fields.push({
              field_id: field.id,
              date: fieldSet[1],
            });
          }
        });

        return api.documentAdd(payload);
      });

      Promise.all(promises).then((results) => {
        let error = false;

        results.forEach((result) => {
          if (error) { return; }
          if (result.error) { error = result.error; }
        });

        if (error) {
          return this.setState({ error, loading: false });
        }

        window.location.reload(); // TODO: Improve
      });
    });
  }

  removeSelectedFile = (index) => {
    this.setState((state) => {
      const selectedFiles = state.selectedFiles.filter((item, j) => index !== j);
      return {
        selectedFiles,
      };
    });
  }

  render() {
    const activeDocumentTypes = this.state.documentTypes.filter((docType) => docType.is_active);
    const excludedFieldKeys = this.props.configuration.document.documentAddFieldExceptions ? Object.keys(this.props.configuration.document.documentAddFieldExceptions) : [];

    return <Modal show={true} onHide={this.props.hideModal}>
      <Modal.Header closeButton>
        <Modal.Title>{I18n.t('views.infraproject.add-document')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Error inline error={this.state.error} />
        <Loading inline hide={!this.state.loading} />
        {!this.state.loading && this.state.step === Steps.documentType &&
          <Fragment>
            {this.props.parentFolder &&
              <FormControl
                type="text"
                disabled
                value={`${I18n.t('folders.folder')}: ${this.props.parentFolder.name}`}
              />
            }
            <Form.Control as="select" name="selectedDocumentType" value={this.state.selectedDocumentType} onChange={this.onChange}>
              <option default value="">{I18n.t('views.documents.select-type')}</option>
              {activeDocumentTypes.map((type) => <option value={type.id} key={type.id}>
                {type.is_default ? I18n.t('documentType.default') : type.name}
              </option>)}
            </Form.Control>
            <Button onClick={this.nextStep} disabled={!this.state.selectedDocumentType}>{I18n.t('views.documents.next')}</Button>
          </Fragment>
        }
        {this.state.step === Steps.fields &&
          <Fragment>
            {this.state.selectedDocumentType.fields.map((field) => (
              !excludedFieldKeys.includes(field.key) &&
              <AvainiaField
                key={field.id}
                field={field}
                updateFieldData={this.updateFieldData}
                handleDateChange={this.handleDateChange}
                selectedDates={this.state.dates}
              />
            ))}
            <input style={{ display: 'none' }} type="file" id="files" multiple name="addNewImage" onChange={this.handleSelectedFile} key={Math.random()}/>
            <label className="label-select-files" htmlFor="files">{I18n.t('views.infraproject.select-files')}</label>
            {this.state.requiredError && <div><Badge variant="danger">{this.state.requiredError}</Badge></div>}
            <p>{I18n.t('views.infraproject.max-files')}{this.state.maxImages}</p>
            {this.state.selectedFiles.map((file, index) => <p key={index}>{file.name} <button onClick={() => this.removeSelectedFile(index)}>{I18n.t('general.delete')}</button></p>)}
          </Fragment>
        }
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={this.props.hideModal}>{I18n.t('general.cancel')}</Button>
        <Button
          variant="primary"
          onClick={this.storeDocument}
          disabled={this.state.loading}
        >
          {I18n.t('general.save')}
          {this.state.loading && <>&nbsp;
            <Spinner animation="border" size="sm" role="status" aria-hidden="true" />
          </>}
        </Button>
      </Modal.Footer>
    </Modal>;
  }
}

export default ModalDocumentAdd;
