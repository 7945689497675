import React, { Component } from 'react';
import AvainiaCore from 'avainia-core-api';
import I18n from 'i18n-js';
import { Form, Button, Row, Modal } from 'react-bootstrap';
import Error from '../../multiview/Error/Error.js';
import Loading from '../../multiview/Loading/Loading.js';
import CustomSelect from '../../multiview/Select/CustomSelect.js';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';

class CondominiumExistingUsersModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: false,
      selectedRole: null,
      users: [],
      loading: false,
      error: false,
    };
  }

  componentDidMount() {
    const api = new AvainiaCore(LocalStorageService.getToken);
    const existingCondoUserIds = this.props.condominiumUsers.map((user) => user.id);
    api.usersGet().then((users) => {
      if (users.error) { return this.setState({ error: users.error }); }
      // filter users that are not in the condominium
      const usersFiltered = users.filter((user) => !existingCondoUserIds.includes(user.id));

      return this.setState({
        users: usersFiltered,
        loading: false,
      });
    });
  }

  handleUserSelectChange = (selectName, selectedValue) => {
    const user = this.state.users.find((x) => x.id === Number(selectedValue.value));
    this.setState({ user });
  }

  addCallback = (userId, role) => {
    if (this.state.loading) { return; }
    const payload = {
      userId: userId,
      role: role.value,
    };

    const api = new AvainiaCore(LocalStorageService.getToken);
    api.condominiumUserCreate(this.props.condominiumId, payload).then((user) => {
      if (user.error) { this.setState({ error: user.error }); }
      else {
        this.props.addExistingUserCallback(user, role.label);
      }
    });
  }

  save = () => {
    if (this.state.user && this.state.selectedRole) {
      this.addCallback(this.state.user.id, this.state.selectedRole);
    } else {
      alert(I18n.t('user.invalid-credentials'));
    }
  }

  render() {
    const UserRoles = [
      { label: I18n.t('views.condominiums.owner'), value: 'owner' },
      { label: I18n.t('views.condominiums.tenant'), value: 'tenant' }
    ];

    return <Modal show={true} onHide={this.props.onHide} size="lg">
      {!this.state.loading ?
        <>
          <Modal.Header closeButton>
            <Modal.Title>
              {I18n.t('views.condominiums.users.add-existing')}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ minHeight: '25rem' }}>
            {this.state.error && <Error inline error={this.state.error} />}
            <div className="tinyform">
              <Form.Group as={Row}>
                <CustomSelect
                  name="user"
                  placeholder={I18n.t('views.condominiums.users.search')}
                  handleChange={this.handleUserSelectChange}
                  selectOptions={this.state.users.map((u) => { return { label: u.name, value: `${u.id}` }; })}
                />
              </Form.Group>
              <Form.Group as={Row}>
                <CustomSelect
                  name="role"
                  placeholder={I18n.t('views.condominiums.users.role')}
                  handleChange={(name, value) => {
                    this.setState({ selectedRole: { label: value.label, value: value.value } });
                  }}
                  selectOptions={UserRoles}
                  value={this.state.selectedRole}
                />
              </Form.Group>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="user-buttons">
              <Button variant="success" disabled={this.state.loading} onClick={this.save}>{I18n.t('general.modal-form-save')}</Button>
            </div>
          </Modal.Footer>
        </>
        :
        <Loading inline />
      }

    </Modal>;
  }
}

export default CondominiumExistingUsersModal;
