import React, { Component } from 'react';
import { Button, Modal, Row, Form, Col, InputGroup, FormControl } from 'react-bootstrap';
import I18n from 'i18n-js';
import AvainiaCore from 'avainia-core-api';
import AvainiaPermissions from '../../../AvainiaTools/AvainiaPermissions.js';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';
import Error from '../Error/Error.js';
import Loading from '../Loading/Loading.js';

class ModalProjectEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      code: '',
      editCoords: false,
      name: '',
      status: '',
      type: '',
      loading: false,
      error: false,
    };
  }

  componentDidMount = () => {
    const api = new AvainiaCore(LocalStorageService.getToken);
    api.projectStatusGet(this.props.project.project_status_id).then((currentProjectStatus) => {
      if (currentProjectStatus.error) { return this.setState({ error: currentProjectStatus.error, loading: false }); }

      api.projectStatusesGet().then((projectStatuses) => {
        if (projectStatuses.error) { return; }

        return this.setState({
          loading: false,
          error: false,
          activeProjectStatus: currentProjectStatus,
          projectStatuses,
          code: this.props.project.code,
          name: this.props.project.name,
          status: currentProjectStatus.id,
          type: this.props.project.type,
          coordinates: this.props.project.coordinates,
        });
      });
    });
  }

  componentDidUpdate = () => {
    if (!this.state.editCoords) { return; }
    if (window.editmap) { return; }

    if (window.google) {
      return this.initializeEditMap();
    }

    const scriptNode = document.createElement('script');
    scriptNode.type = 'text/javascript';
    scriptNode.id = 'googleMapsInsert';
    scriptNode.src = 'https://maps.google.com/maps/api/js?key=AIzaSyCn6T7i_CYc8AFNuT3idK-M0PjLG07anN4'; // TODO! DEDUPLICATE

    document.head.appendChild(scriptNode);

    // Wait for map to load
    scriptNode.addEventListener('load', this.initializeEditMap);
  }

  save = () => {
    const projectId = this.props.project.id;

    let projectDefaultCoordinates = this.props.configuration.coordinates.lat + ", " + this.props.configuration.coordinates.lng;
    if (this.state.coordinates) {
      projectDefaultCoordinates = this.state.coordinates;
    }

    const payload = {
      code: this.state.code,
      name: this.state.name,
      status: this.state.status,
      type: this.state.type,
      active: true,
      coordinates: projectDefaultCoordinates,
    };

    this.setState({ loading: 1, error: false }, () => {
      const api = new AvainiaCore(LocalStorageService.getToken);
      api.projectsEdit(projectId, payload).then((result) => {
        if (result.error) { return this.setState({ error: result.error, loading: false }); }
        return this.setState({ loading: false, error: false, }, () => {
          if(this.props.configuration.automaticalSchedules){
            this.createSchedule(projectId, payload);
            this.hide();
          } else {
            this.props.editCallback(projectId, payload);
            this.hide();
          }
        });
      });
    });
  }

  delete = () => {
    const projectId = this.props.project.id;

    if (!window.confirm(I18n.t('views.projects.confirm-delete'))) { return; }

    this.setState({ loading: 1, error: false }, () => {
      const api = new AvainiaCore(LocalStorageService.getToken);
      api.projectsDelete(projectId).then((result) => {
        if (result.error) { return this.setState({ error: result.error, loading: false }); }
        this.props.projectDeleteCallback(projectId);
        this.hide();
      });
    });
  }

  setCode = (e) => {
    const code = e.target.value;
    this.setState({ code });
  };

  setName = (e) => {
    const name = e.target.value;
    this.setState({ name });
  };

  setStatus = (e) => {
    const status = parseInt(e.target.value);

    this.setState({ status });
  };

  formatDateDB = (date) => {
    const d = new Date(date);
    const formatted = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
    return formatted;
  }

  createSchedule = (projectId, payload) => {
    if (this.state.loading) { return; }

    const api = new AvainiaCore(LocalStorageService.getToken);
    let parent = null;

    api.projectSchedulesGet(this.props.project.id, 'schedules').then((schedules) => {
      /* eslint-disable-next-line array-callback-return */
      schedules.schedules.map((schedule) => {
        if (schedule.description === 'Project Statuses' || schedule.description === 'Projektien statukset') {
          parent = schedule.id;
        }
      })

      const date = new Date();
      const start = this.formatDateDB(new Date());
      const end = this.formatDateDB(new Date(date.setDate(date.getDate() + 1)));
      const status = this.state.projectStatuses.find(x => x.id === this.state.status)

      const payload = {
        description: status.slug,
        start_at: start,
        end_at: end,
        completion: 0,
        automatical: true
      };

      if (parent) {
        payload.parent_id = parent;
      }

      api.projectSchedulesCreate(this.props.project.id, payload).then((schedule) => {
        if (schedule.error) { this.setState({ error: schedule.error }); }
        this.setState({ error: false, description: "", start_at: "", end_at: "", parent_id: 0, completion: null })
      }, () => this.props.editCallback(projectId, payload));
    })
  }

  setType = (e) => {
    const type = e.target.value;
    this.setState({ type });
  }

  editCoords = () => {
    this.setState({ editCoords: true });
  }

  // Stolen from ModalViewDocument, TODO: DEDUPE?
  initializeEditMap = () => {
    const coords = this.props.project.coordinates.split(',');
    const defaultCoords = {lat: parseFloat(coords[0]), lng: parseFloat(coords[1])}

    const center = defaultCoords;
    const options = { // TODO: DEDUPE, Map.js and below
      center,
      zoom: 10,
      fullscreenControl: false,
      zoomControl: true,
      scaleControl: true,
      mapTypeControl: true,
      gestureHandling: 'cooperative'
    };

    window.editmap = new window.google.maps.Map(document.getElementById("editCoordsModalMap"), options);

    if (window.editmarker) { window.editmarker.setMap(null); }

    window.editmap.addListener('click', (e) => {
      if (window.editmarker) {
        window.editmarker.setMap(null);
      }

      const newPosition = e.latLng;

      window.editmarker = new window.google.maps.Marker({
        position: newPosition,
        map: window.editmap,
      });

      window.editmap.panTo(newPosition);

      const fixedNewPosition = `${newPosition}`.replace(' ', '').replace('(', '').replace(')', '');

      this.setState({ coordinates: fixedNewPosition });
    });
  }

  hide = () => {
    window.editmap = null;
    this.props.onHide();
  }

  render() {
    const user = LocalStorageService.getUser();
    const canDelete = user.hasPermission(AvainiaPermissions.ProjectsManage);
    const { projectStatuses } = this.state;

    return <Modal show={true} onHide={this.props.onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{I18n.t('views.projects.edit-project')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {this.state.error && <Error inline error={this.state.error} />}
        {!this.state.error && this.state.loading && <Loading inline />}
        {!this.state.error && !this.state.loading && <div>
          <Form.Group as={Row}>
            <Form.Label column sm="2">{I18n.t('views.projects.project-code')}</Form.Label>
            <Col sm="10"><Form.Control type="text" onChange={this.setCode} name="code" value={this.state.code} /></Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="2">{I18n.t('views.projects.project-name')}</Form.Label>
            <Col sm="10"><Form.Control type="text" onChange={this.setName} name="name" value={this.state.name} /></Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="2">{I18n.t('views.projects.status')}</Form.Label>
            <Col sm="10">
              <FormControl
                as="select"
                onChange={ this.setStatus }
                name="status"
                placeholder={I18n.t('projectStatuses.status-parent')}
                value={this.state.status}
              >
              {
                // eslint-disable-next-line
                projectStatuses && projectStatuses.map((parent) => {
                  if (parent.is_active) {
                    return <option key={parent.id} value={parent.id}> {parent.is_default ? I18n.t(`views.projects.statuses.${parent.slug}`) : parent.name} </option>;
                  }
                  if (!parent.is_active && this.state.activeProjectStatus.id === parent.id) {
                    return <option disabled key={parent.id} value={parent.id}> {parent.is_default ? I18n.t(`views.projects.statuses.${parent.slug}`) : parent.name} </option>;
                  }
                })
              }
              </FormControl>
            </Col>
          </Form.Group>

          {!this.props.disableType &&
            <Form.Group as={Row}>
              <Form.Label column sm="2">{I18n.t('views.projects.type')}</Form.Label>
              <Col sm="10"><Form.Control as="select" onChange={this.setType} value={this.state.type}>
                <option value="condominium">{I18n.t('views.projects.condominium')}</option>
                <option value="infrastructure">{I18n.t('views.projects.infrastructure')}</option>
                <option value="marine">{I18n.t('views.projects.marine')}</option>
              </Form.Control></Col>
            </Form.Group>
          }

          {this.state.type === "infrastructure" && this.props.configuration.setProjectLocationCoordinates && <>
            <Form.Group>
              <Form.Label>{I18n.t('views.projects.project-coordinates')}</Form.Label>
              <InputGroup>
                <Form.Control readOnly type="text" onChange={this.onChange} name="coordinates" value={this.state.coordinates} />
                <InputGroup.Append>
                  <Button
                    variant="outline-secondary"
                    onClick={this.editCoords}
                  >
                    {I18n.t('general.edit')}
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </Form.Group>
            {this.state.editCoords &&  <div id="editCoordsModalMap"></div>}
          </>}

        </div>
        }
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" disabled={this.state.loading} onClick={this.save}>{I18n.t('general.modal-form-save')}</Button>
        {canDelete && <Button variant="primary" disabled={this.state.loading} onClick={this.delete}>{I18n.t('general.delete')}</Button>}
        <Button variant="secondary" onClick={this.hide}>{I18n.t('general.modal-close')}</Button>
      </Modal.Footer>
    </Modal>;
  }
}

export default ModalProjectEdit;
