import React, { Component } from 'react';
import { Form, Button } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import I18n from 'i18n-js';
import AvainiaCore from 'avainia-core-api';
import { City } from '../../multiview/Icon/Icon.js';
import Error from '../../multiview/Error/Error.js';
import Loading from '../../multiview/Loading/Loading.js';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';
import AvainiaPermissions from '../../../AvainiaTools/AvainiaPermissions.js';
import ApartmentTemplatesList from './ApartmentTemplatesList.js';
import UpcertCondominiumModal from '../../multiview/Modals/UpcertCondominiumModal.js';
import AvainiaPanel from '../../multiview/Panels/AvainiaPanel.js';
import ProjectListPanel from '../../multiview/Panels/ProjectListPanel.js';
import CondominiumPanel from '../../multiview/Panels/CondominiumPanel.js';

const Modals = {
  upcert: 1,
  apartmenttemplates: 2,
};

class CondominiumBasedataTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newAparmentTemplateName: '',
      redirect: false,
      modal: false,
      loading: false,
      error: false,
    };
  }

  createAptTemplate = () => {
    if (this.state.loading) { return; }
    this.setState({ loading: 1, error: false }, () => {
      const api = new AvainiaCore(LocalStorageService.getToken);
      api.condominiumApartmentTemplateCreate(this.props.condominium.id, this.state.newAparmentTemplateName).then((result) => {
        if (result.error) { return this.setState({ loading: false, error: result.error }); }

        return this.setState({ loading: false, error: false }, () => { this.props.aptTemplateCreateCallback(result); });
      });
    });
  }

  delete = () => {
    if (this.state.loading) { return; }

    if (!window.confirm(I18n.t('views.condominiums.confirm-delete'))) { return; }

    // TODO: Confirm
    this.setState({ loading: 1, error: false }, () => {
      const api = new AvainiaCore(LocalStorageService.getToken);
      api.condominiumDelete(this.props.condominium).then((condominium) => {
        if (condominium.error) { return this.setState({ error: condominium.error }); }

        this.setState({ redirect: true });
      });
    });
  }

  exportCondo = async () => {
    try {
      const obj = { headers: { Authorization: `Bearer ${LocalStorageService.getToken()}` }};
      // Generate or download a room card
      const apiRoute = `condominiums/${this.props.condominium.id}/export`;
      const res = await fetch(`${process.env.REACT_APP_API_HOST}/api/v1/${apiRoute}`, obj);
      const name = this.props.condominium.name.replace(" ", "-").replace("ö", "o").replace("ä", "a").toLowerCase();

      if (res.ok) {
        const binary = await res.blob();
        const fileName = name + '.csv';
        const src = window.URL.createObjectURL(binary);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.style.cssText = 'display: none';
        a.href = src;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(src);
        setTimeout((x) => { document.body.removeChild(a); }, 1000);
      } else if (res.status !== 410) { // 410 means dont retry
        throw new Error('failed to fetch document'); // TODO! Fatal error, do actual logging
      }
    } catch (ex) {
      console.error(ex); // TODO! Fatal error, do actual logging
    } 
  }

  editAptTemp = () => { this.setState({ modal: Modals.apartmenttemplates }); }

  edit = () => { this.setState({ modal: Modals.upcert }); }

  hideModal = (e) => { this.setState({ modal: false }); }

  successCallback = (condominium) => { window.location.reload(); } // TODO: improve

  onChange = (e) => { this.setState({ [e.target.name]: e.target.value }); }

  render() {
    if (this.state.redirect) { return <Redirect to="/condominiums" push={true} />; }

    const user = LocalStorageService.getUser();

    return <div className="App-container">
      <div className="AvainiaPanel">
        <AvainiaPanel header>
          <div className="text">
            <h2>{this.props.condominium.name}</h2>
          </div>
        </AvainiaPanel>

        <CondominiumPanel condominium={this.props.condominium} disableLink />

        <ProjectListPanel projects={this.props.projects} />

        <AvainiaPanel heading={I18n.t('views.condominiums.apartmenttemplates')} icon={<City />}>
          <ApartmentTemplatesList
            condominium={this.props.condominium}
            list={this.props.apartmenttemplates}
            editCallback={this.props.aptTemplateEditCallback}
            deleteCallback={this.props.aptTemplateDeleteCallback} />

          {this.state.error && <Error inline error={this.state.error} />}
          {!this.state.error && this.state.loading && <Loading inline />}
          {!this.state.error && !this.state.loading && <div>
            <Form.Control
              placeholder={I18n.t('views.condominiums.add-new-apartmenttemplate')}
              type="text"
              onChange={this.onChange} name="newAparmentTemplateName"
              value={this.state.newAparmentTemplateName}
            />
            <Button
              style={{ margin: '8px 0' }}
              size="sm"
              variant="success"
              data-todo="TODO: permissions"
              onClick={this.createAptTemplate}
              disabled={this.state.newAparmentTemplateName.length === 0 || this.state.loading}
              className="right"
            >
              {I18n.t('views.condominiums.button-create-apt-template')}
            </Button>
          </div>}
        </AvainiaPanel>

        {user.hasPermission(AvainiaPermissions.CondominiumsManage) &&
          <div className="actions largebuffer AvainiaPanel--fit">
            <div><Button className="right" size="sm" onClick={this.edit}>{I18n.t('views.condominiums.button-edit')}</Button></div>
            <div><Button className="right" size="sm" onClick={this.exportCondo}>{I18n.t('views.condominiums.button-export')}</Button></div>
            <div><Button className="right" size="sm" onClick={this.delete} variant="dark">{I18n.t('views.condominiums.button-delete')}</Button></div>
          </div>
        }

        {this.state.modal === Modals.upcert &&
          <UpcertCondominiumModal
            show={true}
            onHide={this.hideModal}
            successCallback={this.successCallback}
            condominium={this.props.condominium}
          />
        }
      </div>
    </div>;
  }
}

export default CondominiumBasedataTab;
