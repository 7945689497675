import React, { Component } from 'react';
import { Button, Modal, Row, Form, Col } from 'react-bootstrap';
import I18n from 'i18n-js';
import AvainiaCore from 'avainia-core-api';
import Error from '../../multiview/Error/Error.js';
import Loading from '../../multiview/Loading/Loading.js';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';
import { Upload } from '../../multiview/Icon/Icon.js';

class AddAdditionalProductModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      description: '',
      code: '',
      price: null,
      labor_price: null,
      product_photo: null,
      product_photo_preview: null,
      loading: false,
      error: false,
    };
  }

  handleImageChange = (e) => {
    if (e.target.files[0]) {
      const { name } = e.target;
      const reader = new FileReader();
      const file = e.target.files[0];

      reader.onloadend = () => {
        this.setState((prevState) => ({
          [name]: file,
          [`${name}_preview`]: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    }
  }

  save = () => {
    const payload = {
      name: this.state.name,
      code: this.state.code,
      description: this.state.description,
      price: this.state.price,
      labor_price: this.state.labor_price,
    };

    if (this.state.product_photo) { payload.product_photo = this.state.product_photo; }

    const formData = new FormData();

    Object.keys(payload).forEach((key) => {
      formData.append(key, payload[key]);
    });

    this.setState({ loading: 1, error: false }, () => {
      const api = new AvainiaCore(LocalStorageService.getToken);
      api.additionalProductAdd(formData).then((result) => {
        if (result.error) { return this.setState({ error: result.error, loading: false }); }

        return this.setState({ loading: false, error: false }, () => {
          this.props.refresh();
          this.props.onHide();
        });
      });
    });
  }

  onChange = (e) => { this.setState({ [e.target.name]: e.target.value }); }

  render() {
    return <Modal show={true} onHide={this.props.onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{I18n.t('views.products.add-additional-product')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {this.state.error && <Error inline error={this.state.error} />}
        {!this.state.error && this.state.loading && <Loading inline />}
        {!this.state.error && !this.state.loading && <div>
          <Form.Group as={Row}>
            <Form.Label column sm="2">{I18n.t('views.products.name')}</Form.Label>
            <Col sm="10">
              <Form.Control type="text" onChange={this.onChange} name="name" value={this.state.name} />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="2">{I18n.t('views.products.code')}</Form.Label>
            <Col sm="10">
              <Form.Control type="text" onChange={this.onChange} name="code" value={this.state.code} />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="2">{I18n.t('views.products.description')}</Form.Label>
            <Col sm="10">
              <Form.Control type="text" onChange={this.onChange} name="description" value={this.state.description} />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="2">{I18n.t('views.products.price')}</Form.Label>
            <Col sm="10">
              <Form.Control type="number" onChange={this.onChange} name="price" value={this.state.price} />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="2">{I18n.t('views.products.labor_price')}</Form.Label>
            <Col sm="10">
              <Form.Control type="number" onChange={this.onChange} name="labor_price" value={this.state.labor_price} />
            </Col>
          </Form.Group>
          <Form.Group>
          <Form.Control type="file" id="product_photo" className="inputfile" onChange={this.handleImageChange} name="product_photo" />
            <label htmlFor="product_photo" className="wide"><Upload /> {I18n.t('general.choose-photo')}</label>
            {this.state.product_photo_preview && <img className="product-preview-image" alt="preview" src={this.state.product_photo_preview}/> }
          </Form.Group>
        </div>}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" disabled={this.state.loading} onClick={this.save}>{I18n.t('general.modal-form-save')}</Button>
        <Button variant="secondary" onClick={this.props.onHide}>{I18n.t('general.modal-close')}</Button>
      </Modal.Footer>
    </Modal>;
  }
}

export default AddAdditionalProductModal;
