import React, { Component } from 'react';
import I18n from 'i18n-js';
import { Button, Modal, Form } from 'react-bootstrap';
import AvainiaCore from 'avainia-core-api';
import Error from '../Error/Error.js';
import Loading from '../Loading/Loading.js';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';

export default class ModalDefaultfolderAdd extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      visibility: 'global',
      loading: false,
      error: false,
    };
  }

  onChange = (e) => { this.setState({ [e.target.name]: e.target.value }); }

  create = () => {
    if (this.state.loading) { return; }

    this.setState({ loading: true }, () => {
      const payload = {
        name: this.state.name,
        project_subtype_id: this.props.projectSubtype.id,
        visibility: this.state.visibility
      };

      const api = new AvainiaCore(LocalStorageService.getToken);

      api.defaultFoldersCreate(payload).then((result) => {
        if (result.error) { return this.setState({ error: result.error, loading: false }); }

        window.location.reload(); // TODO: Improve
      });
    });
  }

  render() {
    // TODO: change this to come from core and translate them
    const options = [{id: 'global', name: I18n.t('defaultfolders.global')}, {id: 'project', name: I18n.t('defaultfolders.project')}, {id: 'owner', name: I18n.t('defaultfolders.owner')}, {id: 'ownertenant', name: I18n.t('defaultfolders.ownertenant')}];

    return <Modal show={this.props.show} onHide={this.props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{I18n.t('defaultfolders.create-defaultfolder')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {this.state.error && <Error error={this.state.error} inline />}
        {!this.state.error && this.state.loading && <Loading inline />}
        {!this.state.error && !this.state.loading && <>

          <Form.Group>
            <Form.Label>{I18n.t('projectSubtypes.project-subtypes-name')}</Form.Label>
            <Form.Control type="text" readOnly name="subtype" value={this.props.projectSubtype.name} />
          </Form.Group>

          <Form.Group>
            <Form.Label>{I18n.t('defaultfolders.name')}</Form.Label>
            <Form.Control type="text" onChange={this.onChange} name="name" value={this.state.name} />
          </Form.Group>

          { this.props.configuration.folderVisibilitySelector && 
            <Form.Group>
              <Form.Label>{I18n.t('defaultfolders.visibility')}</Form.Label>
              <Form.Control as="select" onChange={this.onChange} name="visibility">
                {options.map((x) => <option value={x.id} key={x.id}>{x.name}</option>)}
              </Form.Control>
            </Form.Group>
          }

        </>}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={this.props.onHide}>
          {I18n.t('views.materialforms.button-cancel')}
        </Button>
        <Button variant="primary" onClick={this.create}>
          {I18n.t('general.save')}
        </Button>
      </Modal.Footer>
    </Modal>;
  }
}
