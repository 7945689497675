import React, { Component } from 'react';
import AvainiaCore from 'avainia-core-api';
import DatePicker from 'react-datepicker';
import { Button, Badge, Modal } from 'react-bootstrap';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';

class RoomCardDeadlineModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      quote: {},
      changed: false,
    };
  }

  componentDidMount() {
    const quote = { ...this.props.quote };
    quote.room_card_approval_deadline = Date.parse(quote.room_card_approval_deadline);
    this.setState({ quote });
  }

  onChangeDatePicker = (date) => {
    const { quote } = this.state;
    quote.room_card_approval_deadline = date;
    this.setState({ quote, changed: true });
  };

  saveDeadline = () => {
    const api = new AvainiaCore(LocalStorageService.getToken);    

    let date = new Date(
      this.state.quote.room_card_approval_deadline.getTime() - this.state.quote.room_card_approval_deadline.getTimezoneOffset() * 60000,
    );

    const payload = {
      id: this.state.quote.id,
      project_id: this.state.quote.project_id,
      room_card_approval_deadline: date
    };

    api.apartmentMaterialQuoteRoomCardDeadlineSave(payload.project_id, payload).then(() => {
      this.props.onHide();
      window.location.reload();
    });
  };

  deleteDeadline = () => {
    const api = new AvainiaCore(LocalStorageService.getToken);    

    const payload = {
      id: this.state.quote.id,
      project_id: this.state.quote.project_id,
      room_card_approval_deadline: null
    };

    api.apartmentMaterialQuoteRoomCardDeadlineSave(payload.project_id, payload).then(() => {
      this.props.onHide();
      window.location.reload();
    });
  };

  render() {
    return (
      <Modal show={true} onHide={this.props.onHide} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            {this.state.quote.id ? 'Muokkaa aikataulua' : 'Aseta aikataulu'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ minHeight: '400px' }}>
            <p>Huonekortti hyväksyttävä viimeistään:</p>
            <DatePicker
              name="deadline"
              selected={this.state.quote.room_card_approval_deadline}
              onChange={(date) => this.onChangeDatePicker(date)}
              className="datepicker-input"
              dateFormat="dd.MM.yyyy HH:mm"
              showTimeSelect
              timeFormat="HH:mm"
            />
            <div>
              <label style={{ paddingRight: '15px' }}>
                Muistutus lähetetty:
              </label>
              <input
                id="sent"
                disabled={true}
                type="checkbox"
                checked={!this.state.quote.room_card_notification_due}
              />
              <p style={{ paddingTop: '30px' }}>
                Muistutus lähetetään viikkoa ennen deadlinea.
              </p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {this.state.quote.id && (
            <Button variant="secondary" onClick={this.deleteDeadline}>
              Poista
            </Button>
          )}
          <Button
            variant="primary"
            disabled={!this.state.changed}
            onClick={this.saveDeadline}
          >
            Tallenna
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
export default RoomCardDeadlineModal;
