import React, { Component } from 'react';
import I18n from 'i18n-js';
import AvainiaCore from 'avainia-core-api';
import { Button } from 'react-bootstrap';
import { Users } from '../Icon/Icon.js';
import AvainiaTable from '../AvainiaTable/AvainiaTable.js';
import AvainiaPanel from './AvainiaPanel.js';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';

class UsersPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      condominiumId: this.props.condominiumId,
      apartmentId: this.props.apartmentId,
      userId: null,
      roleId: null,
      roles: [],
      role: '',
      showToast: false,
    };
  }

  componentDidMount() {
    const api = new AvainiaCore(LocalStorageService.getToken);
    api.getApartmentAllowedRoles(this.props.condominiumId, this.props.apartmentId).then((roles) => {
      this.setState({ roles: roles })
    }).catch((err) => {
      console.log(err);
    });
  }

  handleUserChange = (event) => {
    this.setState({ userId: event.target.value });
  }

  handleRoleChange = (event) => {
    this.setState({ roleId: event.target.value });
  }

  handleUserRoleRemove = (event, user) => {
    const api = new AvainiaCore(LocalStorageService.getToken);

    api.removeAptUserRole(this.props.condominiumId, this.props.apartmentId, user.id, { roleId: user.role.id })
      .then(() => {
        this.props.refreshUsers();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleSubmit(event, condoId, aptId) {
    event.preventDefault();

    const payload = {
      roleId: this.state.roleId,
    };

    if (this.state.userId !== null) {
      const api = new AvainiaCore(LocalStorageService.getToken);
      api.modifyApartmentRole(condoId, aptId, this.state.userId, payload).then((response) => {
        this.props.refreshUsers();
      }).catch((err) => {
        console.log(err);
      });
    }
  }

  render() {
    return <AvainiaPanel heading={I18n.t('general.users')} double={this.props.double} icon={<Users />}>
      <AvainiaTable data={this.props.currentUsers} keyField="id" columns={[
        { dataField: 'id', text: I18n.t('general.id'), headerStyle: { width: '60px' } },
        { dataField: 'name', text: I18n.t('usercategories.name') },
        { dataField: 'role.display_name', text: I18n.t('user.role') },
        {
          dataField: 'actions',
          text: I18n.t('general.table-actions'),
          headerStyle: { width: '200px' },
          formatter: (cell, row) => {
            return (
              <Button size="sm" style={{ margin: '0px' }} onClick={(event) => this.handleUserRoleRemove(event, row)}>
                {I18n.t('views.condominiums.apartments.detach')}
              </Button>
            );
          },
        },
      ]} />
      <hr />
      <div>
        {I18n.t('views.condominiums.apartments.attach_user_to_apartment')}
      </div>
      <form onSubmit={(event) => this.handleSubmit(event, this.state.condominiumId, this.state.apartmentId)}>
        <div className="form-group">
          <select className="custom-select" onChange={this.handleUserChange} required>
            <option value="">{I18n.t('views.condominiums.apartments.choose_user')} *</option>
            {this.props.condominiumUsers.map((condoUser) => {
              return (
                <option key={condoUser.id} value={condoUser.id}>{condoUser.name} | {condoUser.email}</option>
              );
            })}
          </select>
          <div className="invalid-feedback"></div>
        </div>
        <div className="form-group">
          <select className="custom-select" onChange={this.handleRoleChange} required>
            <option value="">{I18n.t('views.condominiums.apartments.choose_role')} *</option>
            {this.state.roles.map((role) => {
              return (
                <option key={role.id} value={role.id}>{I18n.t(`views.condominiums.apartments.${role.display_name}`)}</option>
              );
            })}
          </select>
          <div className="invalid-feedback"></div>
        </div>
        <button type="submit" className="btn btn-primary">{I18n.t('views.condominiums.apartments.attach')}</button>
      </form>
    </AvainiaPanel >;
  }
}

export default UsersPanel;
