import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import markerIcon from '../../../../../images/marker.png';
import I18n from 'i18n-js';

// Based on https://cuneyt.aliustaoglu.biz/en/using-google-maps-in-react-without-custom-libraries/

class Map extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mapHidden: true,
      mapExpanded: false,
    };
  }

  MapSettings = {
    apikey: 'AIzaSyCn6T7i_CYc8AFNuT3idK-M0PjLG07anN4',
    defaultCenter: { lat: window.Avainia.config.coordinates.lat ? window.Avainia.config.coordinates.lat : '0', lng: window.Avainia.config.coordinates.lng ? window.Avainia.config.coordinates.lng : '0' },
    zoom: 10,
    unsetZoom: 15,
    spidefierOptions: {
      maxZoom: 18,
      legWeight: 3,
      keepSpiderfied: 1,
      circleFootSeparation: 30,
      markersWontMove: true,
      markersWontHide: true,
    },
  };

  onGoogleApiLoaded = (config) => {
    const { maps, map } = config;
    window._map = map; // This is necessary! TODO: refactor

    const OverlappingMarkerSpiderfier = require('overlapping-marker-spiderfier');
    const MarkerClusterer = require('node-js-marker-clusterer');
    const oms = new OverlappingMarkerSpiderfier(map, this.MapSettings.spidefierOptions);

    const markers = this.props.images.filter((x) => x.coordinates).map((image) => {
      const marker = new maps.Marker({
        position: image.coordinates,
        map,
        label: image.counter,
        icon: {
          url: markerIcon,
          labelOrigin: new maps.Point(14, 14),
        },
      });

      marker.addListener('click', (e) => {
        this.props.activateImage(image, { dontCenter: true });
      });

      oms.addMarker(marker);

      return marker;
    });
    window._oms = oms;
    window._markers = markers;

    const closeFullscreen = document.createElement('div');
    closeFullscreen.className = 'closeFullscreen';
    closeFullscreen.style.top = '10px';
    closeFullscreen.style.marginRight = '3em';
    closeFullscreen.style.cursor = 'pointer';
    closeFullscreen.setAttribute('id', 'closeFullcreen');
    closeFullscreen.addEventListener('click', () => {
      document.getElementById('root').classList.remove('mobile-map-fullscreen');
    });

    map.controls[maps.ControlPosition.RIGHT_TOP].push(closeFullscreen);
    window._mc = new MarkerClusterer(map, markers, {
      imagePath: '/m/m',
      maxZoom: 16,
    });
    this.getDefaultCenter();
  }

  getDefaultCenter = () => {
    return (`lat: ${window.Avainia.config.coordinates.lat}, lng: ${window.Avainia.config.coordinates.lng}`);
  }

  getCenter = () => {
    if (this.props.allImages.length > 0) {
      const firstCoords = this.props.allImages.find((x) => x.coordinates);

      if (firstCoords) {
        return firstCoords.coordinates;
      }
    }
    
    if (this.props.coordinates) {
      const coords = this.props.coordinates.split(',');
      const mapCoords = {lat: parseFloat(coords[0]), lng: parseFloat(coords[1])}
      return mapCoords;
    }
    return this.MapSettings.defaultCenter;
  }

  toggleMapExpand = () => {
    this.setState((prevState) => ({ mapExpanded: !prevState.mapExpanded }));
  }

  render() {
    if (this.props.hideableMap) {
      if (this.props.mapHidden) { return false; }
    }

    const { mapExpanded } = this.state;

    return <>
      <div className={`map-wrapper ${mapExpanded ? 'expand' : ''}`}>
        <button className="map-expand-button btn" onClick={this.toggleMapExpand}>
          {I18n.t(`views.infraproject.${mapExpanded ? 'collapse' : 'expand'}-map`)}
        </button>
        <div className="map-container">
          <GoogleMapReact
            bootstrapURLKeys={{ key: this.MapSettings.apikey }}
            defaultCenter={this.getCenter()}
            defaultZoom={this.MapSettings.zoom}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={this.onGoogleApiLoaded}
            options={{
              fullscreenControl: false,
              zoomControl: true,
              scaleControl: true,
              mapTypeControl: true,
              streetViewControl: true,
            }}
          />
        </div>
      </div>
    </>;
  }
}

export default Map;
