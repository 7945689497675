import React, { Component } from 'react';
import I18n from 'i18n-js';
import { Button, FormControl, Modal } from 'react-bootstrap';
import AvainiaCore from 'avainia-core-api';
import Error from '../Error/Error.js';
import Loading from '../Loading/Loading.js';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';

class ModalFolderEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      foldername: '',
      visibility: '',
      parentId: null,
      loading: false,
      error: false, // TODO: Implement
    };
  }

  componentDidMount = () => {
    this.setState({
      foldername: this.props.folder.name,
      visibility: this.props.folder.visibility,
    });
  }

  editFolder = () => {
    this.setState({ loading: true }, () => {
      const api = new AvainiaCore(LocalStorageService.getToken);
      api.projectFolderEdit(this.props.folder, { name: this.state.foldername, visibility: this.state.visibility }).then((result) => {
        if (result.error) { return this.setState({ error: result.error, loading: false }); }

        window.location.reload();
        // this.setState({ loading: false, activeFolder: null });
      });
    });
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    const options = [{id: 'global', name: I18n.t('defaultfolders.global')}, {id: 'project', name: I18n.t('defaultfolders.project')}, {id: 'owner', name: I18n.t('defaultfolders.owner')}, {id: 'ownertenant', name: I18n.t('defaultfolders.ownertenant')}];

    return <Modal show={true} onHide={this.props.hideModal}>
      <Modal.Header closeButton>
        <Modal.Title>{this.props.folder.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {this.props.parentFolder && <>
          <FormControl
            value={`${I18n.t('folders.parentFolder')} ${this.props.parentFolder.name}`}
            disabled
            name="name"
            type="text"
          />
          <br />
        </>}

        {this.state.error && <Error error={this.state.error} inline /> }
        {this.state.loading && <Loading error={this.state.error} inline /> }
        {!this.state.loading && <>
          <FormControl
            placeholder={I18n.t('folders.name')}
            name="foldername"
            type="text"
            value={this.state.foldername}
            onChange={this.onChange}
          />
          { this.props.configuration.folderVisibilitySelector && 
          <>
            <FormControl as="select" onChange={this.onChange} name="visibility" value={this.state.visibility}>
              {options.map((x) => <option value={x.id} key={x.id}>{x.name}</option>)}
              </FormControl>
          </>
          }
          </>
        }
      </Modal.Body>
      <Modal.Footer>
        {!this.state.loading && <>
          <Button variant="secondary" onClick={this.props.hideModal}>{I18n.t('general.cancel')}</Button>
          <Button variant="primary" onClick={this.editFolder}>{I18n.t('general.save')}</Button>
        </>}
      </Modal.Footer>
    </Modal>;
  }
}

export default ModalFolderEdit;
