import React, { Component } from 'react';
import PanelDefaultfolders from '../../multiview/Panels/PanelDefaultfolders.js';
import { TopbarContext } from '../../../contexts/TopbarContext.js';
import { Tab } from 'react-bootstrap';
import PanelProjectStatuses from '../../multiview/Panels/PanelProjectStatuses.js';
import I18n from 'i18n-js';

const Tabs = {
  defaultfolders: "defaultfolders",
  projectstatuses: "projectstatuses"
};

export default class Admin extends Component {
  static contextType = TopbarContext;

  componentDidMount() {
    this.context.resetTopbar();

    const tabs = this.props.configuration.projectManagementTabConfig;
    const projectTabs = Object.keys(tabs).map((x, index) => {
      if (tabs[x] === true) {
        return <Tab key={Tabs[x]} eventKey={Tabs[x]} title={I18n.t(`views.${x}.${x}`)} />;
      } return null;
    });

    this.context.setTopbar(projectTabs);
  }

  render() {
    return (
      <div className="App-container">
        {this.props.configuration.projectManagementTabConfig.defaultfolders && this.context.tab === Tabs.defaultfolders && <PanelDefaultfolders configuration={this.props.configuration} />}
        {this.props.configuration.projectManagementTabConfig.projectstatuses && this.context.tab === Tabs.projectstatuses && <PanelProjectStatuses />}
      </div>
    );
  }
}
