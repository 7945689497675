import React, { Component } from 'react';
import PanelDefaultfolders from '../../multiview/Panels/PanelDefaultfolders.js';
import { TopbarContext } from '../../../contexts/TopbarContext.js';
import { Tab } from 'react-bootstrap';
import AdditionalProducts from './AdditionalProducts.js';
import Products from './Products.js';
import ProductTags from './ProductTags.js'
import I18n from 'i18n-js';

const Tabs = {
  products: "products",
  additionalProducts: "additionalProducts",
  productTags: "productTags"
};

export default class ProductsAdmin extends Component {
  static contextType = TopbarContext;

  componentDidMount() {
    this.context.resetTopbar();

    const tabs = this.props.configuration.productManagementTabConfig;
    const projectTabs = Object.keys(tabs).map((x, index) => {
      if (tabs[x] === true) {
        return <Tab key={Tabs[x]} eventKey={Tabs[x]} title={I18n.t(`views.products.${x}`)} />;
      } return null;
    });

    this.context.setTopbar(projectTabs);
  }

  render() {
    return (
      <div className="App-container">
        {this.props.configuration.productManagementTabConfig.products && this.context.tab === Tabs.products && <Products />}
        {this.props.configuration.productManagementTabConfig.additionalProducts && this.context.tab === Tabs.additionalProducts && <AdditionalProducts />}
        {this.props.configuration.productManagementTabConfig.productTags && this.context.tab === Tabs.productTags && <ProductTags />}
      </div>
    );
  }
}
