import React, { Component } from 'react';
import I18n from 'i18n-js';
import { FormControl, Dropdown, DropdownButton } from 'react-bootstrap';
import { Undo, FolderPlus, FolderMinus } from '../../../multiview/Icon/Icon.js';

class DesktopFilters extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dateContainerOpen: false,
    };
  }

  render() {
    return <div className="desktop-filters">
      <div className="desktop-filters-content-wrap">
        <FormControl
          className="filter-block"
          autoFocus
          placeholder={I18n.t('views.infraproject.filter')}
          name="filter"
          type="text"
          value={this.props.thing.state.view.filter}
          onChange={this.props.thing.onChange}
        />

        <DropdownButton variant="primary" title={`${I18n.t('views.infraproject.phases')} ${`(${this.props.thing.state.view.activeFilters.phase.length})` || ''}`} className="filter-block">
          {this.props.thing.state.phases.map((phase, i) => <Dropdown.Item
              eventKey={i}
              onClick={this.props.thing.toggleFilter}
              data-filtertype="phase"
              key={phase.id}
              className={this.props.thing.state.view.activeFilters.phase.includes(phase.name) ? 'filter-toggle active' : 'filter-toggle'}
            >
              {phase.text}
            </Dropdown.Item>)}
        </DropdownButton>

        <DropdownButton variant="primary" title={`${I18n.t('views.infraproject.subjects')} ${`(${this.props.thing.state.view.activeFilters.subject.length})` || ''}`} className={'filter-block'}>
          {this.props.thing.state.subjects.sort((a, b) => {return a.text < b.text ? -1 : 1}).map((subject, i) => <Dropdown.Item
              eventKey={i}
              onClick={this.props.thing.toggleFilter}
              data-filtertype="subject"
              key={subject.id}
              className={this.props.thing.state.view.activeFilters.subject.includes(subject.name) ? 'filter-toggle active' : 'filter-toggle'}
            >
              {subject.text}
            </Dropdown.Item>)}
        </DropdownButton>

        <DropdownButton variant="primary" title={`${I18n.t('views.infraproject.states')} ${`(${this.props.thing.state.view.activeFilters.state.length})` || ''}`} className={'filter-block'}>
          {this.props.thing.state.states.map((state, i) => <Dropdown.Item
              eventKey={i}
              onClick={this.props.thing.toggleFilter}
              data-filtertype="state"
              key={state.id}
              className={this.props.thing.state.view.activeFilters.state.includes(state.name) ? 'filter-toggle active' : 'filter-toggle'}
            >
              {state.text}
            </Dropdown.Item>)}
        </DropdownButton>

        <div className="filter-block dropdown">
          <button className="btn dropdown-toggle" onClick={() => this.setState({ dateContainerOpen: !this.state.dateContainerOpen })}>
            {I18n.t('views.infraproject.date')}
          </button>
          {this.state.dateContainerOpen && <div className="datepicker-container">
            <FormControl
              className="filter-block two"
              placeholder="vvvv.kk.pp"
              name="filterFromDate"
              type="text"
              value={this.props.thing.state.view.filterFromDate}
              onChange={this.props.thing.onChangeFrom}
            />
            <FormControl
              className="filter-block two"
              placeholder="vvvv.kk.pp"
              name="filterToDate"
              type="text"
              value={this.props.thing.state.view.filterToDate}
              onChange={this.props.thing.onChangeTo}
            />
          </div> }
        </div>
        <div className="filter-block">
          <button onClick={this.props.thing.resetFilters} title={I18n.t('views.infraproject.filter-clear')}>
            <Undo className="filter-block-button" style={{ fontSize: '24px' }} />
          </button>
        </div>
        <div className="filter-block">
          <button onClick={this.props.toggleFlatmode}>
            {this.props.flatMode && <FolderPlus className="filter-block-button" style={{ fontSize: '24px' }} label={I18n.t('folders.enabled')} title={I18n.t('folders.disabled')} />}
            {!this.props.flatMode && <FolderMinus className="filter-block-button" style={{ fontSize: '24px' }} label={I18n.t('folders.enabled')} title={I18n.t('folders.enabled')} />}
          </button>
        </div>
      </div>
    </div>;
  }
}

export default DesktopFilters;
