import React, { Component } from 'react';
import { Form, Badge, Button, Row, Col, Modal } from 'react-bootstrap';
import I18n from 'i18n-js';
import AvainiaCore from 'avainia-core-api';
import { AngleRight } from '../Icon/Icon.js';
import Error from '../Error/Error.js';
import Loading from '../Loading/Loading.js';
import AvainiaTable from '../AvainiaTable/AvainiaTable.js';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';

// TODO: Replace user.new with user.id
export default class ModalCompanyEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: this.props.companyToEdit && this.props.companyToEdit.name ? this.props.companyToEdit.name : '',
      code: this.props.companyToEdit && this.props.companyToEdit.code ? this.props.companyToEdit.code : '',
      type: this.props.companyToEdit && this.props.companyToEdit.type ? this.props.companyToEdit.type : '',
      roles: this.props.companyToEdit && this.props.companyToEdit.roles ? this.props.companyToEdit.roles : '',
      users: [],
      manager: null,
      editor: null,
      userToEdit: null,
      loading: true,
      error: false,
    };
  }

  componentDidMount = () => {
    if (!this.props.companyToEdit) { return; }

    const api = new AvainiaCore(LocalStorageService.getToken);
    api.companyUsersGet(this.props.companyToEdit.id).then((users) => {
      if (users.error) { return this.setState({ error: users.error, loading: false }); }

      this.setState({ users, loading: false });
    });
  }

  handleSelectChange = (name, value) => { this.setState({ [name]: value.value }); }

  handleUserSelectChange = (name, value) => {
    const { userToEdit } = this.state;
    userToEdit.type = value.value;
    this.setState({ userToEdit, error: false });
  }

  onChange = (e) => { this.setState({ [e.target.name]: e.target.value }); }

  onUserFormChange = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    const { userToEdit } = this.state;

    userToEdit[name] = value;

    this.setState({ userToEdit });
  }

  onUserCategoryChange = (e) => {
    const { value } = e.target;
    const { userToEdit } = this.state;

    userToEdit.user_category_id = value;

    this.setState({ userToEdit });
  }

  saveCompany = () => {
    if (this.state.loading) { return; }

    this.setState({ loading: true }, () => {
      const payload = {
        id: this.props.companyToEdit.id,
        name: this.state.name,
        code: this.state.code,
      };

      const api = new AvainiaCore(LocalStorageService.getToken);
      api.companyUpdate(payload).then((result) => {
        if (result.error) { return this.setState({ error: result.error, loading: false }); }

        window.location.reload(); // TODO: Improve
      });
    });
  }

  saveUser = () => {
    // TODO: Refactor this mess
    const editedUser = this.state.userToEdit;
    const company = this.props.companyToEdit;
    const api = new AvainiaCore(LocalStorageService.getToken);

    if (!editedUser.user_category_id) {
      const defaultcat = this.props.userCategories.find((uc) => uc.default);
      if (defaultcat) {
        editedUser.user_category_id = defaultcat.id;
      }
    }

    const managerRole = this.props.companyToEdit.roles.find((x) => x.display_name === 'manager');
    const editorRole = this.props.companyToEdit.roles.find((x) => x.display_name === 'editor');

    if (editedUser.new) {
      this.setState({ loading: true }, () => {
        api.companyUsersCreate(company, editedUser).then((user) => {
          if (user.error) { return this.setState({ error: user.error, loading: false }); }

          const { manager } = this.state;
          const { editor } = this.state;

          const promises = [];
          if (manager) {
            promises.push(api.companyUserRoleAdd(company.id, user.id, managerRole.id));
          }
          if (editor) {
            promises.push(api.companyUserRoleAdd(company.id, user.id, editorRole.id));
          }

          Promise.all(promises).then((results) => {
            let error = false;

            results.forEach((item) => {
              if (item.error) { error = item.error; }
            });

            if (error) {
              // TODO! Should never happen and if does, is a fatal error that needs to be logged and reported
              return this.setState({ error, loading: false });
            }

            window.location.reload(); // TODO: Improve
          });
        });
      });
    } else {
      const userIsManager = !!(editedUser.roles || []).find((x) => x.display_name === 'manager');
      const userIsEditor = !!(editedUser.roles || []).find((x) => x.display_name === 'editor');
      const promises = [];

      const payload = {
        id: editedUser.id,
        email: editedUser.email,
        name: editedUser.name,
        user_category_id: editedUser.user_category_id,
      };

      const manager = this.userToEditIsManager();
      const editor = this.userToEditIsEditor();

      this.setState({ loading: true }, () => {
        api.companyUsersUpdate(company, payload).then((result) => {
          if (result.error) { return this.setState({ error: result.error, loading: false }); }

          if (manager) {
            if (!userIsManager) {
              promises.push(api.companyUserRoleAdd(company.id, editedUser.id, managerRole.id));
            }
          } else if (userIsManager) {
            promises.push(api.companyUserRoleRemove(company.id, editedUser.id, managerRole.id));
          }

          if (editor) {
            if (!userIsEditor) {
              promises.push(api.companyUserRoleAdd(company.id, editedUser.id, editorRole.id));
            }
          } else if (userIsEditor) {
            promises.push(api.companyUserRoleRemove(company.id, editedUser.id, editorRole.id));
          }

          Promise.all(promises).then((results) => {
            let error = false;

            results.forEach((item) => {
              if (item.error) { error = item.error; }
            });

            if (error) {
              // TODO! Should never happen and if does, is a fatal error that needs to be logged and reported
              return this.setState({ error, loading: false });
            }

            window.location.reload(); // TODO: Improve
          });
        });
      });
    }
  }

  removeCompany = () => {
    if (this.state.loading) { return; }

    this.setState({ loading: true }, () => {
      const company = this.props.companyToEdit;

      const api = new AvainiaCore(LocalStorageService.getToken);
      api.companyDelete(company).then((result) => {
        if (result.error) { return this.setState({ error: result.error, loading: false }); }

        window.location.reload(); // TODO: Improve
      });
    });
  }

  removeUser = () => {
    if (this.state.loading) { return; }

    this.setState({ loading: true }, () => {
      const company = this.props.companyToEdit;
      const user = this.state.userToEdit;

      const api = new AvainiaCore(LocalStorageService.getToken);
      api.companyUsersDelete(company, user).then((result) => {
        if (result.error) { return this.setState({ error: result.error, loading: false }); }

        window.location.reload(); // TODO: Improve
      });
    });
  }

  handleCompanyUsersRowClick = (e, rowData) => {
    this.setState({ userToEdit: rowData });
  }

  handleUserAddClick = (e) => {
    const defaultcat = this.props.userCategories.find((uc) => uc.default);
    const userToEdit = {
      new: true,
      name: '',
      email: '',
      phone: '',
      user_category_id: defaultcat ? defaultcat.id : null,
      roles: [],
    };
    this.setState({ userToEdit, error: false });
  }

  renderUserRoles = (roles) => {
    return <ul>
      {(roles || [])
        .map((role) => <li key={role.id}>{I18n.t(`constants.userTypes.${role.display_name}`)}</li>)}
    </ul>;
  }

  renderUserCategory = (targetId) => {
    // eslint-disable-next-line eqeqeq
    const cat = this.props.userCategories.find((uc) => uc.id == targetId);
    return cat ? <Badge pill variant="primary" style={{ marginLeft: 3 }}>{cat.name}</Badge> : '';
  }

  userToEditIsManager = () => {
    if (!this.state.userToEdit) { return false; }
    if (this.state.manager !== null) { return this.state.manager; }
    // TODO: Hardcoding is evil
    const managerRole = this.state.userToEdit.roles.find((r) => r.display_name === 'manager');
    return !!managerRole;
  }

  userToEditIsEditor = () => {
    if (!this.state.userToEdit) { return false; }
    if (this.state.editor !== null) { return this.state.editor; }
    return !!this.state.userToEdit.roles.find((r) => r.display_name === 'editor'); // TODO: Hardcoding is evil
  }

  wrap = (title, content) => {
    return <Modal show={true} onHide={this.props.onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          {I18n.t('views.companies.edit-company')}
          {this.state.userToEdit && <>
            <AngleRight />
            {title}
          </>}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {content}
      </Modal.Body>
    </Modal>;
  }

  toggleManager = () => { this.setState({ manager: !this.userToEditIsManager() }); }

  toggleEditor = () => { this.setState({ editor: !this.userToEditIsEditor() }); }

  resetPassword = () => {
    if (this.state.loading) { return; }

    if (!window.confirm(I18n.t('views.companies.reset-password-confirm'))) { return; }

    this.setState({ loading: true }, () => {
      const api = new AvainiaCore(LocalStorageService.getToken);
      api.userPasswordReset(this.state.userToEdit.email).then((result) => {
        if (result.error) { return this.setState({ error: result.error, loading: false }); }
        this.setState({ loading: false }, () => {
          return alert(I18n.t('views.companies.reset-password-email-sent'));
        });
      });
    });
  }

  render() {
    let title = '';
    let warning = false;

    const currentUser = LocalStorageService.getUser();

    if (this.state.userToEdit) {
      if (this.state.userToEdit.new) {
        title = I18n.t('views.companies.add-user');
      } else {
        title = I18n.t('views.companies.edit-user');
        warning = (this.state.userToEdit.id === currentUser.id) && I18n.t('views.companies.edit-self-warning');
      }
    }

    return this.wrap(title,
      <div>
        <Error inline error={this.state.error} />
        <Loading inline hide={!this.state.loading} />
        {!this.state.userToEdit && !this.state.loading &&
          <div className="container">
            <Row>
              <Col sm={12}>
                <h4>{I18n.t('views.companies.company-info')}</h4>
                <div className="tinyform">
                  <Form.Group as={Row}>
                    <Form.Control
                      placeholder={I18n.t('views.companies.name')}
                      type="text"
                      onChange={this.onChange}
                      name="name"
                      value={this.state.name}
                    />
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Control
                      placeholder={I18n.t('views.companies.code')}
                      type="text"
                      onChange={this.onChange}
                      name="code"
                      value={this.state.code}
                  />
                  </Form.Group>
                </div>
              </Col>
              <Col sm={12}>
                <h4>{I18n.t('views.companies.company-users')}</h4>
                <AvainiaTable
                  keyField='email'
                  data={this.state.users}
                  rowClickHandler={this.handleCompanyUsersRowClick}
                  columns={[
                    { dataField: 'name', text: I18n.t('views.companies.name') },
                    { dataField: 'email', text: I18n.t('general.email') },
                    {
                      dataField: 'user_category_id',
                      text: I18n.t('usercategories.usercategory'),
                      formatter: this.renderUserCategory,
                    },
                    { dataField: 'roles', text: I18n.t('user.roles'), formatter: this.renderUserRoles },
                  ]}
                />
                <Button variant="success" onClick={this.handleUserAddClick}>
                  {I18n.t('views.companies.user-add')}
                </Button>
              </Col>
            </Row>
            <hr />
            <div className="company-buttons">
              {/*
              Company removal has been temporarily disabled.
              Removing a company should also prevent its users from logging in.
              Also connections to other relations should be checked and disabled to prevent unforeseen issues.

              {!this.props.disableRemoval && <Button variant="danger" onClick={this.removeCompany}>
                {I18n.t('general.delete')}
              </Button>}
              */}
              <Button variant="success" onClick={this.saveCompany}>{I18n.t('general.modal-form-save')}</Button>
              <Button variant="secondary" onClick={this.props.onHide}>{I18n.t('general.modal-close')}</Button>
            </div>
          </div>
        }

        {this.state.userToEdit && !this.state.loading &&
          <div className="container">
            {warning && <h5>{warning}</h5>}
            <Row>{I18n.t('general.roles')}</Row>
            <Row>
              <Form.Check type="checkbox" label={I18n.t('constants.userTypes.employee')} checked={true} disabled />
            </Row>
            <Row>
              <Form.Check
                type="checkbox"
                label={I18n.t('constants.userTypes.manager')}
                checked={this.userToEditIsManager()}
                onChange={this.toggleManager}
              />
            </Row>
            <Row>
              <Form.Check
                type="checkbox"
                label={I18n.t('constants.userTypes.editor')}
                checked={this.userToEditIsEditor()}
                onChange={this.toggleEditor}
              />
            </Row>

            <hr />
            <Row>{I18n.t('usercategories.heading')}</Row>
            {this.props.userCategories.map((uc) => {
              const user = this.state.userToEdit;
              // Allow checking both string and int
              // eslint-disable-next-line eqeqeq
              const checked = user.user_category_id ? user.user_category_id == uc.id : uc.default;
              return <Row key={uc.id}>
                <Form.Check
                  type="radio"
                  label={uc.name}
                  checked={checked}
                  onChange={this.onUserCategoryChange}
                  value={uc.id}
                />
              </Row>;
            })}
            <hr />
            <Form.Group as={Row}>
              <Form.Control
                placeholder={I18n.t('views.companies.name')}
                type="text"
                onChange={this.onUserFormChange}
                name="name"
                value={this.state.userToEdit.name}
              />
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Control
                placeholder={I18n.t('general.email')}
                type="text"
                onChange={this.onUserFormChange}
                name="email"
                value={this.state.userToEdit.email}
              />
            </Form.Group>
            {/* <Form.Group as={Row}>
              <Form.Control
                placeholder={I18n.t('views.companies.phone')}
                type="text"
                onChange={this.onUserFormChange}
                name="phone"
                value={this.state.userToEdit.phone}
              />
            </Form.Group> */}
            <hr />
            <div className="user-buttons">
              {!this.state.userToEdit.new && <>
                <Button variant="danger" onClick={this.removeUser}>{I18n.t('general.delete')}</Button>
                <Button variant="info" onClick={this.resetPassword}>{I18n.t('views.companies.reset-password')}</Button>
              </>}
              <Button variant="success" onClick={this.saveUser}>{I18n.t('general.modal-form-save')}</Button>
              <Button variant="secondary" onClick={() => { this.setState({ userToEdit: null, error: false }); }}>
                {I18n.t('general.return')}
              </Button>
            </div>
          </div>
        }
      </div>);
  }
}
