import React, { Component } from 'react';
import { Button, ListGroup, InputGroup, Form } from 'react-bootstrap';
import I18n from 'i18n-js';
import AvainiaCore from 'avainia-core-api';
import { Trash, Edit } from '../../multiview/Icon/Icon.js';
import AvainiaTable from '../../multiview/AvainiaTable/AvainiaTable.js';
import AvainiaTableHeading from '../../multiview/AvainiaTable/AvainiaTableHeading.js';
import AvainiaPanel from '../../multiview/Panels/AvainiaPanel.js';
import Error from '../../multiview/Error/Error.js';
import Loading from '../../multiview/Loading/Loading.js';
import ProductTagEditModal from './ProductTagEditModal.js';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';
import './Products.scss';

const Modals = {
  editTag: 1,
};

class ProductTags extends Component {

  constructor(props) {
    super(props);

    this.state = {
      productTags: [],
      products: [],
      tagName: '',
      activeTag: false,
      editing: false,
      modal: false,
      loading: true,
      secondaryloading: false,
      error: false,
      secondaryerror: false,
      showHidden: false,
    };
  }

  componentDidMount = () => {
    const api = new AvainiaCore(LocalStorageService.getToken);

    api.tagsGet().then((productTags) => {
      if (productTags.error) { return this.setState({ error: productTags.error }); }
      if (productTags) {
        this.setState({ productTags, loading: false }, this.context.resetTopbar);
      }
    });
  }

  onChange = (e) => { this.setState({ [e.target.name]: e.target.value }); }

  doSaveTag = (e) => {
    this.setState({ loading: true }, () => {
      const payload = { name: this.state.tagName };
      const api = new AvainiaCore(LocalStorageService.getToken);

      api.tagCreate(payload).then((productTag) => {
        if (productTag.error) { return this.setState({ error: productTag.error }); }
        const { productTags } = this.state;
        productTags.push(productTag);
        this.setState({ productTags, loading: false, tagName: '' });
      });
    });
  }

  deleteTag = (id) => {
    // TODO: implement UI
    // TODO: Confirm
    this.setState({ loading: true }, () => {
      const api = new AvainiaCore(LocalStorageService.getToken);
      api.tagDelete(id).then((result) => {
        if (result.error) { return this.setState({ error: result.error }); }
        let { productTags } = this.state;
        productTags = productTags.filter((x) => x.id !== id);
        this.setState({ productTags, loading: false });
      });
    });
  }

  editTag = (id) => {
    if (this.state.loading) { return; }

    const editingTarget = this.state.productTags.find((x) => x.id === id);
    this.setState({ editing: editingTarget, modal: Modals.editTag });
  }

  tagEditCallback = (id, name) => {
    const { productTags } = this.state;

    const target = productTags.find((x) => x.id === id);
    if (target) { target.name = name; }

    this.setState({ productTags });
  }

  activateTag = (activeTag, e) => {
    const act = document.querySelector('.pseudolink.active');
    if (act) { act.classList.toggle('active'); }
    e.target.classList.toggle('active');
    this.setState({ activeTag, secondaryloading: true }, () => {
      const api = new AvainiaCore(LocalStorageService.getToken);
      api.tagProductsGet(activeTag.id).then((products) => {
        if (products.error) { return this.setState({ secondaryerror: products.error }); }

        this.setState({ products, loading: false, secondaryloading: false });
      });
    });
  }

  hideModal = () => { this.setState({ modal: false }); }


  render() {
    if (this.state.error) { return <Error error={this.state.error} />; }
    if (this.state.loading) { return <Loading />; }

    let filteredProducts = this.state.products;

    if(!this.state.showHidden){
      filteredProducts = filteredProducts.filter((x) => x.hidden === 0);
    } else {
      filteredProducts = filteredProducts.filter((x) => x.hidden === 1);
    }

    return (

      <div className="App-container"> 
        <h1>{I18n.t('views.products.productTags')}</h1>
        <ListGroup className="listing">
          {this.state.productTags.map((pt) => <ListGroup.Item key={pt.id}>
            <span className="clickable" onClick={(e) => this.activateTag(pt, e)}>{pt.name}</span>
            <Trash onClick={() => { this.deleteTag(pt.id); }} />
            <Edit onClick={() => { this.editTag(pt.id); }} />
          </ListGroup.Item>)}
        </ListGroup>
        {this.state.modal === Modals.editTag &&
          <ProductTagEditModal productTag={this.state.editing} onHide={this.hideModal} editCallback={this.tagEditCallback} />
        }

        <h3>{I18n.t('views.products.create-new-product-tag')}</h3>

        <InputGroup>
          <Form.Control placeholder={I18n.t('views.products.name')}type="text" onChange={this.onChange} name="tagName" value={this.state.tagName} />
          <InputGroup.Append>
          <Button variant="primary" onClick={this.doSaveTag}>
            {I18n.t('views.products.button-create')}
          </Button>
          </InputGroup.Append>
        </InputGroup>


        {/* TODO: new className */}
        {this.state.activeTag && <div className="categoryproducts">
          <AvainiaPanel icon={null} heading={null}>

            { this.state.secondaryerror && <Error inline error={this.state.secondaryerror} /> }
            { this.state.secondaryloading && <Loading inline /> }

            <AvainiaTableHeading
              title={`${I18n.t('views.products.products-for-tag')} ${this.state.activeTag.name}`}
              button={
		           <div style={{display:"flex"}}>
	              <Button variant="secondary" onClick={() => {this.setState({ showHidden: !this.state.showHidden });}} size="m">
                  {!this.state.showHidden && <span>Näytä piilotetut</span> } {/* TODO translate */}
                  {this.state.showHidden && <span>Näytä aktiiviset</span> } {/* TODO translate */}
    		        </Button>
		           </div>
              }
            />
            <AvainiaTable data={filteredProducts} keyField="id" columns={[
              { dataField: 'id', text: I18n.t('general.id'), headerStyle: { width: '60px' } },
              { dataField: 'manufacturer', text: I18n.t('views.products.manufacturer') },
              { dataField: 'code', text: I18n.t('views.products.code') },
              { dataField: 'name', text: I18n.t('views.products.name') },
              { dataField: 'description', text: I18n.t('views.products.description') },
              { dataField: 'price', text: I18n.t('views.products.price') },
              { dataField: 'measurements', text: I18n.t('views.products.measurements') },
              { dataField: 'external_link', text: I18n.t('views.products.external_link') },
              { dataField: 'loss_percentage', text: I18n.t('views.products.loss_percentage') },
              { dataField: 'package_size', text: I18n.t('views.products.package_size') },
            ]} />
          </AvainiaPanel>
        </div>}
      </div>
    );
  }
}

export default ProductTags;
