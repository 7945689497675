import React from 'react';
import I18n from 'i18n-js';
import { Alert, OverlayTrigger, Fade } from 'react-bootstrap';
import { InfoCircle } from '../Icon/Icon.js';

/**
 * Non-DRY component copy pasted from Error.js
 * TODO: make an abstracted/general alert component to handle all alert variants
 */
export default function Success(props) {
  if (!props.success) {
    return null;
  }

  if (props.inline) {
    return (
      <Alert variant="success" transition={props.transition} dismissible={props.dismissible} show={props.show ?? true}>
        {props.success}
        {props.info && (
          <OverlayTrigger placement="right" overlay={props.popoverInfo}>
            <InfoCircle />
          </OverlayTrigger>
        )}
      </Alert>
    );
  }

  return (
    <div className="App-main">
      <Alert variant="success" className="success" transition={props.transition} dismissible={props.dismissible} show={props.show ?? true}>
        {props.success}
        {props.info && (
          <OverlayTrigger placement="right" overlay={props.popoverInfo}>
            <InfoCircle />
          </OverlayTrigger>
        )}
      </Alert>
    </div>
  );
}
