import React, { Component } from 'react';
import I18n from 'i18n-js';
import { Button, Modal, Form, Badge, FormControl } from 'react-bootstrap';
import AvainiaCore from 'avainia-core-api';
import Error from '../Error/Error.js';
import Loading from '../Loading/Loading.js';

import '../../mainviews/Notifications/notificationsAdmin.scss';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';

class ModalNotificationCreate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: '',
      body: '',
      attachment: {},
      scope: '',
      scope_id: '',
      published: false,
      loading: false,
      error: false,
      selectedFile: null,
      notifiable: 0,
      visibility: null,
      projects: null,
      selectedTargetIds: {
        global: {}
      },
    };
  }

  componentDidMount = () => {
    const api = new AvainiaCore(LocalStorageService.getToken);

    api.projectsGet().then((projects) => {
      if (projects.error) { return this.setState({ error: projects.error }); }

      api.condominiumsGet().then((condominiums) => {
        if (condominiums.error) { return this.setState({ error: condominiums.error }); }

        const condoId = condominiums[0].id;

        if (condominiums[0]) {
          api.condominiumApartmentsGet(condoId).then((apartments) => {
            if (apartments.error) { return this.setState({ error: apartments.error }); }

            this.setState({
              apartments,
              projects,
              condominiums,
              loading: false,
            });
          })
        };
      });
    });
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleFileChange = (e) => {
    if (e.target.files[0]) {
      const { name } = e.target;
      const reader = new FileReader();
      const file = e.target.files[0];

      reader.onload = () => {
        this.setState((prevState) => ({
          [name]: file,
          selectedFile: file.name,
        }));
      };
      reader.readAsDataURL(file);
    }
  }

  removeSelectedFile = () => {
    this.setState({
      selectedFile: null,
      attachment: {},
    });
  }

  handlePublishChange = (e) => {
    this.setState({ published: e.target.checked });
  }

  createNotification = (e) => {
    if (this.state.loading) {
      return;
    }

    if (this.props.requiredFieldsCheck(this.state)) {
      let ids = [];
      const targetIds = this.state.selectedTargetIds[this.state.visibility]

      if (targetIds && Object.keys(targetIds).length === 0 && targetIds.constructor === Object) {
        ids = [];
      } else {
        for (const [key, value] of Object.entries(targetIds)) {
          if (value === true) {
            ids.push(parseInt(key));
          }
        }
      }

      this.setState(
        { requiredError: false, loading: true, error: false },
        () => {
          const payload = {
            title: this.state.title,
            body: this.state.body,
            is_published: this.state.published,
            notifiable: this.state.notifiable,
            visibility: this.state.visibility,
            selectedTargetIds: ids
          };

          if (this.state.attachment && this.state.attachment.name) {
            payload.attachment = this.state.attachment;
          }

          const api = new AvainiaCore(LocalStorageService.getToken);
          api.notificationCreate(payload).then((notification) => {
            if (notification.errorCode) { return this.setState({ error: notification.errorCode.code, loading: false }); }

            this.setState({
              title: '',
              body: '',
              attachment: {},
              published: false,
              loading: false,
              notifiable: false,
              visibility: null,
              selectedTargetIds: {}
            });
            this.props.onHide();
            this.props.notificationCallback();
          });
        },
      );
    } else {
      this.setState({ requiredError: I18n.t('general.required') });
    }
  };

  onSelectChange = (e) => {
    let value = e.target.value;
    if (value === 'global') {
      this.setState({ notifiable: 0, visibility: value, selectedTargetIds: { global: {} } })
    } else {
      this.setState({ notifiable: 1, visibility: value, selectedTargetIds: { ...this.state.selectedTargetIds[value] } })
    };
  }

  handleNotificationRangeChange = (e) => {
    const type = this.state.visibility
    this.setState({
      selectedTargetIds: {
        ...this.state.selectedTargetIds, [type]: {
          ...this.state.selectedTargetIds[type], [e.target.name]: e.target.checked
        }
      }
    });
  }

  onSelectCondoForApartmentsChange = (e) => {
    const condoId = e.target.value
    const api = new AvainiaCore(LocalStorageService.getToken);

    api.condominiumApartmentsGet(condoId).then((apartments) => {
      if (apartments.error) { return this.setState({ error: apartments.error }); }

      this.setState({
        apartments,
        selectedTargetIds: {},
        loading: false,
      });
    });
  }

  render() {
    const visibilities = [
      { id: null, type: null, name: "-" },
      { id: 0, type: 'global', name: I18n.t('views.notifications.global') },
      { id: 1, type: 'project', name: I18n.t('views.notifications.project') },
      ...this.props.configuration.condominiums ? [{ id: 2, type: 'condominium', name: I18n.t('views.notifications.condominium') }] : [],
      ...this.props.configuration.condominiums ? [{ id: 3, type: 'apartment', name: I18n.t('views.notifications.apartment') }] : [],
    ]

    return (
      <Modal show={ true } onHide={ this.props.onHide }>
        <Modal.Header closeButton>
          <Modal.Title>{ I18n.t('views.notifications.add-notification') }</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          { this.state.error && <Error error={ this.state.error } inline /> }
          { this.state.loading && <Loading inline /> }
          { !this.state.loading && (
            <>
              <Form.Group controlId='create-modal-title'>
                <Form.Label>{ I18n.t('views.notifications.title') }</Form.Label>
                <Form.Control
                  type='text'
                  onChange={ this.onChange }
                  name='title'
                  value={ this.state.title }
                />
                <Badge variant='danger'>{ this.state.requiredError }</Badge>
              </Form.Group>
              <Form.Group controlId='create-modal-body'>
                <Form.Label>{ I18n.t('views.notifications.body') }</Form.Label>
                <Form.Control
                  as="textarea"
                  rows="5"
                  onChange={ this.onChange }
                  name='body'
                  value={ this.state.body }
                />
                <Badge variant='danger'>{ this.state.requiredError }</Badge>
              </Form.Group>
              <Form.Group controlId="publish-checkbox">
                <Form.Check
                  type='checkbox'
                  label={ I18n.t('views.notifications.publish') }
                  checked={ this.state.published }
                  onChange={ this.handlePublishChange }
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>{ I18n.t('views.notifications.attachment') }</Form.Label>
                <br />
                <Form.Control
                  type="file"
                  id="inputfile"
                  className="inputfile"
                  onChange={ this.handleFileChange }
                  name="attachment"
                />
                <label className="label-select-file" htmlFor="inputfile">{ I18n.t('views.notifications.select-file') }</label>

                { this.state.selectedFile && <p>
                  { this.state.selectedFile }
                  <button onClick={ this.removeSelectedFile }>
                    { I18n.t('general.delete') }
                  </button>
                </p> }

              </Form.Group>

              <Form.Group>
                <Form.Label>{ I18n.t('views.notifications.visibility') }</Form.Label>
                <FormControl
                  as="select"
                  onChange={ this.onSelectChange }
                  name="Visibility"
                  placeholder={ I18n.t('views.notifications.visibility') }
                >
                  {
                    visibilities.map((visibility) => {
                      return <option key={ visibility.id } value={ visibility.type }>{ visibility.name } </option>;
                    })
                  }
                </FormControl>
              </Form.Group>

              {
                this.state.visibility && this.state.visibility === 'project' &&
                this.state.projects.map((project) =>
                  <Form.Check
                    type='checkbox'
                    label={ project.id + ' : ' + project.name }
                    checked={ this.state.selectedTargetIds.project ? this.state.selectedTargetIds.project[project.id] : false }

                    onChange={ this.handleNotificationRangeChange }
                    name={ project.id }
                  />
                )
              }
              {
                this.props.configuration.condominiums && this.state.visibility && this.state.visibility === 'condominium' &&
                this.state.condominiums.map((condo) =>
                  <Form.Check
                    type='checkbox'
                    label={ condo.id + ' : ' + condo.name }
                    checked={ this.state.selectedTargetIds.condominium ? this.state.selectedTargetIds.condominium[condo.id] : false }
                    onChange={ this.handleNotificationRangeChange }
                    name={ condo.id }
                  />
                )
              }
              {
                this.props.configuration.condominiums && this.state.visibility && this.state.visibility === 'apartment' && <>
                  <FormControl
                    as="select"
                    onChange={ this.onSelectCondoForApartmentsChange }
                    name="Select from condo"
                    placeholder={ I18n.t('views.notifications.visibility') }
                  >
                    {
                      this.state.condominiums.map((condo) => {
                        return <option key={ condo.id } value={ condo.id }>{ condo.name } </option>;
                      })
                    }
                  </FormControl>
                  { this.state.apartments.map((apt) =>
                    <Form.Check
                      type='checkbox'
                      label={ apt.id + ' : ' + apt.apartment_number + ' ' + apt.stairwell }
                      checked={ this.state.selectedTargetIds.apartment ? this.state.selectedTargetIds.apartment[apt.id] : false }
                      onChange={ this.handleNotificationRangeChange }
                      name={ apt.id }
                    />
                  ) }
                </>
              }

            </>
          ) }
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={ this.props.onHide }>
            { I18n.t('views.notifications.button-cancel') }
          </Button>
          <Button variant='primary' onClick={ this.createNotification }>
            { I18n.t('views.notifications.button-save') }
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ModalNotificationCreate;
