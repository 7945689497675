import React, { Component } from 'react';
import { Button, Modal, Row, Col, Badge } from 'react-bootstrap';
import I18n from 'i18n-js';

class ProjectProductReportModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      additionalProducts: [],
      materialSelections: [],
      apartmentns: [],
    };
  }

  componentDidMount = () => {
    const { additionalProducts, materialSelections, apartments } = this.props.data;

    this.setState({
      additionalProducts,
      materialSelections,
      apartments
    });
  }

  render() {
    const { additionalProducts, materialSelections, apartments } = this.state;
    let sortedMaterialSelections = {};
    let sortedAdditionalProducts = {};

    materialSelections.forEach(materialSelection => {
      if (!sortedMaterialSelections[materialSelection.material_form_option.product_id]) {
        sortedMaterialSelections[materialSelection.material_form_option.product_id] = [materialSelection];
      } else {
        sortedMaterialSelections[materialSelection.material_form_option.product_id].push(materialSelection)
      }
    });

    additionalProducts.forEach(additionalProduct => {
      if (!sortedAdditionalProducts[additionalProduct.additional_product_id]) {
        sortedAdditionalProducts[additionalProduct.additional_product_id] = [additionalProduct];
      } else {
        sortedAdditionalProducts[additionalProduct.additional_product_id].push(additionalProduct)
      }
    });

    return <Modal show={true} onHide={this.props.onHide} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>{I18n.t('views.productReports.product-report')}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{margin: '10px'}}>
        <div>
          <Row>
            {I18n.t('views.productReports.apartments-listed')}
          </Row>
          {apartments && apartments.map((apartment) => 
            <Badge variant="info" style={{ marginLeft: 3 }}>
              {apartment.apartment_number}{apartment.stairwell}
            </Badge>
          )}
          <Row>{I18n.t('views.productReports.products')}</Row>
          <Row>
            <Col xs={1}>{I18n.t('views.productReports.product-id')}</Col>
            <Col xs={2}>{I18n.t('views.productReports.product-code')}</Col>
            <Col xs={5}>{I18n.t('views.productReports.product-name')}</Col>
            <Col xs={1}>{I18n.t('views.productReports.product-amount')}</Col>
          </Row>
          {
            Object.values(sortedMaterialSelections).map((selection) => {
              const selectionProductId = selection[0].material_form_option.product_id;
              let amount = 0;
              let amountType = '';
              selection.map(sel => {
                amount += sel.material_form_option.package_amount;
                amountType = sel.material_form_select.area === null ? I18n.t('views.productReports.pcs') : I18n.t('views.productReports.packages');
              });

              return (
                <Row>
                  <Col xs={1}>{selectionProductId}</Col>
                  <Col xs={2}>{selection[0].material_form_option.product.code}</Col>
                  <Col xs={5}>{selection[0].material_form_option.product.name}</Col>
                  <Col xs={1}>{amount}</Col>
                  <Col xs={3}>{amountType}</Col>
                </Row>
              )
            })
          }
          <br />
          <Row>Lisätuotteet</Row>

          {
            Object.values(sortedAdditionalProducts).map((selection) => {
              const selectionProductId = selection[0].additional_product_id;
              let amount = 0;
              let amountType = I18n.t('views.productReports.pcs');
              selection.map(sel => {
                amount += 1;
              });

              return (
                <Row>
                  <Col xs={1}>{selectionProductId}</Col>
                  <Col xs={2}>{selection[0].additional_product.code}</Col>
                  <Col xs={5}>{selection[0].additional_product.name}</Col>
                  <Col xs={1}>{amount}</Col>
                  <Col xs={3}>{amountType}</Col>
                </Row>
              )
            })
          }
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={this.props.onHide}>{I18n.t('views.materialforms.button-cancel')}</Button>
      </Modal.Footer>
    </Modal>;
  }
}

export default ProjectProductReportModal;
