import React, { Component } from 'react';
import I18n from 'i18n-js';
import { Button, Modal, ListGroup } from 'react-bootstrap';
import AvainiaCore from 'avainia-core-api';
import Error from '../Error/Error.js';
import Loading from '../Loading/Loading.js';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';

class LinkMaterialformModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
    };
  }

  linkTemplate = (apartmentTemplate) => {
    if (this.state.loading) { return; }

    if (!window.confirm(I18n.t('views.materialforms.confirm-link'))) { return; }

    this.setState({ loading: true }, () => {
      const payload = { material_form_id: this.props.materialform.id, is_published: true };
      const api = new AvainiaCore(LocalStorageService.getToken);
      api.condominiumApartmentTemplateMaterialformsCreate(apartmentTemplate.id, payload).then((result) => {
        if (result.error) { return this.setState({ error: result.error }); }

        window.location.reload(); // TODO: Improve
      });
    });
  }

  unlinkTemplate = (apartmentTemplate) => {
    if (this.state.loading) { return; }

    if (!window.confirm(I18n.t('views.materialforms.confirm-unlink'))) { return; }

    this.setState({ loading: true }, () => {
      const api = new AvainiaCore(LocalStorageService.getToken);
      api.condominiumApartmentTemplateMaterialformsDelete(apartmentTemplate.id, this.props.materialform.id).then((result) => {
        if (result.error) { return this.setState({ error: result.error }); }

        window.location.reload(); // TODO: Improve
      });
    });
  }

  onChange = (e) => { this.setState({ [e.target.name]: e.target.value }); }

  getMaterialformsForApartTemplate = (tmpl) => {
    // eslint-disable-next-line eqeqeq
    return tmpl.materialforms.filter((x) => x.id == this.props.materialform.id);
  }

  render() {
    return <Modal show={ true } onHide={ this.props.onHide } size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{ I18n.t('views.materialforms.link-matform-to-template') }</Modal.Title>
      </Modal.Header>
      <Modal.Body>

        { this.state.error && <Error inline error={ this.state.error } /> }
        { !this.state.error && this.state.loading && <Loading inline /> }
        { !this.state.error && !this.state.loading && <>
          <h3>{ I18n.t('views.materialforms.materialform') } <i>{ this.props.materialform.name }</i></h3>
          <p>{ I18n.t('views.projects.project') } <i>{ this.props.project.name }</i></p>
          <h5>{ I18n.t('views.condominiums.apartmenttemplates') }</h5>
          <ListGroup className="listing">
            { this.props.apartmentTemplates.map((tmpl) => <ListGroup.Item key={ tmpl.id }>
              { tmpl.name }
              { this.getMaterialformsForApartTemplate(tmpl).length === 0 ?
                <Button variant="primary" onClick={ () => { this.linkTemplate(tmpl); } } className="floatright">
                  { I18n.t('views.materialforms.button-add-link') }
                </Button>
                :
                <>
                  <Button variant="secondary" onClick={ () => { this.linkTemplate(tmpl); } } style={{marginLeft:"20px"}} className="floatright">
                    { I18n.t('views.materialforms.button-link-existing') }
                  </Button>
                  <Button variant="secondary" onClick={ () => { this.unlinkTemplate(tmpl); } } className="floatright">
                    { I18n.t('views.materialforms.button-remove-link') }
                  </Button>
                </> }
            </ListGroup.Item>) }
          </ListGroup>
        </> }

        {/* TODO: Create debugging thing out of this? */ }
        <pre style={ { display: 'none', height: '200px', border: '2px dashed #eee', padding: '2px', fontSize: '80%', overflowY: 'scroll' } }>{ JSON.stringify(this.props, undefined, 2) }</pre>

      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={ this.props.onHide }>{ I18n.t('views.materialforms.button-cancel') }</Button>
      </Modal.Footer>
    </Modal>;
  }
}

export default LinkMaterialformModal;
