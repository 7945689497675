/* eslint-disable max-classes-per-file */
import React, { Component } from 'react';
import I18n from 'i18n-js';
import { Accordion, Card, Col, Row, Button, Form, FormControl, Modal } from 'react-bootstrap';
import AvainiaCore from 'avainia-core-api';
import { Trash, AngleRight, ArrowsVertical } from '../Icon/Icon.js';
import UtilService from '../../../AvainiaTools/UtilService.js';
import Error from '../Error/Error.js';
import Loading from '../Loading/Loading.js';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';
import { ReactSortable } from "react-sortablejs";
import './EditMaterialformModal.scss';

class EditMaterialformModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      materialform: {
        id: false,
        name: '',
        groups: [],
      },
      productCategories: [],
      additionalProducts: [],
      selectedAdditionalProducts: [],
      nameGroup: '',
      newGroupActive: false,
      activeCategory: false,
      loading: true,
      error: false,
    };
  }

  componentDidMount = () => {
    const api = new AvainiaCore(LocalStorageService.getToken);
    api.productCategoriesGet().then((productCategories) => {
      if (productCategories.error) { return this.setState({ error: productCategories.error }); }

      // Properly initiate materialform
      const api = new AvainiaCore(LocalStorageService.getToken);
      api.projectMaterialFormGet(this.props.project.id, this.props.materialform.id).then((result) => {
        if (result.error) { return this.setState({ error: result.error }); }

        api.additionalProductsGet().then((additionalProducts) => {
          if (additionalProducts.error) { return this.setState({ error: additionalProducts.error }); }

          api.additionalProductsForMaterialFormGet(this.props.materialform.id).then((selectedAdditionalProducts) => {
            if (selectedAdditionalProducts.error) { return this.setState({ error: selectedAdditionalProducts.error }); }

            let selected = [];
            selectedAdditionalProducts.map((product) => (
                selected.push({id: product.id, price: product.price, labor_price: product.labor_price})
              )
            );

            UtilService.convertMaterialform(result).then((materialform) => {
              if (materialform.error) { return this.setState({ error: materialform.error }); }

              return this.setState({
                materialform,
                name: materialform.name,
                description: materialform.description,
                productCategories,
                loading: false,
                additionalProducts,
                selectedAdditionalProducts: selected
              })
            });
          });
        });
      });
    });
  }

  componentDidUpdate = () => {
    if (!this.props.materialform) { return; }
    if (this.props.materialform.id === this.state.materialform.id) { return; }
    this.setState({ materialform: this.props.materialform });
  }

  activateCategory = (activeCategory, e) => {
    this.setState({ activeCategory, loading: true }, () => {
      const api = new AvainiaCore(LocalStorageService.getToken);
      api.productsGet(activeCategory.id).then((products) => {
        if (products.error) { return this.setState({ error: products.error }); }

        this.setState({ products, loading: false });
      });
    });
  }

  save = async () => {
    if (this.state.loading) { return; }

    // TODO! There is a bug here. We should FIRST delete using a top-down approach and then do the update/Create separately
    // TODO! There is a 2nd bug here. It's not possible to select the default option AND set the create the options at the same time, because the option IDs are not available.
    // The bug is triggered when someone deletes a top-level element (group) and then its children (sels, opts) are attempted to save

    const { materialform } = this.state;

    this.setState({ loading: true }, async () => {
      const payload = {
        name: materialform.name,
        additionalProducts: this.state.selectedAdditionalProducts,
      };

      let error = false;

      // Update Materialform
      const api = new AvainiaCore(LocalStorageService.getToken);

      await api.projectMaterialFormsUpdate(this.props.project.id, materialform.id, payload).then((result) => {
        if (result.error) { error = result.error; }
      });
      if (error) { return this.setState({ error }); }

      // Delete everything
      let promises = [];
      materialform.groups.forEach((g) => {
        if (g.deleted && !g.isNew) { promises.push(api.projectMaterialFormsGroupsDelete(materialform.id, g.id)); }
        g.selects.forEach((s) => {
          if (s.deleted && !s.isNew) { promises.push(api.projectMaterialFormsSelectDelete(g.id, s.id)); }
          s.options.forEach((o) => {
            if (o.deleted && !o.isNew) { promises.push(api.projectMaterialFormsOptionsDelete(s.id, o.id)); }
          });
        });
      });

      await Promise.all(promises).then((results) => {
        results.forEach((result) => { if (result.error) { error = result.error; } });
      });
      if (error) { return this.setState({ error }); }

      // Update everything
      promises = [];
      materialform.groups.forEach((g) => {
        if (g.deleted) { return; }
        
        const payload = {
          id: g.id,
          name: g.name,
          description: g.description,
          order_index: g.order_index
        };
        
        if (!g.isNew) {
          promises.push(api.projectMaterialFormsGroupsUpdate(materialform.id, payload));
        }
        g.selects.forEach((s) => {
          const payload = { id: s.id, name: s.name, area: s.area ? s.area : 0, product_category_id: s.product_category_id };

          if (s.deleted) return;
          if (!s.isNew) {
            promises.push(api.projectMaterialFormsSelectUpdate(g.id, payload));
          }
          s.options.forEach((o) => {

            //if (o.deleted || !o.isNew) return;
            const packageAmount = o.package_amount === null ? 1 : o.package_amount;
            const payload = { id: o.id, name: o.name, price: o.price, labor_price: o.labor_price, product_id: o.product_id, package_amount: parseInt(packageAmount), order_index: o.order_index, is_contractual: o.is_contractual};
            //promises.push(api.projectMaterialFormsOptionUpdate(s.id, payload));
            if (!o.isNew && !o.deleted) { promises.push(api.projectMaterialFormsOptionUpdate(s.id, payload)); }
          });
        });
      });

      await Promise.all(promises).then((results) => {
        results.forEach((result) => { if (result.error) { error = result.error; } });
      });
      if (error) { return this.setState({ error }); }

      // Save everyting and mark those that need to be updated
      await Promise.all(await materialform.groups.map(async (g) => {
        if (error || g.deleted) { return; }

        const payload = { name: g.name, description: g.description, order_index: g.order_index };
        if (g.isNew) {
          const result = await api.projectMaterialFormsGroupsCreate(materialform.id, payload);
          if (result.error) { error = result.error; return; }
          g.id = result.id;
        }

        await Promise.all(await g.selects.map(async (s) => {
          if (error || s.deleted) { return; }

          const payload = { name: s.name, area: s.area ? s.area : 0, product_category_id: s.product_category_id };
          if (s.isNew) {
            const result = await api.projectMaterialFormsSelectCreate(g.id, payload);
            if (result.error) { error = result.error; return; }
            s.id = result.id;
            s.isNew = false; // this marks the new select as created
          }

          await Promise.all(await s.options.map(async (o) => {
            if (error || o.deleted || !o.isNew) { return; }
            if (s.isNew) { return; } // if select wasn't created, dont create option either
            const packageAmount = o.package_amount === null ? 1 : o.package_amount;
            const payload = { name: o.name, price: o.price, labor_price: o.labor_price, product_id: o.product_id, package_amount: parseInt(packageAmount), order_index: o.order_index, is_contractual: o.is_contractual };
            const { id } = o;
            const result = await api.projectMaterialFormsOptionCreate(s.id, payload);
            if (result.error) { error = result.error; return; }
            o.oldId = id;
            o.id = result.id;
          }));
        }));
      }));
      if (error) { return this.setState({ error }); }

      // Loop through everything and update selects that have a default_option_id set --- find the new ID of their matching options
      promises = [];
      materialform.groups.forEach((g) => {
        if (error || g.deleted) { return; }
        g.selects.forEach((s) => {
          if (error || s.deleted) { return; }
          if (!s.options || s.options.length === 0 || !s.options.find(o => !o.deleted)) { return; }

          const payload = { id: s.id, name: s.name, area: s.area ? s.area : 0, product_category_id: s.product_category_id };

          // eslint-disable-next-line eqeqeq
          const targetDefaultOption = s.options.find((o) => o.oldId == s.default_option_id || o.id == s.default_option_id);

          if (!targetDefaultOption || targetDefaultOption.deleted) { error = 21; return; } // TODO! Add translation
          payload.default_option_id = targetDefaultOption.id;

          return promises.push(api.projectMaterialFormsSelectUpdate(g.id, payload));
        });
      });
      if (error) { return this.setState({ error }); }

      await Promise.all(promises).then((results) => {
        results.forEach((result) => { if (result.error) { error = result.error; } });
      });
      if (error) { return this.setState({ error }); }

      return this.props.refreshModal(); // TODO: After save, show alert and update the whole page, the view update to match IDs is a PITA
    });
  }

  onChange = (e) => { this.setState({ [e.target.name]: e.target.value }); }

  onChangeMaterialform = (e) => {
    const materialform = { ...this.state.materialform };
    materialform[e.target.name] = e.target.value;
    this.setState({ materialform });
  }

  addNewGroup = () => {
    if (this.state.nameGroup === '') { return; }
    const g = this.state.materialform.groups || [];
    const groups = [...g];
    groups.push({
      id: UtilService.uuidv4(),
      name: this.state.nameGroup,
      selects: [],
      isNew: true,
    });

    const materialform = { ...this.state.materialform };
    materialform.groups = groups;
    this.setState({ materialform, newGroupActive: false, nameGroup: '' });
  }

  updateGroup = (updatedGroup) => {
    const g = this.state.materialform.groups || [];
    let groups = [...g];
    groups = groups.map((group) => (group.id === updatedGroup.id ? updatedGroup : group));

    const materialform = { ...this.state.materialform };
    materialform.groups = groups;
    this.setState({ materialform });
  }

  activateGroupAdd = () => {
    this.setState({ newGroupActive: true });
  }

  additionalProductAdd = (e) => {

    var additionalProduct = this.state.additionalProducts.find(x => x.id == e.target.value);

    // in case option 0 is selected
    if (additionalProduct === undefined) {
      return false;
    }

    if(this.state.selectedAdditionalProducts.find(x => x.id == e.target.value)){
      return false;
    }

    this.setState({
      selectedAdditionalProducts: [
        ...this.state.selectedAdditionalProducts,
        {
           id: additionalProduct.id,
           price: additionalProduct.price,
           labor_price: additionalProduct.labor_price
        }
      ]
    })
  }

  additionalProductHide = (value) => {
    var selectedproducts = this.state.selectedAdditionalProducts;
    selectedproducts = selectedproducts.filter(x => x.id !== value);

    this.setState({
      selectedAdditionalProducts: selectedproducts
    })
  }

  additionalProductEdit = (e) => {
    let parts = e.target.name.split("-");
    let field = parts[1];
    let id = parts[0];
    let value = e.target.value;

    var selectedproducts = this.state.selectedAdditionalProducts;
    let index = selectedproducts.findIndex(x => x.id == id);

    selectedproducts[index][field] = value;

    this.setState({
      selectedAdditionalProducts: selectedproducts
    })

  }

  render() {
    const disabled = this.props.materialform.is_published;

    return <Modal show={ true } onHide={ this.props.onHide } dialogClassName="custom-modal">
      <Modal.Header closeButton>
        <Modal.Title>{ I18n.t('views.materialforms.edit-title') }</Modal.Title>
      </Modal.Header>
      <Modal.Body className="edit-materialform-modal">

        { this.state.error && <Error inline error={ this.state.error } /> }
        { !this.state.error && this.state.loading && <Loading inline /> }
        { !this.state.error && !this.state.loading && <>
          <Row>
            <Col sm={ 1 }>{ I18n.t('views.materialforms.materialform-name') }</Col>
            <Col sm={ 11 }><FormControl name="name" type="text" value={ this.state.materialform.name } onChange={ this.onChangeMaterialform } /></Col>
          </Row>

          { !this.state.newGroupActive && <Row>
            <Col sm={ 1 }></Col>
            <Col sm={ 11 }>
              <Button disabled={ disabled } variant="secondary" onClick={ this.activateGroupAdd }>{ I18n.t('views.materialforms.button-add-group') }</Button>
            </Col>
          </Row> }

          { this.state.newGroupActive && <Row>
            <Col sm={ 1 }></Col>
            <Col sm={ 11 }>
              <div className="typicalBox">
                <Row>
                  <Col sm={ 1 }> { I18n.t('views.materialforms.materialform-name') } </Col>
                  <Col sm={ 11 }>
                    <FormControl disabled={ disabled } name="nameGroup" type="text" value={ this.state.nameGroup } onChange={ this.onChange } />
                  </Col>
                </Row>

                <Button disabled={ disabled } variant="secondary" onClick={ this.addNewGroup }>{ I18n.t('views.materialforms.button-add-group') }</Button>
              </div>
            </Col>
          </Row> }

          { this.state.materialform.groups.map((group, index) => <MaterialFormGroupEditor index={ index } disabled={ disabled } key={ group.id } group={ group } updateGroup={ this.updateGroup } productCategories={ this.state.productCategories } />) }

          <Row>
            <Col sm={ 2 }>{ I18n.t('views.materialforms.additional-products') }</Col>
            <Col sm={ 10 }>
            </Col>
          </Row>

          { this.state.additionalProducts && <div className='additional-products'>

            <Form.Control as="select" onChange={ this.additionalProductAdd }>
              <option key={0} value={null}>Valitse lisätuote</option>
              { this.state.additionalProducts.map((additionalProduct) => <option key={ additionalProduct.id } value={ additionalProduct.id }>{ additionalProduct.name }</option>) }
            </Form.Control>

            <Row>
              <Col sm={ 3 }>{ I18n.t('views.materialforms.materialform-name') }</Col>
              <Col sm={ 3 }>{ I18n.t('views.products.description') }</Col>
              <Col sm={ 2 }>{ I18n.t('views.products.price') }</Col>
              <Col sm={ 2 }>{ I18n.t('views.products.labor_price') }</Col>
              <Col sm={ 1 }>{ I18n.t('views.products.product-photo-label') }</Col>
            </Row>

            {this.state.selectedAdditionalProducts && this.state.selectedAdditionalProducts.map((selectedProduct) => {

              var additionalProduct = this.state.additionalProducts.find((x) => x.id == selectedProduct.id);
              var hiddenStyle = additionalProduct.hidden ? {opacity: 0.4} : null;

              return (
                <>
                  <Row style={hiddenStyle}>
                    <Col sm={ 3 }>{ additionalProduct.name }</Col>
                    <Col sm={ 3 }>{ additionalProduct.description }</Col>
                    <Col sm={ 2 }><FormControl name={ selectedProduct.id + "-price" } type="text" value={ selectedProduct.price } onChange={ this.additionalProductEdit } /></Col>
                    <Col sm={ 2 }><FormControl name={ selectedProduct.id + "-labor_price" } type="text" value={ selectedProduct.labor_price } onChange={ this.additionalProductEdit } /></Col>
                    <Col sm={ 1 }>{ additionalProduct.product_photo ? I18n.t('general.yes') : I18n.t('general.no') }</Col>
                    <Col sm={ 1 }><Button variant="secondary" onClick={ ()=>{this.additionalProductHide(selectedProduct.id)} }>X</Button></Col>
                  </Row>
                </>
              )
            }) }
          </div> }

        </> }

      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={ this.props.onHide }>{ I18n.t('views.materialforms.button-cancel') }</Button>
        <Button variant="primary" onClick={ this.save }>{ I18n.t('views.materialforms.button-save') }</Button>
      </Modal.Footer>
    </Modal>;
  }
}

class MaterialFormGroupEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: false,
      name: '',
      description: '',
      order_index: (this.props.index + 1) * 10,
      nameSelect: '',
      deleted: false,
      product_category_id: false,
      newSelectActive: false,
      selects: [],
      showDefaultOptionsOnNewSelect: false,
    };
  }

  componentDidMount = () => {
    this.setState(this.props.group);
  }

  addNewSelect = (e) => {
    if (!this.state.nameSelect.length) { return; }
    if (!this.state.product_category_id) { return; }
    const s = this.state.selects || [];
    const selects = [...s];
    selects.push({
      id: UtilService.uuidv4(),
      name: this.state.nameSelect,
      area: 0,
      product_category_id: this.state.product_category_id,
      default_option_id: false,
      options: [],
      deleted: false,
      isNew: true,
    });
    this.setState({ selects, newSelectActive: false, nameSelect: '' }, () => { this.props.updateGroup(this.state); });
  }

  onChange = (e) => { this.setState({ [e.target.name]: e.target.value }, () => { this.props.updateGroup(this.state); }); }

  updateSelect = (updatedSelect) => {
    const s = this.state.selects || [];
    let selects = [...s];
    selects = selects.map((select) => (select.id === updatedSelect.id ? updatedSelect : select));

    this.setState({ selects }, () => { this.props.updateGroup(this.state); });
  }

  onChangeCat = (e) => {
    this.setState({ product_category_id: e.target.value });
  }

  deleteThis = () => {
    this.setState({ deleted: true }, () => { this.props.updateGroup(this.state); });
  }

  activateNewSelect = () => {
    this.setState({ newSelectActive: 1 });
  }

  toggleAccordion = () => {
    this.setState((prevstate) => ({ isExpanded: !prevstate.isExpanded }));
  }

  render() {
    const disabled = this.props.disabled;

    // Adds label only to first selection element
    const nameFirstElement = () => { // DRY?
      if (this.props.index === 0) {
        return <Col sm={ 1 }>{ I18n.t('views.materialforms.materialform-group') }</Col>;
      }
      return <Col sm={ 1 }></Col>;
    };

    if (this.state.deleted) { return <></>; }
    return <div className="group">
      <Row>
      { nameFirstElement() }
        <Col sm={ 11 }>
          <Accordion className="group-editor">
            <Card>
              <Accordion.Toggle as={Card.Header} variant="link" eventKey="1" className={`clickable ${this.state.isExpanded ? 'expanded' : 'collapsed'}`} onClick={this.toggleAccordion}>
                <Row className="justify-content-between">
                  <Row className="chevron">
                    <Col className="w-auto flex-fill flex-grow-0"><AngleRight/></Col>
                    <Col className="w-auto flex-fill flex-grow-0">{ this.state.name }</Col>
                  </Row>
                  <Col sm={ 1 }>{ !disabled && <Trash onClick={ () => { this.deleteThis(); } } className="clickable" /> }</Col>
                </Row>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <Row>
                    <Col sm={ 2 }>{ I18n.t('views.materialforms.materialform-name') }</Col>
                    <Col sm={ 10 }><FormControl disabled={ disabled } name="name" type="text" value={ this.state.name } onChange={ this.onChange } /></Col>
                  </Row>

                  <Row>
                    <Col sm={ 2 }>{ I18n.t('views.materialforms.materialform-helptext') }</Col>
                    <Col sm={ 10 }><FormControl disabled={ disabled } name="description" as="textarea" value={ this.state.description } onChange={ this.onChange } /></Col>
                  </Row>

                  <Row>
                    <Col sm={ 2 }>{ I18n.t('views.materialforms.materialform-order_index') }</Col>
                    <Col sm={ 10 }><FormControl disabled={ disabled } name="order_index" type="number" value={ this.state.order_index } onChange={ this.onChange } /></Col>
                  </Row>

                  { !this.state.newSelectActive && <Row>
                    <Col sm={ 2 }></Col>
                    <Col sm={ 10 }>
                      <Button disabled={ disabled } variant="secondary" onClick={ this.activateNewSelect }>{ I18n.t('views.materialforms.button-add-selection') }</Button>
                    </Col>
                  </Row> }

                  { this.state.newSelectActive && <Row>
                    <Col sm={ 2 }></Col>
                    <Col sm={ 10 }>
                      <div className="typicalBox">
                        <Row>
                          <Col sm={ 2 }>{ I18n.t('views.materialforms.materialform-name') }</Col>
                          <Col sm={ 10 }><FormControl disabled={ disabled } name="nameSelect" type="text" value={ this.state.nameSelect } onChange={ this.onChange } /></Col>
                        </Row>

                        <Row>
                          <Col sm={ 2 }>
                            { I18n.t('views.materialforms.materialform-select-choose-productcategory') }
                          </Col>
                          <Col sm={ 10 }>
                            <Form.Control as="select" disabled={ disabled } onChange={ this.onChangeCat }>
                              <option value="" />
                              { this.props.productCategories.map((cat) => <option key={ cat.id } value={ cat.id }>{ cat.name }</option>) }
                            </Form.Control>
                          </Col>
                        </Row>

                        <Button variant="secondary" disabled={ disabled } onClick={ this.addNewSelect }>{ I18n.t('views.materialforms.button-add-selection') }</Button>
                      </div>
                    </Col>
                  </Row> }
                  { this.state.selects.map((select, index) => <MaterialFormSelectEditor disabled={ disabled } key={ select.id } index={index} select={ select } updateSelect={ this.updateSelect } productCategories={ this.props.productCategories } />) }
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Col>
      </Row>
    </div>;
  }
}

class MaterialFormSelectEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: false,
      name: '',
      nameOption: '',
      area: 0,
      product_category_id: false,
      default_option_id: false,
      options: [],
      products: [],
      product: false,
      categoryTags: [],
      tagProducts: [],
      filteredProducts: [],
      loading: false,
      error: false,
      defaultProducts: [],
      selectableProducts: [],
      isExpanded: false,
    };
  }

  componentDidMount = () => {
    const api = new AvainiaCore(LocalStorageService.getToken);
    // Get all default products from category id 1 (no choice / other choice etc)
    api.productsGet(1).then((products) => {
      if (products.error) { return this.setState({ error: products.error }); }
      this.setState({
        defaultProducts: products,
        loading: false,
      });
    });

    this.setState(this.props.select, () => {
      const options = [...this.state.options].sort((a, b) => a.order_index - b.order_index);
      this.setState({options});
      if (!this.state.default_option_id && this.state.options.length !== 0) {
        this.setState({ default_option_id: this.state.options[0].id });
      }
      if (this.props.select.product_category_id) {
        this.onSelectCatDirect(this.props.select.product_category_id);
      }
    });
  }

  onChange = (e) => { this.setState({ [e.target.name]: e.target.value }, () => { this.props.updateSelect(this.state); }); }

  onSelectCatDirect = (value) => {
    this.setState({ loading: true }, () => {
      const api = new AvainiaCore(LocalStorageService.getToken);
      api.productsGet(value).then((products) => {
        if (products.error) { return this.setState({ error: products.error }); }

        api.categoryProductTagsGet(value).then((categoryTags) => {
          if (categoryTags.error) { return this.setState({ error: categoryTags.error }); }

          this.setState({
            products,
            selectableProducts: [...products, ...this.state.defaultProducts],
            categoryTags,
            loading: false,
            product_category_id: value,
          }, () => {
            this.setState({
              filteredProducts: this.state.selectableProducts.filter((x) => {  return x.hidden === 0;  })
            })
            this.props.updateSelect(this.state);
          });
        })

      });
    });
  }

  onSelectCat = (e) => {
    const { value } = e.target;
    this.onSelectCatDirect(value);
  }

  onSelectProd = (e) => {
    const { value } = e.target;
    this.setState({
      product: value,
      loading: false,
    }, () => { this.props.updateSelect(this.state); });
  }

  onSelectTag = (e) => {
    const { value } = e.target;

    if (value) {
      // filter products based on selected tag
      this.setState({ loading: true }, () => {
        const api = new AvainiaCore(LocalStorageService.getToken);
        api.tagProductsGet(value).then((tagProducts) => {
          if (tagProducts.error) { return this.setState({ error: tagProducts.error }); }

          let productIds = []
          tagProducts.forEach((product) => {
            productIds.push(product.id);
          })

          const filteredProducts = this.state.selectableProducts.filter((x) => {
            if (productIds.includes(x.id) && x.hidden === 0) {
              return true;
            } ;
          });

          this.setState({
            filteredProducts,
          });
        });
      });
    } else {
      // show all products under the category
      this.setState({
        filteredProducts: this.state.selectableProducts.filter((x) => {  return x.hidden === 0;  })
      });
    }
  }

  getProductById = (id) => {
    // eslint-disable-next-line eqeqeq
    return this.state.selectableProducts.find((x) => x.id == id);
  }

  addNewOption = (e) => {
    const prod = this.getProductById(this.state.product);
    if (!prod) { return; }

    const o = this.state.options || [];
    const options = [...o];

    const existing = options.find((o) => o.product_id === this.state.product);
    if (existing) { return; }

    const api = new AvainiaCore(LocalStorageService.getToken);
    api.productGet(prod.id).then((product) => {
      const packageAmount = Math.ceil(this.state.area * (1 + (product.loss_percentage / 100)) / product.package_size);
      const length = options.length;
      const newId = UtilService.uuidv4();
      options.push({
        id: newId,
        name: prod.name,
        price: prod.price,
        product_id: this.state.product,
        package_amount: packageAmount, // here
        isNew: true,
        product_obj: product,
        order_index: length,
      });

      this.setState({
        options,
        default_option_id: this.state.default_option_id || newId,
        nameOption: '',
      }, () => { this.props.updateSelect(this.state); });
    });
  }

  updateOption = (updatedOption) => {
    const o = this.state.options || [];
    let options = [...o];
    options = options.map((option) => (option.id === updatedOption.id ? updatedOption : option));

    this.setState({ options }, () => { this.props.updateSelect(this.state); });
  }

  deleteThis = () => {
    this.setState({ deleted: true }, () => { this.props.updateSelect(this.state); });
  }

  changeDefaultOptionId = (target) => {
    this.setState({ default_option_id: target }, () => { this.props.updateSelect(this.state); });
  }

  // Sets a new default option if the current default option is deleted
  updateDefaultOptionId = (deletedId) => {
    const options = this.state.options;
    if (!options || options.length < 1) return;
    const firstOption = options.sort((a, b) => a.order_index - b.order_index).find(o => o.id !== deletedId && !o.deleted);
    this.setState({ default_option_id: firstOption?.id }, () => { this.props.updateSelect(this.state); });
  }

  toggleAccordion = () => {
    this.setState((prevstate) => ({ isExpanded: !prevstate.isExpanded }));
  }

  setIndex = () => {
    let options = [...this.state.options];
    options.forEach((option, i) => {
      option.order_index = i;
    })
    this.setState({options}, () => this.props.updateSelect(this.state));
  }

  render() {
    const disabled = this.props.disabled;

    if (this.state.deleted) { return <></>; }
    if (this.state.error) { return <Error error={ this.state.error } />; }
    // if (this.state.loading) { return <Loading />; } // Causes graphical bug and all the stuff is hiding in the accordion anyway

    let catName = '';
    if (this.state.product_category_id) {
      // eslint-disable-next-line eqeqeq
      const target = this.props.productCategories.find((x) => x.id == this.state.product_category_id);
      if (target) {
        catName = target.name;
      }
    }

    // Adds label only to first selection element
    const nameFirstElement = () => {
      if (this.props.index === 0) {
        return <Col style={{padding: '0.66rem 1rem'}} sm={ 2 }>{ I18n.t('views.materialforms.materialform-selection') }</Col>;
      }
      return <Col sm={ 2 }></Col>;
    };

    return <div className="select">
      <Row>
      { nameFirstElement() } <Col sm={ 10 }></Col>
        <Col sm={ 12 } style={{}}>
          <Accordion className="select-editor">
            <Card>
              <Accordion.Toggle as={Card.Header} variant="link" eventKey="0" className={`clickable ${this.state.isExpanded ? 'expanded' : 'collapsed'}`} onClick={this.toggleAccordion}>
                <Row className="justify-content-between">
                  <Row className="chevron">
                    <Col className="w-auto flex-fill flex-grow-0"><AngleRight/></Col>
                    <Col className="w-auto flex-fill flex-grow-0">{ this.state.name }</Col>
                  </Row>
                  <Col sm={ 1 }>{ !disabled && <Trash onClick={ () => { this.deleteThis(); } } className="clickable" /> }</Col>
                </Row>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <Row>
                    <Col sm={ 2 }>{ I18n.t('views.materialforms.materialform-name') }</Col>
                    <Col sm={ 10 }>
                      <FormControl disabled={ disabled } name="name" type="text" value={ this.state.name } onChange={ this.onChange } />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={ 2 }>{ I18n.t('views.products.productcategory') }</Col>
                    <Col sm={ 10 }>
                      <FormControl disabled={ disabled } type="text" readOnly value={ catName } />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={ 2 }>{ I18n.t('general.areasqm') }</Col>
                    <Col sm={ 10 }>
                      <FormControl disabled={ disabled } name="area" type="number" value={ this.state.area } onChange={ this.onChange } />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={ 12 }>
                      { this.state.options.length > 0 && <div className="typicalBox">
                        <Row className="material-options">
                          <Col sm={ 1 }>{ I18n.t('general.default') }</Col>
                          <Col sm={ 2 }>{ I18n.t('views.materialforms.default-option') }</Col>
                          <Col sm={ 3 }>{ I18n.t('views.materialforms.product') }</Col>
                          <Col sm={ 1 }>{ I18n.t('views.productReports.product-amount') }</Col>
                          <Col sm={ 2 }>{ I18n.t('views.products.labor_price') }</Col>
                          <Col sm={ 2 }>{ I18n.t('views.products.material_price') }</Col>
                        </Row>
                        <ReactSortable
                          list={this.state.options}
                          setList={(newState) => this.setState({ options: newState })}
                          handle=".handle"
                          onEnd={() => this.setIndex()}
                        >
                            { this.state.options.map((option) => <MaterialFormOptionEditor disabled={ disabled }
                            defaultOptionId={ this.state.default_option_id }
                            changeDefaultOptionId={ this.changeDefaultOptionId }
                            updateDefaultOptionId={ this.updateDefaultOptionId }
                            option={ option }
                            key={ option.id }
                            updateOption={ this.updateOption }
                            area={ this.state.area } />) }
                        </ReactSortable>
                      </div> }
                      <Row>
                        <Col sm={ 4 }>{ I18n.t('views.materialforms.tag-filter') }</Col>
                        <Col sm={ 8 }>
                          <Form.Control disabled={ disabled } as="select" onChange={ this.onSelectTag }>
                            <option key={0} value="">{ I18n.t('views.materialforms.no-filter') }</option>
                            { this.state.categoryTags.map((tag) => <option key={ tag.id } value={ tag.id }>{ tag.name }</option>) }
                          </Form.Control>
                        </Col>
                      </Row>
                      <Row className="optionBox">
                        <Col sm={ 4 }>{ I18n.t('views.products.products') }</Col>
                        <Col sm={ 5 }>
                          <Form.Control disabled={ disabled } as="select" onChange={ this.onSelectProd }>
                            <option value="" />
                            { this.state.filteredProducts.map((prod) => <option key={ prod.id } value={ prod.id }> { prod.code != 'NULL' ? prod.code + ' - ' : ''} { prod.name }</option>) }
                          </Form.Control>
                        </Col>
                        <Col sm={ 3 }>
                          <Button variant="secondary" disabled={ disabled } onClick={ this.addNewOption }>
                            { I18n.t('views.materialforms.button-add-option') }
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Col>
      </Row>
    </div>;
  }
}

class MaterialFormOptionEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: false,
      price: 0,
      name: '-',
      product_id: 0,
      isNew: true,
      package_amount: 0,
      labor_price: null,
      area: 0,
      is_contractual: false,
    };
  }

  componentDidMount() {
    let requiredPackageAmount = 0;
    if (this.props.area === 0 && this.props.option.product_obj.loss_percentage === 0) {
      requiredPackageAmount = this.props.option.product_obj.package_size;
    } else {
      requiredPackageAmount = this.props.option.product_obj && (this.props.area * (1 + (this.props.option.product_obj.loss_percentage / 100)) / this.props.option.product_obj.package_size).toFixed(2);
    }
    this.setState({ ...this.props.option, area: this.props.area, oldRequiredPackageAmount: requiredPackageAmount });
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    }, () => { this.props.updateOption(this.state); });
  }

  deleteThis = () => {
    this.setState({ deleted: true }, () => {
      if (this.state.id === this.props.defaultOptionId) { this.props.updateDefaultOptionId(this.state.id); }
      this.props.updateOption(this.state);
    });
  }

  onclick = () => {
    if (this.state.id !== this.props.defaultOptionId) {
      this.props.changeDefaultOptionId(this.state.id);
    }
  }

  onToggle = () => {
    this.setState((prevState) => ({is_contractual: !prevState.is_contractual}), () => this.props.updateOption(this.state));
  }

  getRequiredPackageAmount = () => {
    const oldRequiredPackageAmount = this.state.oldRequiredPackageAmount; // 1.58
    let newRequiredPackageAmount = 0;
    let isSingularProduct = false;

    if (this.props.area === 0 && this.state.product_obj.loss_percentage === 0) {
      newRequiredPackageAmount = this.state.product_obj.package_size;
      isSingularProduct = true;
    } else {
      newRequiredPackageAmount = this.state.product_obj && (this.props.area * (1 + (this.state.product_obj.loss_percentage / 100)) / this.state.product_obj.package_size).toFixed(2);
    }
    const originalPackageAmount = this.state.package_amount; // 2
    const newPackageAmount = Math.ceil(this.props.area * (1 + (this.state.product_obj.loss_percentage / 100)) / this.state.product_obj.package_size);

    if (!isSingularProduct) {
      if (oldRequiredPackageAmount !== newRequiredPackageAmount) {
        this.setState({
          package_amount: newPackageAmount,
          oldRequiredPackageAmount: newRequiredPackageAmount,
        })
      }
    } else if (this.state.package_amount !== newRequiredPackageAmount) {
      this.setState({
        package_amount: newRequiredPackageAmount,
      })
    }
    return newRequiredPackageAmount;
  }

  getTotalPrice = () => {
    let price = 0;
    price += Number(this.state.price * this.state.package_amount);
    price += Number(this.state.labor_price);
    return price;
  }

  render() {
    const disabled = this.props.disabled;

    if (this.state.deleted) { return <></>; }
    const isDefault = this.state.id === this.props.defaultOptionId;

    return <>
      <Form.Group as={ Row }>
        <Col sm={ 1 }>
          { isDefault && <Form.Check.Input disabled={ disabled } checked={ true } type="radio" readOnly={ true } className="inlineradio" /> }
          { !isDefault && <Form.Check.Input disabled={ disabled } checked={ false } type="radio" onChange={ this.onclick } className="inlineradio" /> }
        </Col>
        <Col sm={ 2 }>
          <Form.Check disabled={ disabled } checked={ this.state.is_contractual } name='is_contractual' type="checkbox" onChange={ this.onToggle }/>
        </Col>
        <Col sm={ 3 }><FormControl disabled={ disabled } name="name" type="text" value={ this.state.name } onChange={ this.onChange } className="material-form-selections" />
          { this.state.product_obj &&
            this.state.product_obj.loss_percentage !== null && this.state.product_obj.package_size !== null &&
            <div>
              <i>
                { I18n.t('views.materialforms.required-packages') } { this.getRequiredPackageAmount() }
              </i>
            </div>
          }
        </Col>
        <Col sm={ 1 }><FormControl disabled={ disabled } name="package_amount" type="number" value={ this.state.package_amount } onChange={ this.onChange } /></Col>
        <Col sm={ 2 }><FormControl disabled={ disabled } name="labor_price" type="text" value={ this.state.labor_price } onChange={ this.onChange } className="material-form-selections" /></Col>
        <Col sm={ 2 }><FormControl disabled={ disabled } name="price" type="text" value={ this.state.price } onChange={ this.onChange } className="material-form-selections" />
          { this.state.product_obj &&
            this.state.product_obj.loss_percentage !== null && this.state.product_obj.package_size !== null &&
            <div>
              <i>
                { I18n.t('views.materialforms.total-abbreviated') } { this.getTotalPrice() } €
              </i>
            </div>
          }
        </Col>
        <Col sm={ 1 }>
          { !disabled && <Trash onClick={ () => { this.deleteThis(); } } className="clickable" /> }
          <ArrowsVertical className="handle clickable" />
        </Col>

      </Form.Group>
    </>
  }
}

export default EditMaterialformModal;
