import React, { Component } from 'react';
import { Button, Modal, Row, Form, Col } from 'react-bootstrap';
import I18n from 'i18n-js';
import AvainiaCore from 'avainia-core-api';
import Error from '../../multiview/Error/Error.js';
import Loading from '../../multiview/Loading/Loading.js';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';
import { Upload, Trash } from '../../multiview/Icon/Icon.js';

class EditAdditionalProductModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: this.props.additionalProduct.id,
      name: this.props.additionalProduct.name,
      code: this.props.additionalProduct.code,
      description: this.props.additionalProduct.description,
      price: this.props.additionalProduct.price,
      labor_price: this.props.additionalProduct.labor_price,
      product_photo: this.props.additionalProduct.product_photo,
      product_photo_preview: this.props.additionalProduct.product_photo_preview,
      hidden: this.props.additionalProduct.hidden,
      loading: false,
      error: false,
    };
  }

  findPhotoColSize = () => {
    if (this.state.product_photo) {
      return '6';
    }
    return '10';
  }

  handleImageChange = (e) => {
    if (e.target.files[0]) {
      const { name } = e.target;
      const reader = new FileReader();
      const file = e.target.files[0];

      reader.onloadend = () => {
        this.setState((prevState) => ({
          [name]: file,
          [`${name}_preview`]: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    }
  }

  onRemoveImage = (e) => {
    const name = e.target.getAttribute('data-name');
    this[`fileinput_${name}`].value = null;

    this.setState((prevState) => ({
      [name]: null,
      product: {
        ...prevState.product,
        [name]: null,
      },
    }));
  }

  save = () => {
    const productId = this.state.id;

    const product = {
      id: this.state.id,
      name: this.state.name,
      code: this.state.code,
      description: this.state.description,
      price: this.state.price,
      labor_price: this.state.labor_price,
      product_photo: this.state.product_photo,
      hidden: this.state.hidden,
    }

    if (product.product_photo === null) {
      product.delete_product_photo = 1;
      delete product.product_photo;
    }

    // Don't send urls back
    if (typeof product.product_photo === 'string') { delete product.product_photo; }
    if (typeof product.product_context_photo === 'string') { delete product.product_context_photo; }

    this.setState({ loading: 1, error: false }, () => {
      const api = new AvainiaCore(LocalStorageService.getToken);
      api.additionalProductEdit(productId, product).then((result) => {
        if (result.error) { return this.setState({ error: result.error, loading: false }); }

        return this.setState({ loading: false, error: false }, () => {
          this.props.refresh();
          this.props.onHide();
        });
      });
    });
  }

  onChange = (e) => { this.setState({ [e.target.name]: e.target.value }); }

  render() {
    return <Modal show={true} onHide={this.props.onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{I18n.t('views.products.add-additional-product')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {this.state.error && <Error inline error={this.state.error} />}
        {!this.state.error && this.state.loading && <Loading inline />}
        {!this.state.error && !this.state.loading && <div>
          <Form.Group as={Row}>
            <Form.Label column sm="2">{I18n.t('views.products.name')}</Form.Label>
            <Col sm="10">
              <Form.Control type="text" onChange={this.onChange} name="name" value={this.state.name} />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="2">{I18n.t('views.products.code')}</Form.Label>
            <Col sm="10">
              <Form.Control type="text" onChange={this.onChange} name="code" value={this.state.code} />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="2">{I18n.t('views.products.description')}</Form.Label>
            <Col sm="10">
              <Form.Control type="text" onChange={this.onChange} name="description" value={this.state.description} />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="2">{I18n.t('views.products.price')}</Form.Label>
            <Col sm="10">
              <Form.Control type="number" onChange={this.onChange} name="price" value={this.state.price} />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="2">{I18n.t('views.products.labor_price')}</Form.Label>
            <Col sm="10">
              <Form.Control type="number" onChange={this.onChange} name="labor_price" value={this.state.labor_price} />
            </Col>
          </Form.Group>
          {this.state.product_photo && <Col sm="4"><img alt="product preview" className="product-preview-image" src={this.state.product_photo_preview ? this.state.product_photo_preview : this.state.product_photo }/></Col>}
            <Col sm={this.findPhotoColSize()}>
              {this.state.product_photo && <Button variant="danger" className="remove-photo-button" onClick={this.onRemoveImage} data-name="product_photo"><Trash />{I18n.t('general.remove-photo')}</Button> }
              <Form.Control type="file" id="modal_product_photo" className="inputfile" onChange={this.handleImageChange} name="product_photo" ref={(ref) => this.fileinput_product_photo = ref}/>
              <label htmlFor="modal_product_photo"><Upload /> {I18n.t('general.choose-photo')}</label>
            </Col>
        </div>}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" disabled={this.state.loading} onClick={this.save}>{I18n.t('general.modal-form-save')}</Button>
        <Button variant="secondary" onClick={this.props.onHide}>{I18n.t('general.modal-close')}</Button>
      </Modal.Footer>
    </Modal>;
  }
}

export default EditAdditionalProductModal;
