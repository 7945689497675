import React, { Component } from 'react';
import { Button, ListGroup } from 'react-bootstrap';
import I18n from 'i18n-js';
import AvainiaCore from 'avainia-core-api';
import Error from '../../../multiview/Error/Error.js';
import Loading from '../../../multiview/Loading/Loading.js';
import AvainiaPermissions from '../../../../AvainiaTools/AvainiaPermissions.js';
import LocalStorageService from '../../../../AvainiaTools/LocalStorageService.js';
import { Trash } from '../../../multiview/Icon/Icon.js';
import ModalNotificationRecipientAdd from '../../../multiview/Modals/ModalProjectNotificationRecipientAdd.js';

class NotificationsTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      notifications: [],
      modal: false,
    };
  }

  componentDidMount() {
    this.getNotifications();
  }

  getNotifications() {
    const { id } = this.props.project;
    const api = new AvainiaCore(LocalStorageService.getToken);

    const promises = [api.projectNotificationsGet(id, 'notifications')];

    Promise.all(promises).then((data) => {
      let error = false;
      let notifications;

      data.forEach((x) => {
        if (error) {
          return;
        }
        if (x.error) {
          error = x.error;
          return;
        }
        if (x.notifications) {
          notifications = x.notifications;
        }
      });

      if (error) {
        return this.setState({ error });
      }

      this.setState({ notifications, loading: false });
    });
  }

  delete = (e) => {
    if (this.state.loading) {
      return;
    }

    if (!window.confirm(I18n.t('views.projectNotifications.confirm-notification-delete'))) {
      return;
    }

    this.setState({ loading: true, error: false }, () => {
      const api = new AvainiaCore(LocalStorageService.getToken);
      api.projectNotificationsDelete(this.props.project.id, e.id).then((notification) => {
        if (notification.error) {
          this.setState({ error: notification.error, loading: false });
        }

        const notifications = this.state.notifications.filter((x) => x.id !== notification.id);
        this.setState({ notifications, loading: false });
      });
    });
  };

  addNotification = () => {
    this.setState({ modal: true });
  };

  hideModal = (e) => {
    this.setState({ modal: false });
  };

  refreshView = () => {
    this.getNotifications();
  };

  render() {
    const user = LocalStorageService.getUser();
    const canManage = user.hasPermission(AvainiaPermissions.ProjectsManage);

    return (
      <div className="App-container">
        <p>{I18n.t('views.projectNotifications.description')}</p>
        {this.state.error && <Error error={this.state.error} inline />}
        {!this.state.error && this.state.loading && <Loading inline />}
        {!this.state.loading && canManage && (
          <>
            <ListGroup className="listing">
              {this.state.notifications.map((notification) => (
                <ListGroup.Item key={notification.id}>
                  <Trash
                    onClick={() => {
                      this.delete(notification);
                    }}
                  />
                  <span>{notification.name} </span>
                </ListGroup.Item>
              ))}
            </ListGroup>
            <Button variant="primary" onClick={this.addNotification}>
              {I18n.t('views.projectNotifications.create-notification')}
            </Button>
          </>
        )}

        <ModalNotificationRecipientAdd
          show={this.state.modal}
          onHide={this.hideModal}
          onRefresh={this.refreshView}
          project={this.props.project}
          notifications={this.state.notifications}
        />
      </div>
    );
  }
}

export default NotificationsTab;
