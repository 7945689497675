import React, { Component } from "react";
import I18n from "i18n-js";
import { Button, Modal } from "react-bootstrap";
import AvainiaCore from "avainia-core-api";
import Error from "../Error/Error.js";
import Loading from "../Loading/Loading.js";
import LocalStorageService from "../../../AvainiaTools/LocalStorageService.js";
import CustomSelect from "../Select/CustomSelect.js";
import "./ModalProjectNotificationRecipientAdd.scss";

class ModalProjectNotificationRecipientAdd extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedUserId: null,
      users: [],
    };
  }

  componentDidMount() {
    const api = new AvainiaCore(LocalStorageService.getToken);
    api.usersGet().then((users) => {
      const vrjEmployeeUsers = users.filter((u) => u.roles.some((r) => r.name === "vrj_employee"));
      if (users.error) {
        return this.setState({ error: users.error });
      }
      return this.setState({
        users: vrjEmployeeUsers,
      });
    });
  }

  handleUserSelectChange = (selectName, selectedValue) => {
    const user = this.state.users.find((x) => x.id === Number(selectedValue.value));
    this.setState({ selectedUserId: user.id });
  };

  addRecipient = (e) => {
    this.setState({ loading: true, error: false }, () => {
      const payload = {
        user_id: this.state.selectedUserId,
      };

      const api = new AvainiaCore(LocalStorageService.getToken);
      api.projectNotificationsCreate(this.props.project.id, payload).then((notification) => {
        if (notification.error) {
          return this.setState({ error: notification.error, loading: false });
        }
        this.setState({
          selectedUserId: null,
          loading: false,
        });
        this.props.onHide();
        this.props.onRefresh();
      });
    });
  };

  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.onHide}>
        <Modal.Header closeButton>
          <Modal.Title>{I18n.t("views.projectNotifications.create-notification")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="project-notification-add-recipient-modal">
          {this.state.error && <Error error={this.state.error} inline />}
          {!this.state.error && this.state.loading && <Loading inline />}
          {!this.state.error && !this.state.loading && (
            <>
              <CustomSelect
                name="user"
                placeholder={I18n.t("views.projectNotifications.choose-user")}
                handleChange={this.handleUserSelectChange}
                selectOptions={this.state.users
                  .filter((u) => !this.props.notifications.find((n) => n.user_id === u.id))
                  .map((u) => {
                    return { label: u.name, value: `${u.id}` };
                  })}
              />
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.props.onHide}>
            {I18n.t("views.projectNotifications.button-cancel")}
          </Button>
          <Button variant="primary" onClick={this.addRecipient}>
            {I18n.t("views.projectNotifications.button-create")}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ModalProjectNotificationRecipientAdd;
