import React, { Component } from 'react';
import { Button, Modal, Form, Badge, Row, Col } from 'react-bootstrap';
import I18n from 'i18n-js';
import AvainiaCore from 'avainia-core-api';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';
import './RoomCardModal.scss';


class RoomCardModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      condominium: null,
      apartment: null,
      quote: null,
      submission: null,
      quoteGroups: null,
      roomCardSelections: null,
      apartmentUsers: null,
      allPhotos: [],
    };
  }


  componentDidMount = () => {
    const api = new AvainiaCore(LocalStorageService.getToken);

    const promises = [
      api.condominiumGet(this.props.condominiumId, 'condominium'),
      api.materialFormSubmissionGet(this.props.apartment.id, this.props.materialFormId, 'submissionForm'),
      api.quoteForRoomCardGet(this.props.roomCardQuote.id, this.props.materialFormId, 'quoteGroups'),
      api.roomCardSelectionsGet(this.props.roomCardQuote.id, 'roomCardSelections'),
      api.getApartmentUsers(this.props.apartment.condominium_id, this.props.apartment.id, 'apartmentUsers'),
      api.materialQuotePhotosGet(this.props.roomCardQuote.project_id, this.props.roomCardQuote.id, 'materialQuotePhotos')
    ];

    Promise.all(promises).then((data) => {
      let error = false;
      let condominium;
      let submissionForm;
      let quoteGroups;
      let roomCardSelections;
      let apartmentUsers;
      let materialQuotePhotos;

      data.forEach((x) => {
        if (error) { return; }
        if (x.error) { error = x.error; return; }
        if (x.condominium) { condominium = x.condominium; }
        if (x.submissionForm) { submissionForm = x.submissionForm; }
        if (x.quoteGroups) { quoteGroups = x.quoteGroups; }
        if (x.roomCardSelections) { roomCardSelections = x.roomCardSelections; }
        if (x.apartmentUsers) { apartmentUsers = x.apartmentUsers; }
        if (x.materialQuotePhotos) { materialQuotePhotos = x.materialQuotePhotos; }
      });

      if (error) { return this.setState({ error }); }

      this.setState({
        condominium,
        submission: submissionForm,
        quoteGroups,
        apartment: this.props.apartment,
        loading: false,
        quote: this.props.roomCardQuote,
        roomCardSelections,
        apartmentUsers,
        materialQuotePhotos
      }, () => {
        materialQuotePhotos && this.getAllPhotos(this.props.roomCardQuote.project_id, this.props.roomCardQuote.id, materialQuotePhotos);
      });
    });
  }

  getAllPhotos = (projectId, quoteId, materialQuotePhotos) => {
    const api = new AvainiaCore(LocalStorageService.getToken);
  
    materialQuotePhotos.forEach(photo => {
      api.materialQuotePhotoGet(projectId, quoteId, photo.id).then((image) => {
        if (image.error) { return this.setState({ error: image.error }); }
  
        this.setState((prevState) => ({
          allPhotos: {...prevState.allPhotos, [photo.id]: {id: photo.id, image: image} },
        }));
  
      });
    })
  }

  formatDate = (date, format) => {
    const map = {
        mm: date.getMonth() + 1,
        dd: date.getDate(),
        yy: date.getFullYear().toString().slice(-2),
        yyyy: date.getFullYear()
    }

    return format.replace(/mm|dd|yyyy/gi, matched => map[matched])
  }

  downloadAttachment = async () => {
    try {
      const obj = { headers: { Authorization: `Bearer ${LocalStorageService.getToken()}`} };
      const res = await fetch(process.env.REACT_APP_API_HOST + "/api/v1/generate-room-card/" + this.state.condominium.id + '/' + this.props.roomCardQuote.id, obj);
      const fileName = "Huonekortti_" + this.state.apartment.stairwell + '_' + this.state.apartment.apartment_number + ".docx";

      if (res.ok) {
        const binary = await res.blob();

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(binary, fileName);
        } else {
          const src = window.URL.createObjectURL(binary);
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.style.cssText = 'display: none';
          a.href = src;
          a.download = fileName;
          a.click();
          window.URL.revokeObjectURL(src);
          setTimeout((x) => { document.body.removeChild(a); }, 1000);
        }
      } else if (res.status !== 410) { // 410 means dont retry
        throw new Error('failed to fetch document'); // TODO! Fatal error, do actual logging
      }
    } catch (ex) {
      console.error(ex); // TODO! Fatal error, do actual logging
    }
  }

  render() {
    const { condominium, loading, submission, quote, quoteGroups, roomCardSelections } = this.state;
    const today = this.formatDate(new Date(), 'dd.mm.yyyy');
    const apartment = this.state.apartment && this.state.apartment.stairwell + ' ' + this.state.apartment.apartment_number;
    const allPhotos = Object.values(this.state.allPhotos);
    const lines = (this.state.quote && this.state.quote.other_tasks) ? this.state.quote.other_tasks.split("\n") : [];

    return <>
      {!loading &&
        <Modal show={true} onHide={this.props.onHide} size="xl">
          <Modal.Header closeButton>
            <Modal.Title>{ condominium.name } {I18n.t('views.roomCards.room-card')} { today } { apartment } </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="roomCard">
              <Row>
                <Col xs={6}>
                  <Form.Check type="checkbox" label={I18n.t('views.roomCards.info-residency')} checked={submission.residents_stay_during_repairs} disabled />
                </Col>
                <Col xs={6}>
                  {I18n.t('views.roomCards.apartment-number')} { apartment } 
                </Col>
              </Row>
              <Row>
                {/* TODO: Hardcoding is evil */}
                KYSYTTÄVÄÄ: VRJ / Monica Wenell / 040 720 0109 / monica.wenell@vrj.fi
              </Row>
              <Row>
                <Col xs={6}>
                  <Row>
                    {I18n.t('views.roomCards.owner-information')}
                  </Row>
                  <Row>
                    {I18n.t('views.roomCards.name')} { this.state.apartmentUsers.Owners[0] ? this.state.apartmentUsers.Owners[0].name : '-'}
                  </Row>
                  <Row>
                    {I18n.t('views.roomCards.phone')} { this.state.apartmentUsers.Owners[0] ? this.state.apartmentUsers.Owners[0].phone : '-'} 
                    {/* TODO: users don't have phone numbers so using email instead */}
                  </Row>
                </Col>
                <Col xs={6}>
                  <Row>
                    {I18n.t('views.roomCards.tenant-information')}
                  </Row>
                  <Row>
                    {I18n.t('views.roomCards.name')} { this.state.apartmentUsers.Tenants[0] ? this.state.apartmentUsers.Tenants[0].name : '-'}
                  </Row>
                  <Row>
                    {I18n.t('views.roomCards.phone')} { this.state.apartmentUsers.Tenants[0] ? this.state.apartmentUsers.Tenants[0].phone : '-'} 
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col xs={6}>
                  {I18n.t('views.roomCards.info-keep-store')}
                </Col>
                <Col xs={6}>
                  { submission.info_keep_store }
                </Col>
              </Row>
              <Row>
                <Col xs={6}>
                {I18n.t('views.roomCards.info-keep-reinstall')}
                </Col>
                <Col xs={6}>
                { submission.info_keep_reinstall }
                </Col>
              </Row>
              <Row>
                <Col xs={6}>
                  {I18n.t('views.roomCards.dismantling')} 
                </Col>
                <Col xs={6}>
                  {I18n.t('views.roomCards.contractual')} 
                </Col>
              </Row>
              <Row>
                <Col xs={6}>
                  {I18n.t('views.roomCards.protections')} 
                </Col>
                <Col xs={6}>
                  {I18n.t('views.roomCards.contractual')} 
                </Col>
              </Row>
              <Row className="group">
                <Col xs={12}>
                  Lisärivit
                </Col>
              </Row>
                {quote.additionalRows.map(row => {
                  return (
                    <Row>
                      <Col xs={3}>
                        {row.title}
                      </Col>
                      <Col xs={9}>
                        {row.description}
                      </Col>
                    </Row>
                  )
                })}
              <Row>
                {
                  lines.map(line => {
                    return (
                        <Col xs={12}>
                          {line}
                        </Col>
                    )
                  })
                }
              </Row>
              {
                quoteGroups.map((group) => {
                  return ( <>
                      <Row className="group">
                        <Col xs={3}>
                        { group.name }
                        </Col>
                        <Col xs={3}>
                          {I18n.t('views.roomCards.model')}
                        </Col>
                        <Col xs={3}>
                          {I18n.t('views.roomCards.size')}
                        </Col>
                          {I18n.t('views.roomCards.installer')}
                      </Row>
                      <Row>
                        { group.material_form_selects.map((select) => {
                          const selection =  roomCardSelections.find((product) => product.material_form_select_id === select.id) 
                          if (selection) {
                            return ( <>
                              <Col xs={3}>
                                { select.name }
                              </Col>
                              <Col xs={3}>
                                { selection.name }{' '}-{' '}{ selection.product.code }
                              </Col>
                              <Col xs={3}>
                                { selection.product.measurements || null }
                              </Col>
                              <Col xs={3}>
                                NN
                              </Col>
                            </>
                            )
                          }
                        }) }
                      </Row>
                    </>
                  )
                })
              }
              {
                submission.selected_additional_products && submission.selected_additional_products.length > 0 &&
                <Row className="group">
                  <Col xs={3}>
                    Lisätuotteet
                  </Col>
                  <Col xs={3}>
                    {I18n.t('views.roomCards.model')}
                  </Col>
                  <Col xs={3}>
                    {I18n.t('views.roomCards.description')}
                  </Col>
                    {I18n.t('views.roomCards.installer')}
                </Row> }
                { submission.selected_additional_products && submission.selected_additional_products.length > 0 &&
                  submission.selected_additional_products.map((additionalProduct) => {
                    return (
                      <Row>
                        <Col xs={3}>
                          {additionalProduct.name}
                        </Col>
                        <Col xs={3}>
                          {additionalProduct.code}
                        </Col>
                        <Col xs={3}>
                          {additionalProduct.description}
                        </Col>
                          NN
                      </Row>
                    )
                  })

              }
              <Row>
                { allPhotos && allPhotos.length > 0 && 
                  allPhotos.map((photo) => {
                    return (
                      <Col xs={12}>
                        <img style={{maxWidth: '100%'}} src={photo.image} alt=""/>
                      </Col>
                    )
                  })
                }
              </Row>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.downloadAttachment}>{I18n.t('views.materialforms.generate-room-card')}</Button>
            <Button variant="secondary" onClick={this.props.onHide}>{I18n.t('views.materialforms.button-cancel')}</Button>
          </Modal.Footer>
        </Modal>
      }
    </>
  }
}

export default RoomCardModal;
