import React, { Component } from 'react';
import { Badge, Button, Modal, Row, Form, Col, InputGroup } from 'react-bootstrap';
import I18n from 'i18n-js';
import AvainiaCore from 'avainia-core-api';
import { Upload, Trash } from '../../multiview/Icon/Icon.js';
import Error from '../../multiview/Error/Error.js';
import Loading from '../../multiview/Loading/Loading.js';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';

class ProductEditModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      product: this.props.product,
      product_photo: this.props.product.product_photo,
      product_context_photo: this.props.product.product_context_photo,
      loading: false,
      error: false,
    };

    this.onChange = this.onChange.bind(this);
  }

  save = () => {
    const productId = this.props.product.id;
    const product = { ...this.state.product };

    const tagsArr = [];
    this.state.product.tags.map((tag) => {
      return tagsArr.push(tag.id);
    });

    product.tags = tagsArr;

    // If null, signal core to delete images
    if (product.product_context_photo === null) {
      product.delete_product_context_photo = 1;
      delete product.product_context_photo;
    }

    if (product.product_photo === null) {
      product.delete_product_photo = 1;
      delete product.product_photo;
    }

    // Don't send urls back
    if (typeof product.product_photo === 'string') { delete product.product_photo; }
    if (typeof product.product_context_photo === 'string') { delete product.product_context_photo; }

    this.setState({ loading: 1, error: false }, () => {
      const api = new AvainiaCore(LocalStorageService.getToken);
      api.productsEdit(productId, product).then((result) => {
        if (result.error) { return this.setState({ error: result.error, loading: false }); }

        return this.setState({ loading: false, error: false }, () => {
          this.props.editProductCallback(product);
          this.props.onHide();
        });
      });
    });
  }

  onChange = (e) => {
    const { name } = e.target;
    const { value } = e.target;

    this.setState((prevState) => ({
      product: {
        ...prevState.product,
        [name]: value,
      },
    }));
  }

  onToggleHide = (e) => {
    this.setState((prevState) => ({
      product: {
        ...prevState.product,
        hidden_on_room_card: !prevState.product.hidden_on_room_card,
      },
    }));
  }

  onRemoveImage = (e) => {
    const name = e.target.getAttribute('data-name');
    this[`fileinput_${name}`].value = null;

    this.setState((prevState) => ({
      [name]: null,
      product: {
        ...prevState.product,
        [name]: null,
      },
    }));
  }

  handleImageChange = (e) => {
    const { name } = e.target;
    const reader = new FileReader();
    const file = e.target.files[0];

    reader.onloadend = () => {
      this.setState((prevState) => ({
        [name]: reader.result,
        product: {
          ...prevState.product,
          [name]: file,
        },
      }));
    };

    reader.readAsDataURL(file);
  }

  findPhotoColSize = () => {
    if (this.state.product_photo) {
      return '6';
    }

    return '10';
  }

  productTagAdd = (e) => {
    // eslint-disable-next-line eqeqeq
    const productTag = this.props.productTags.find((x) => x.id == e.target.value);

    // in case option 0 is selected
    if (productTag === undefined) {
      return false;
    }
    // eslint-disable-next-line eqeqeq
    if (this.state.product.tags.find((x) => x.id == e.target.value)) {
      return false;
    }
    this.setState((prevState) => ({
      product: {
        ...prevState.product,
        tags: [
          ...prevState.product.tags,
          {
            id: productTag.id,
            name: productTag.name,
          },
        ],
      },
    }));
  }

  productTagHide = (value) => {
    let selectedProductTags = this.state.product.tags;
    selectedProductTags = selectedProductTags.filter((x) => x.id !== value);

    this.setState((prevState) => ({
      product: {
        ...prevState.product,
        tags: selectedProductTags,
      },
    }));
  }

  render() {
    return <Modal show={true} onHide={this.props.onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{I18n.t('views.products.edit-product')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {this.state.error && <Error inline error={this.state.error} />}
        {!this.state.error && this.state.loading && <Loading inline />}
        {!this.state.error && !this.state.loading && <div>
          <Form.Group as={Row} id="edit-product-form">
            <Form.Label column sm="2">{I18n.t('views.products.productname-label')}</Form.Label>
            <Col sm="10"><Form.Control type="text" onChange={this.onChange} name="name" value={this.state.product.name || ''} isInvalid={!this.state.product.name} /></Col>
            <Form.Label column sm="2">{I18n.t('views.products.productmanufacturer-label')}</Form.Label>
            <Col sm="10"><Form.Control type="text" onChange={this.onChange} name="manufacturer" value={this.state.product.manufacturer || ''} isInvalid={!this.state.product.manufacturer} /></Col>
            <Form.Label column sm="2">{I18n.t('views.products.productcode-label')}</Form.Label>
            <Col sm="10"><Form.Control type="text" onChange={this.onChange} name="code" value={this.state.product.code || ''} isInvalid={!this.state.product.code} /></Col>
            <Form.Label column sm="2">{I18n.t('views.products.productdescription-label')}</Form.Label>
            <Col sm="10"><Form.Control type="text" onChange={this.onChange} name="description" value={this.state.product.description || ''} /></Col>
            <Form.Label column sm="2">{I18n.t('views.products.productprice-label')}</Form.Label>
            <Col sm="10">
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroupPrepend">€</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control type="text" onChange={this.onChange} name="price" value={this.state.product.price || ''} isInvalid={isNaN(this.state.product.price)} />
              </InputGroup>
            </Col>
            <Form.Label column sm="2">{I18n.t('views.products.productmeasurements-label')}</Form.Label>
            <Col sm="10"><Form.Control type="text" onChange={this.onChange} name="measurements" value={this.state.product.measurements || ''} /></Col>
            <Form.Label column sm="2">{I18n.t('views.products.productexternallink-label')}</Form.Label>
            <Col sm="10"><Form.Control type="text" onChange={this.onChange} name="external_link" value={this.state.product.external_link || ''} /></Col>
            <Form.Label column sm="2">{I18n.t('views.products.loss-percentage')}</Form.Label>
            <Col sm="10"><Form.Control type="text" name="loss_percentage" value={this.state.product.loss_percentage} onChange={this.onChange} /></Col>
            <Form.Label column sm="2">{I18n.t('views.products.package_size')}</Form.Label>
            <Col sm="10"><Form.Control type="text" name="package_size" value={this.state.product.package_size} onChange={this.onChange} /></Col>
            <Form.Label column sm="2">{I18n.t('views.products.producttags-label')}</Form.Label>
            <Col sm="10"><Form.Control as="select" onChange={ this.productTagAdd }>
              <option key={0} value={null}>{I18n.t('views.products.select-tag')}</option>
              { this.props.productTags.map((tag) => <option key={ tag.id } value={ tag.id }>{ tag.name }</option>) }
            </Form.Control></Col>
            <Col sm={ 2 }/>
            <Col sm={ 10 }>
              <Row style={ { marginLeft: '0', marginBottom: '1rem' } }>
              {this.state.product.tags && this.state.product.tags.map((selectedProductTag) => {
                return (<>
                    <Badge pill variant="secondary" style={ { lineHeight: '0', padding: '0.4rem', margin: '0.1rem 0.25rem 0.1rem 0.25rem' } }>
                        { selectedProductTag.name }
                        <Button variant="secondary" style={ { padding: '0.4rem', margin: '0 0 0 0.2rem', borderRadius: '50%', lineHeight: '0.75' } } onClick={ () => { this.productTagHide(selectedProductTag.id); } }>X</Button>
                    </Badge>
                  </>);
              })
              }
              </Row>
            </Col>
            <Form.Label column sm="2">{I18n.t('views.products.hidden-on-room-card')}</Form.Label>
            <Col sm="10" className="d-flex align-items-center">
              <Form.Check column sm="10" type="checkbox" id="hidden-on-room-card" checked={this.state.product.hidden_on_room_card} onChange={this.onToggleHide} />
            </Col>
            <Form.Label column sm="2">{I18n.t('views.products.product-photo-label')}</Form.Label>
            {this.state.product_photo && <Col sm="4"><img alt="product preview" className="product-preview-image" src={this.state.product_photo}/></Col>}
            <Col sm={this.findPhotoColSize()}>
              {this.state.product_photo && <Button variant="danger" className="remove-photo-button" onClick={this.onRemoveImage} data-name="product_photo"><Trash />{I18n.t('general.remove-photo')}</Button> }
              <Form.Control type="file" id="modal_product_photo" className="inputfile" onChange={this.handleImageChange} name="product_photo" ref={(ref) => this.fileinput_product_photo = ref}/>
              <label htmlFor="modal_product_photo"><Upload /> {I18n.t('general.choose-photo')}</label>
            </Col>
            <Form.Label column sm="2">{I18n.t('views.products.product-context-photo-label')}</Form.Label>
            {this.state.product_context_photo && <Col sm="4"><img alt="product context preview" className="product-preview-image" src={this.state.product_context_photo}/></Col>}
            <Col sm="6">
              {this.state.product_context_photo && <Button variant="danger" className="remove-photo-button" onClick={this.onRemoveImage} data-name="product_context_photo"><Trash />{I18n.t('general.remove-photo')}</Button> }
              <Form.Control type="file" id="modal_product_context_photo" className="inputfile" onChange={this.handleImageChange} name="product_context_photo" ref={(ref) => this.fileinput_product_context_photo = ref}/>
              <label htmlFor="modal_product_context_photo"><Upload /> {I18n.t('general.choose-photo')}</label>
            </Col>
          </Form.Group>
        </div>}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" disabled={this.state.loading} onClick={this.save}>{I18n.t('general.modal-form-save')}</Button>
        <Button variant="secondary" onClick={this.props.onHide}>{I18n.t('general.modal-close')}</Button>
      </Modal.Footer>
    </Modal>;
  }
}

export default ProductEditModal;
