import React, { Component } from 'react';
import I18n from 'i18n-js';
import { Button, Badge, Row, Form, Col, DropdownButton } from 'react-bootstrap';
import AvainiaCore from 'avainia-core-api';
import { File, Upload, Trash, ArrowDownTray, ArrowUpTray, EllipsisVertical, PlusCircle } from '../Icon/Icon.js';
import Error from '../Error/Error.js';
import Loading from '../Loading/Loading.js';
import ProductReportModal from '../Modals/ProductReportModal.js';
import RoomCardModal from '../Modals/RoomCardModal.js';
import ApartmentMaterialselectionQuoteModal from '../Modals/ApartmentMaterialselectionQuoteModal.js';
import AvainiaPanel from './AvainiaPanel.js';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';
import ProjectProductReportModal from '../Modals/ProjectProductReportModal.js';
import MaterialformDeadlineModal from '../Modals/MaterialformDeadlineModal.js';
import QuoteDeadlineModal from '../Modals/QuoteDeadlineModal.js';
import RoomCardDeadlineModal from '../Modals/RoomCardDeadlineModal.js';
import Success from '../Success/Success.js';
import moment from 'moment';
import './MaterialformQuotesPanel.scss';

const Modals = {
  quote: 1,
  productReport: 2,
  roomCard: 3,
  projectProductReport: 4,
  materialformDeadline: 5,
  quoteDeadline: 6,
  roomCardDeadline: 7,
};

class MaterialformQuotesPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      materialQuotes: [],
      materialFormDeadlines: [],
      materialformDeadline: false,
      quote: null,
      modal: false,
      reportData: false,
      targetApartment: false,
      targetQuote: false,
      modalKeyHack: 1,
      loading: false,
      error: false,
      success: false,
      show: false,
    };
  }

  componentDidMount() {
    const { id } = this.props.project;

    const api = new AvainiaCore(LocalStorageService.getToken);
    api.projectMaterialquotesGet(id).then((materialQuotes) => {
      if (materialQuotes.error) { return this.setState({ error: materialQuotes.error }); }
      api.materialFormDeadlineGet().then((materialFormDeadlines) => {
        this.setState({
          materialQuotes,
          materialFormDeadlines,
          loading: false,
        });
      });
    });
  }

  hideModal = (e) => { this.setState({ modal: false }); }

  onChange = (e) => { this.setState({ [e.target.name]: e.target.value }); }

  downloadQuotePDF = async (id, apt) => {
    try {
      const obj = { headers: { Authorization: `Bearer ${LocalStorageService.getToken()}` }};
      const apiRoute = `materialquotes/${id}/generate`;
      const res = await fetch(`${process.env.REACT_APP_API_HOST}/api/v1/${apiRoute}`, obj);
      const name = `Tarjous_${apt.stairwell}_${apt.apartment_number}`;

      if (res.ok) {
        const binary = await res.blob();
        const fileName = name + '.pdf';
        const src = window.URL.createObjectURL(binary);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.style.cssText = 'display: none';
        a.href = src;
        a.download = fileName;
        window.open(src, '_blank');
        setTimeout((x) => { document.body.removeChild(a); }, 1000);
      } else if (res.status !== 410) { // 410 means dont retry
        throw new Error('failed to fetch document'); // TODO! Fatal error, do actual logging
      }
    } catch (ex) {
      console.error(ex); // TODO! Fatal error, do actual logging
    }
  }

  printQuoteStatus = (apt, form, submission) => {
    const quote = this.apartmentHasMaterialQuote(apt, form, submission);
    if (quote) {
      if (quote.status === 'approved') {
        return <div style={{display: 'flex', height: '100%'}}>
          <Button variant='light' className='btn-light' onClick={() => { this.openQuoteForApartment(apt, form); }}>
            {this.printStatusColor('green', I18n.t('views.materialforms.statuses.quote-approved'), true)}
          </Button>
          <Button className='btn-light' variant="light" size='sm' onClick={() => { this.downloadQuotePDF(quote.id, apt); }}>
              <ArrowDownTray/>
          </Button>
          </div>;
      }
      if (quote.status === 'sent') {
        return <Button variant='light' className='btn-light' onClick={() => { this.openQuoteForApartment(apt, form); }}>
            {this.printStatusColor('red', I18n.t('views.materialforms.statuses.pending-owner'))}
          </Button>;
      }
      return <Button variant='light' className='btn-light' onClick={() => { this.openQuoteForApartment(apt, form); }}>
          {this.printStatusColor('orange', I18n.t('views.materialforms.statuses.quote-edit'))}
        </Button>;
    }
    return <Button variant='light' className='btn-light' onClick={() => { this.openQuoteForApartment(apt, form); }}>
        <PlusCircle/> {I18n.t('views.materialforms.create-new')}
      </Button>;
  }

  printRoomCardStatus = (quote, form, submission, apt) => {

    if (!quote.custom_room_card ) {
      return <Button variant='light' className='btn-light' onClick={() => this.setState({
        roomCardSubmissionFormId: submission.id,
        roomCardMaterialFormId: form.id,
        roomCardApartment: apt,
        modal: Modals.roomCard,
        roomCardQuote: quote,
        roomCardQuoteId: quote.id,
      }, () => { this.downloadAttachment(quote.custom_room_card); })}>
          <PlusCircle/> {I18n.t('views.materialforms.create-new')}
        </Button>;
    }

    if (quote.custom_room_card && !quote.room_card_approved ) {
      return <div style={{padding: '0.5rem'}}>{ this.printStatusColor('red', I18n.t('views.materialforms.statuses.pending-owner'))}</div>

    }
    return <div style={{padding: '0.5rem'}}>{this.printStatusColor('green', I18n.t('views.materialforms.statuses.approved'))}</div>
  }

  printStatusColor = (color, contentText, noMargin = false) => {
    let styles = {};
    if(noMargin){
      styles.margin = 0;
    }

    return (
      <span className='status-badge' style={styles}>
        <div style={{backgroundColor: color}}/>
        <p>{contentText}</p>
      </span>
    )
  }

  showProductReport = (stairwell) => {
    const api = new AvainiaCore(LocalStorageService.getToken);
    api.reportForProducts(this.props.project.id, stairwell).then((reportData) => {
      if (reportData.error) { return this.setState({ error: reportData.error }); }
      this.setState({ reportData, modal: Modals.productReport });
    });
  }

  openQuoteForApartment = (targetApartment, targetForm) => {
    const api = new AvainiaCore(LocalStorageService.getToken);
    api.materialFormSubmissionGet(targetApartment.id, targetForm.id).then((submissionForm) => {
      if (submissionForm.error) { return this.setState({ error: submissionForm.error }); }
      const quote = this.apartmentHasMaterialQuote(targetApartment, targetForm, submissionForm);
      this.setState({ targetApartment, targetQuote: quote, modal: Modals.quote, targetMaterialForm: targetForm, targetMaterialFormSubmission: submissionForm });
    });
  }

  apartmentHasMaterialQuote = (apt, form, submission) => {
    return this.state.materialQuotes.find((quote) => quote.apartment_id === apt.id && quote.material_form_submission_id === submission.id);
  }

  updateSubmissionFormCallback = async () => {
    const api = new AvainiaCore(LocalStorageService.getToken);
    const { targetApartment, targetMaterialForm } = this.state;

    api.materialFormSubmissionGet(targetApartment.id, targetMaterialForm.id).then((submissionForm) => {
      if (submissionForm.error) { return this.setState({ error: submissionForm.error }); }
      this.setState({ targetMaterialFormSubmission: submissionForm });
    });
  }

  refreshQuoteCallback = async () => {
    const { id } = this.props.project;

    const api = new AvainiaCore(LocalStorageService.getToken);
    const { targetApartment, targetMaterialFormSubmission } = this.state;
    const materialQuotes = await api.projectMaterialquotesGet(id)
    if (materialQuotes.error) { return this.setState({ error: materialQuotes.error }); }
    const quote = materialQuotes.find((q) => q.apartment_id === targetApartment.id && q.material_form_submission_id === targetMaterialFormSubmission.id);

    this.setState({
      targetQuote: quote,
      loading: false,
      materialQuotes,
    });
    return quote;
  }

  showProjectProductReport = () => {
    const api = new AvainiaCore(LocalStorageService.getToken);
    api.reportForProject(this.props.project.id).then((reportData) => {
      if (reportData.error) { return this.setState({ error: reportData.error }); }
      this.setState({ report: reportData, modal: Modals.projectProductReport });
    });
  }

  downloadAttachment = async (roomCard, generate = false) => {
    try {
      const obj = { headers: { Authorization: `Bearer ${LocalStorageService.getToken()}` }};
      // Generate or download a room card
      const apiRoute = roomCard && !generate ? `room-card/${this.state.roomCardQuote.id}/download` : `generate-room-card/${this.props.project.condominiumProject.condominium_id}/${this.state.roomCardQuote.id}`;
      const res = await fetch(`${process.env.REACT_APP_API_HOST}/api/v1/${apiRoute}`, obj);
      const name = `Huonekortti_${this.state.roomCardApartment.stairwell}_${this.state.roomCardApartment.apartment_number}`;

      if (res.ok) {
        const binary = await res.blob();
        const extension = binary.type === 'application/pdf' ? '.pdf' : '.docx';
        const fileName = name + extension;

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(binary, fileName);
        } else {
          const src = window.URL.createObjectURL(binary);
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.style.cssText = 'display: none';
          a.href = src;
          a.download = fileName;
          a.click();
          window.URL.revokeObjectURL(src);
          setTimeout((x) => { document.body.removeChild(a); }, 1000);
        }
      } else if (res.status !== 410) { // 410 means dont retry
        throw new Error('failed to fetch document'); // TODO! Fatal error, do actual logging
      }
    } catch (ex) {
      console.error(ex); // TODO! Fatal error, do actual logging
    }
  }

  createMaterialformDeadline = (form, apartment) => {
    const materialformDeadline = {
      id: null,
      material_form_id: form,
      apartment_id: apartment,
      deadline: null
    };

    this.setState({ materialformDeadline, modal: Modals.materialformDeadline });
  }

  createQuoteDeadline = (quote) => {
    this.setState({ quote, modal: Modals.quoteDeadline });
  }

  editMaterialformDeadline = (materialform_deadline) => {
    this.setState({ materialformDeadline: materialform_deadline, modal: Modals.materialformDeadline });
  }

  editQuoteDeadline = (quote_deadline) => {
    this.setState({ quote: quote_deadline, modal: Modals.quoteDeadline });
  }

  editRoomCardDeadline = (quote) => {
    this.setState({ quote: quote, modal: Modals.roomCardDeadline });
  }

  handleRoomCardUpload = (e, quote, dropped, apartment) => {
    const file = dropped ?  e.dataTransfer.files[0] : e.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
          const payload = {
            room_card: file,
          };

          const api = new AvainiaCore(LocalStorageService.getToken);
          api.roomCardUpload(quote.id, payload).then((res) => {
          if (res?.error) { return this.setState({ error: res.error }); }
            const success = I18n.t('views.roomCards.upload-success', {
              apartment,
            });

            let newQuotes = this.state.materialQuotes;
            let newQuote = newQuotes.find(q => q.id === quote.id);

            newQuote.custom_room_card = true;
            newQuote.room_card_approved = false;

            newQuotes = newQuotes.map(q => {
              if (q.id === quote.id) { q = newQuote };
              return q;
            });

            this.setState({
              materialQuotes: newQuotes, success, show: true,
            }, () => {
              window.setTimeout(() => {
                this.setState({ show: false })
              }, 3000)
            });
          });
      };

      reader.readAsDataURL(file);
    }
  }

  handleRoomCardRemove = (quote) => {
    if (!window.confirm(I18n.t('views.materialforms.confirm-delete'))) { return; } // TODO: Improve, maybe?
    const api = new AvainiaCore(LocalStorageService.getToken);
    api.roomCardDelete(quote.id).then((res) => {
    if (res?.error) { return this.setState({ error: res.error }); }
      let newQuotes = this.state.materialQuotes;
      let newQuote = newQuotes.find(q => q.id === quote.id);

      newQuote.custom_room_card = null;
      newQuotes = newQuotes.map(q => {
        if (q.id === quote.id) { q = newQuote };
        return q;
      })

      this.setState({
        materialQuotes: newQuotes,
      })
    });
  }

  render() {
    if (this.state.error) { return <Error error={this.state.error} />; }
    if (this.state.loading) { return <Loading />; }

    let fileRefs = [];
    const stairwells = [];
    this.props.apartments.forEach((apt) => { if (stairwells.indexOf(apt.stairwell) === -1) { stairwells.push(apt.stairwell); } });

    return <AvainiaPanel double={false} icon={<File />} heading={I18n.t('views.materialforms.quotes-heading')}>
      <span style={{position: 'fixed', top: '5rem', right: '5rem'}}>
          <Success inline transition={true} dismissable={true} success={this.state.success} show={this.state.show} />
      </span>
      <Button size="sm" style={{ width: '25%', display: 'block', marginLeft: 'auto', fontWeight: 'bold' }} onClick={() => { this.showProjectProductReport(); }}>
        {I18n.t('views.productReports.show-product-report')}
      </Button>

      {stairwells.map((s) => <div key={s} className="stairwell" style={{ marginBottom: '15px' }}>
        <h5 style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>{I18n.t('views.condominiums.stairwell')} {s}</h5>
        <table style={{ width: '100%' }} className='table-striped'>
          <thead className='table-headers'>
            <tr className='table-headers--main'>
              <th style={{ width: '5%'}} /> {/* Main headers */}
              <th style={{ width: '10%'}}>{I18n.t('views.materialforms.phases.material-selections')}</th>
              <th style={{ width: '15%'}}/>
              <th style={{ width: '12%'}}>{I18n.t('views.materialforms.phases.quote')}</th>
              <th style={{ width: '10%'}}/>
              <th style={{ width: '15%'}}>{I18n.t('views.materialforms.phases.room-card')}</th>
              <th style={{ width: '10%'}}/>
              <th style={{ width: '6%'}}/>
              <th style={{ width: '10%'}}/>
              <th style={{ width: '6%'}}/>
            </tr>
            <tr className='table-headers--sub'>
              <th/> {/* Sub headers */}
              <th>{I18n.t('general.status')}</th>
              <th>{I18n.t('views.materialforms.materialform-deadline')}</th>
              <th>{I18n.t('general.status')}</th>
              <th>{I18n.t('views.materialforms.materialform-deadline')}</th>
              <th>{I18n.t('general.status')}</th>
              <th>{I18n.t('views.materialforms.materialform-deadline')}</th>
              <th/>
            </tr>
          </thead>
          <tbody className='table-body'>
            {this.props.apartments.filter((apt) => apt.stairwell === s).map((apt) => {
              return apt.materialforms.map((form) => {
                const newRef = React.createRef();
                fileRefs.push(newRef);
                const submission = apt.material_form_submissions.find((x) => x.material_form_id === form.id);
                const quote = this.apartmentHasMaterialQuote(apt, form, submission);
                const timeFormat = 'D.M.YYYY HH:mm';
                const materialform_deadline = this.state.materialFormDeadlines.find((x) => (x.material_form_id == form.id && x.apartment_id == apt.id));
                let materialform_deadline_formatted = materialform_deadline ? moment(materialform_deadline.deadline).format(timeFormat) : "";
                const quote_deadline = quote?.deadline && quote?.deadline !== '0000-00-00 00:00:00' ? quote : false;
                let quote_deadline_formatted = quote_deadline ? moment(quote_deadline.deadline).format(timeFormat) : "";
                const isEditable = quote?.project_id && (quote.status === 'created' || quote.status === 'pending');
                const multipleForms = apt.materialforms.length > 1;
                const apartment = apt.stairwell + apt.apartment_number;

                const roomcard_deadline = quote?.room_card_approval_deadline && quote?.room_card_approval_deadline !== '0000-00-00 00:00:00' ? quote.room_card_approval_deadline : false;
                let roomcard_deadline_formatted = roomcard_deadline ? moment(roomcard_deadline).format(timeFormat) : "";

                return (
                  <tr key={apt.id}>
                    {/* Apartment code */}
                    <td style={{padding: '0.5rem 2rem', fontWeight: 'bold', width: multipleForms && '10%'}}>
                      {apartment}
                      {multipleForms && <p style={{margin: '0', fontWeight: 'normal'}}>{form.name}</p>}
                    </td>

                    {/* Material selections */}
                    <td key={form.id}>
                      {(submission) && <div style={{padding: '0.5rem'}}>
                        {this.printStatusColor(submission.status === 'open' ? 'red' : 'green', I18n.t('views.materialforms.phases.material-selections'))}
                      </div> }
                    </td>

                    <td>
                      {materialform_deadline && <Button className='btn-light' variant="light" size='sm' onClick={() => {this.editMaterialformDeadline(materialform_deadline)}}>{materialform_deadline_formatted}</Button> }
                      {!materialform_deadline && <Button className='btn-light' variant="light" size='sm' onClick={() => {this.createMaterialformDeadline(form.id, apt.id)}}><PlusCircle/> {I18n.t('views.roomCards.add-deadline')}</Button> }
                    </td>

                    {/* Quotes */}
                    <td key={form.id}>{submission && submission.status === 'sent' && this.printQuoteStatus(apt, form, submission)}</td>

                    {/* Quote deadline */}
                    <td key={form.id}>
                      {quote_deadline && isEditable && <Button className='btn-light' variant="light" size='sm' onClick={() => {this.editQuoteDeadline(quote_deadline)}}>{quote_deadline_formatted}</Button>}
                      {quote_deadline && !isEditable && <Button className='btn-light' variant="light" size='sm' disabled>{quote_deadline_formatted}</Button>}
                      {!quote_deadline && isEditable && <Button className='btn-light' variant="light" size='sm' onClick={() => {this.createQuoteDeadline(quote)}}><PlusCircle/> {I18n.t('views.roomCards.add-deadline')}</Button>}
                    </td>

                    {/* Room cards */}
                    { quote && quote.status === 'approved' &&
                      <>
                        <td>
                          { this.printRoomCardStatus(quote, form, submission, apt) }
                        </td>
                        {/* RoomCard deadline */}
                        <td key={form.id}>
                          {roomcard_deadline && <Button className='btn-light' variant="light" size='sm' onClick={() => {this.editRoomCardDeadline(quote)}}>{roomcard_deadline_formatted}</Button>}
                          {!roomcard_deadline && <Button className='btn-light' variant="light" size='sm' onClick={() => {this.editRoomCardDeadline(quote)}}><PlusCircle/> {I18n.t('views.roomCards.add-deadline')}</Button>}
                        </td>
                        <td>
                          {quote.custom_room_card && <Button className='btn-light' variant="light" size='sm'
                            onClick={() => this.setState({
                              roomCardSubmissionFormId: submission.id,
                              roomCardMaterialFormId: form.id,
                              roomCardApartment: apt,
                              modal: Modals.roomCard,
                              roomCardQuote: quote,
                              roomCardQuoteId: quote.id,
                            }, () => { this.downloadAttachment(quote.custom_room_card); })}>
                                <ArrowDownTray/>
                          </Button>}
                        </td>
                        <td>
                          <Button className='btn-light--input' variant="light" size='sm'
                            onDragOver={(e) => {e.preventDefault(); e.stopPropagation();}}
                            onDrop={(e) => { e.preventDefault(); e.stopPropagation(); this.handleRoomCardUpload(e, quote, true, apartment)}}
                            onDragEnter={(e) => {e.preventDefault(); e.stopPropagation();}}
                            onDragLeave={(e) => {e.preventDefault(); e.stopPropagation();}}
                          >
                            <Form.Control type="file" id={`room-card-${quote.id}`} className="roomCardInputfile" onChange={(e) => this.handleRoomCardUpload(e, quote, false, apartment)} name={`room-card-${quote.id}`} ref={ newRef }/>
                            <label htmlFor={`room-card-${quote.id}`} className='clickable'><ArrowUpTray /> {I18n.t('views.roomCards.upload-room-card')}</label>
                          </Button>
                        </td>
                      </>}
                      {quote?.status !== 'approved' && <><td/><td/><td/><td/></>}
                    <td>
                      {quote && <DropdownButton title={<EllipsisVertical/>} id={`materialform-row-${quote?.id}`} className='btn-light--dropdown' style={{display: 'flex', justifyContent: 'flex-end'}} variant="light" size='sm'>
                          <Button variant='light' className='btn-light' size='sm' style={{width: '100%'}} onClick={() => this.setState({
                            roomCardSubmissionFormId: submission.id,
                            roomCardMaterialFormId: form.id,
                            roomCardApartment: apt,
                            modal: Modals.roomCard,
                            roomCardQuote: quote,
                            roomCardQuoteId: quote.id,
                            }, () => { this.downloadAttachment(quote.custom_room_card); })}>
                              <PlusCircle/> {I18n.t('views.materialforms.create-new-room-card')}
                          </Button>
                          { quote?.custom_room_card && <Button className='btn-light' variant="light" size='sm' style={{width: '100%'}} onClick={() => this.handleRoomCardRemove(quote)}><Trash />{I18n.t('views.roomCards.remove-room-card')}</Button> }
                        </DropdownButton>}
                    </td>
                    {/* Tables ¯\_(ツ)_/¯ */}
                    { apt.materialforms.length === 0 && <><td/><td/><td/><td/><td/><td/></> }
                  </tr>
                );
              })
            })}
          </tbody>
        </table>
        <hr style={{marginTop: '3rem'}}/>
        {/* Legend */}
        <div style={{display: 'flex'}}>
          {this.printStatusColor('red', I18n.t('views.materialforms.statuses.pending-owner'))}
          {this.printStatusColor('orange', I18n.t('views.materialforms.statuses.pending-contractor'))}
          {this.printStatusColor('green', I18n.t('views.materialforms.statuses.approved'))}
        </div>
      </div>)}

      {this.state.modal === Modals.quote &&
        <ApartmentMaterialselectionQuoteModal
          key={this.state.modalKeyHack}
          onHide={this.hideModal}
          apartment={this.state.targetApartment}
          project={this.props.project}
          materialforms={this.props.materialforms}
          targetMaterialForm={this.state.targetMaterialForm}
          submissionForm={this.state.targetMaterialFormSubmission}
          quote={this.state.targetQuote}
          refreshQuoteCallback={this.refreshQuoteCallback}
          updateSubmissionFormCallback={this.updateSubmissionFormCallback}
        />
      }

      {this.state.modal === Modals.productReport &&
        <ProductReportModal
          data={this.state.reportData}
          onHide={this.hideModal}
          key={this.state.modalKeyHack}
        />
      }

      {this.state.modal === Modals.projectProductReport &&
        <ProjectProductReportModal
          onHide={this.hideModal}
          data={this.state.report}
        />
      }

      {this.state.modal === Modals.materialformDeadline &&
        <MaterialformDeadlineModal
          onHide={this.hideModal}
          data={this.state.materialformDeadline}
        />
      }

      {this.state.modal === Modals.quoteDeadline &&
        <QuoteDeadlineModal
          onHide={this.hideModal}
          quote={this.state.quote}
        />
      }

      {this.state.modal === Modals.roomCardDeadline &&
        <RoomCardDeadlineModal
          onHide={this.hideModal}
          quote={this.state.quote}
        />
      }
    </AvainiaPanel>;
  }
}

export default MaterialformQuotesPanel;
