import React, { Component } from 'react';
import { Button, ListGroup, InputGroup, Form } from 'react-bootstrap';
import I18n from 'i18n-js';
import AvainiaCore from 'avainia-core-api';
import { Trash, Cog, Edit, Undo } from '../../multiview/Icon/Icon.js';
import AvainiaTable from '../../multiview/AvainiaTable/AvainiaTable.js';
import AvainiaTableHeading from '../../multiview/AvainiaTable/AvainiaTableHeading.js';
import Error from '../../multiview/Error/Error.js';
import Loading from '../../multiview/Loading/Loading.js';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';
import AddAdditionalProductModal from './AdditionalProductAddModal.js';
import EditAdditionalProductModal from './AdditionalProductEditModal.js';
import AvainiaPanel from '../../multiview/Panels/AvainiaPanel.js';

const Modals = {
  addAdditionalProduct: 1,
  editAdditionalProduct: 2,
};

class AdditionalProducts extends Component {

  constructor(props) {
    super(props);

    this.state = {
      additionalProducts: [],
      editing: null,
      modal: '',
      error: '',
      loading: false,
      showHidden: false,
    };
  }

  componentDidMount = () => {
    const api = new AvainiaCore(LocalStorageService.getToken);
    api.additionalProductsGet().then((additionalProducts) => {
      if (additionalProducts.error) { return this.setState({ error: additionalProducts.error }); }

      this.setState({
        additionalProducts,
      })
    })
  }

  deleteAdditionalProduct = (additionalProduct) => {
    this.setState({ loading: true }, () => {
      const api = new AvainiaCore(LocalStorageService.getToken);

      api.additionalProductDelete(additionalProduct.id).then((result) => {
        if (result.error) { return this.setState({ error: result.error }); }
        let { additionalProducts } = this.state;
        additionalProducts = additionalProducts.filter((x) => x.id !== additionalProduct.id);
        this.setState({ additionalProducts, loading: false });
      });
    });
  }

  toggleHideAdditionalProduct = (additionalProduct) => {

    const api = new AvainiaCore(LocalStorageService.getToken);

    additionalProduct.hidden = additionalProduct.hidden ? 0 : 1;

    api.additionalProductEdit(additionalProduct.id, additionalProduct).then((result) => {
      if (result.error) { return this.setState({ error: result.error }); }
      return this.setState({ loading: false, error: false }, () => {
        this.setState({ loading: false });
        window.location.reload();
      });
    });

  }

  hideModal = () => { this.setState({ modal: false }); }

  editAdditionalProduct = (additionalProduct) => {
    if (this.state.loading) { return; }

    const editingTarget = this.state.additionalProducts.find((x) => x.id === additionalProduct.id);
    this.setState({ editing: editingTarget, modal: Modals.editAdditionalProduct });
  }

  addAdditionalProduct = () => {
    if (this.state.loading) { return; }

    this.setState({ modal: Modals.addAdditionalProduct });
  }

  renderActions = (cell, row) => {
    return <>
      <Cog onClick={() => { this.editAdditionalProduct(row); }} className="clickable" />
      {!this.state.showHidden && <Trash onClick={() => { this.toggleHideAdditionalProduct(row); }} className="clickable" /> }
      {this.state.showHidden && <Undo onClick={() => { this.toggleHideAdditionalProduct(row); }} className="clickable" /> }
    </>;
  }

  refreshList = () => {
    const api = new AvainiaCore(LocalStorageService.getToken);
    api.additionalProductsGet().then((additionalProducts) => {
      if (additionalProducts.error) { return this.setState({ error: additionalProducts.error }); }

      this.setState({
        additionalProducts,
      })
    })
  }

  render() {
    const { additionalProducts } = this.state;

    if (this.state.error) { return <Error error={this.state.error} />; }
    if (this.state.loading) { return <Loading />; }

    var filteredProducts = additionalProducts;

    if(!this.state.showHidden){
      filteredProducts = additionalProducts.filter((x) => x.hidden === 0);
    } else {
      filteredProducts = additionalProducts.filter((x) => x.hidden === 1);
    }

    return (
      <AvainiaPanel icon={null} heading={null}>
        <AvainiaTableHeading
          title={I18n.t('views.products.additionalProducts')}
          button={
            <div style={{display:"flex"}}>
              <Button onClick={this.addAdditionalProduct} size="m">
                {I18n.t('views.products.add-additional-product')}
              </Button>
              <Button style={{marginLeft: "15px"}} variant="secondary" onClick={() => {this.setState({ showHidden: !this.state.showHidden });}} size="m">
                {!this.state.showHidden && <span>Näytä piilotetut</span> }
                {this.state.showHidden && <span>Näytä aktiiviset</span> }
              </Button>
            </div>
          }
        />
        {
          filteredProducts &&
            <AvainiaTable
              data={filteredProducts}
              keyField="id"
              columns={
                [{
                  dataField: 'id',
                  text: I18n.t('views.products.id')
                }, {
                  dataField: 'code',
                  text: I18n.t('views.products.code')
                },{
                  dataField: 'name',
                  text: I18n.t('views.products.name')
                }, {
                  dataField: 'description',
                  text: I18n.t('views.products.description')
                }, {
                  dataField: 'price',
                  text: I18n.t('views.products.price')
                }, {
                  dataField: 'labor_price',
                  text: I18n.t('views.products.labor_price')
                }, {
                  dataField: 'product_photo',
                  text: I18n.t('views.products.product-photo'),
                  formatter: (cell, row) => {
                    return row.product_photo ? I18n.t('general.yes') : I18n.t('general.no') ;
                  },
                }, {
                  dataField: 'actions',
                  text: I18n.t('general.table-actions'),
                  headerStyle: { width: '100px' },
                  formatter: this.renderActions
                },
              ]
              }
            />
        }

        { this.state.modal === Modals.addAdditionalProduct &&
          <AddAdditionalProductModal
            refresh={this.refreshList}
            onHide={this.hideModal}
          />
        }

        { this.state.modal === Modals.editAdditionalProduct &&
          <EditAdditionalProductModal
            refresh={this.refreshList}
            additionalProduct={this.state.editing}
            onHide={this.hideModal}
          />
        }
      </AvainiaPanel>
    );
  }
}

export default AdditionalProducts;
