import React, { Component } from 'react';
import I18n from 'i18n-js';
import { Button, Modal, Form, Badge, FormControl } from 'react-bootstrap';
import AvainiaCore from 'avainia-core-api';
import Error from '../Error/Error.js';
import Loading from '../Loading/Loading.js';
import '../../mainviews/Notifications/notificationsAdmin.scss';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';

class ModalNotificationEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: '',
      body: '',
      attachment: null,
      creator_user_id: '',
      editor_user_id: '',
      published: false,
      loading: true,
      error: false,
      requiredError: false,
      hasAttachment: null,
      notifiable: 0,
      visibility: 'global',
      projects: null,
      selectedTargetIds: {
        global: {}
      },
    };
  }

  componentDidMount = () => {
    const { notification } = this.props;
    const api = new AvainiaCore(LocalStorageService.getToken);
    const hasAttachment = notification.attachment !== null;
    const isNotifiable = notification.notifiables.length !== 0;
    const equalVisibilities = isNotifiable ? notification.notifiables.every( (v, i, arr) => v.notifiable_type === arr[0].notifiable_type ) : null;
    const visibility = isNotifiable ? notification.notifiables[0].notifiable_type.slice(4).toLowerCase() : 'global';
    const selectedIds = notification.notifiables.map(notifiable => notifiable.notifiable_id );
    let selectedTargetIds = {}

    selectedIds.forEach(id => {
      selectedTargetIds[id] = true;
    });

    const promises = [
      api.projectsGet('projects'),
      api.condominiumsGet('condominiums'),
      isNotifiable && visibility === 'apartment' && api.condominiumBasedOnApartmentGet(selectedIds[0], 'condominium'),
      isNotifiable && visibility === 'apartment' && api.condominiumApartmentsGetByApartmentId(selectedIds[0], 'apartments'),
    ];

    Promise.all(promises).then((data) => {
      let error = false;
      let projects;
      let condominiums;
      let condominium;
      let apartments;


      data.forEach((x) => {
        if (error) { return; }
        if (x.error) { error = x.error; return; }
        if (x.projects) { projects = x.projects; }
        if (x.condominiums) { condominiums = x.condominiums; }
        if (isNotifiable && visibility === 'apartment') {
          if (x.condominium) { condominium = x.condominium; }
          if (x.apartments) { apartments = x.apartments; }
        }
      });

      if (error) { return this.setState({ loading: false, error }); }

      this.setState({
        hasAttachment,
        loading: false,
        title: notification.title,
        body: notification.body,
        attachment: notification.attachment,
        creator_user_id: notification.creator_user_id,
        editor_user_id: notification.editor_user_id,
        published: notification.is_published,
        notifiable: isNotifiable,
        visibility: equalVisibilities ? visibility : 'global',
        error: (!equalVisibilities && visibility != 'global') ? 44 : false,
        condominium: condominium,
        projects,
        apartments,
        condominiums,
        selectedTargetIds: {
          [visibility]: selectedTargetIds
        },
      }, () => this.state.selectedTargetIds);
     });
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleFileChange = (e) => {
    if (e.target.files[0]) {
      const { name } = e.target;
      const reader = new FileReader();
      const file = e.target.files[0];

      reader.onload = () => {
        this.setState({
          [name]: file,
        });
      };

      reader.readAsDataURL(file);
    }
  }

  removeOldFile = () => {
    this.setState({ attachment: null, attachmentRemoved: true });
  }

  handlePublishChange = (e) => {
    this.setState({ published: e.target.checked });
  }

  updateNotification = (e) => {
    if (this.state.loading) {
      return;
    }

    if (this.props.requiredFieldsCheck(this.state)) {
      let ids = [];
      const targetIds = this.state.selectedTargetIds[this.state.visibility]

      if (targetIds && Object.keys(targetIds).length === 0 && targetIds.constructor === Object) {
        ids = [];
      } else {
        for (const [key, value] of Object.entries(targetIds)) {
          if (value === true) {
            ids.push(parseInt(key));
          }
        }
      }



      this.setState(
        { requiredError: false, loading: true, error: false },
        () => {
          const payload = {
            title: this.state.title,
            body: this.state.body,
            is_published: this.state.published,
            is_edited: true,
            notifiable: this.state.notifiable,
            visibility: this.state.visibility,
            selectedTargetIds: ids
          };

          // This indicates a file that has been changed
          if (this.state.attachment && !this.state.attachment.filename) {
            payload.attachment = this.state.attachment;
            payload.attachmentChanged = true;
            payload.attachmentRemoved = false;
          }

          if (this.state.attachmentRemoved) {
            payload.attachmentRemoved = true;
          }

          const api = new AvainiaCore(LocalStorageService.getToken);
          api.notificationEdit(payload, this.props.notification.id).then(
            (notification) => {
              if (notification.errorCode) { return this.setState({ error: notification.errorCode.code, loading: false }); }

              this.setState({
                title: '',
                body: '',
                attachment: null,
                published: false,
                loading: false,
                notifiable: false,
                visibility: 'global',
                selectedTargetIds: {}
              });
              this.props.onHide();
              this.props.notificationCallback();
            },
          );
        },
      );
    } else {
      this.setState({ requiredError: I18n.t('general.required') });
    }
  };

  onSelectChange = (e) => {
    let value = e.target.value;
    if (value === 'global') {
      this.setState({ notifiable: 0, visibility: value, selectedTargetIds: { global: {} } })
    } else {
      this.setState({ notifiable: 1, visibility: value, selectedTargetIds: {...this.state.selectedTargetIds[value] } })
    };
  }

  handleNotificationRangeChange = (e) => {
    const type = this.state.visibility
    this.setState({ selectedTargetIds: {
      ...this.state.selectedTargetIds, [type]: {
        ...this.state.selectedTargetIds[type], [e.target.name]: e.target.checked
      }
    }});
  }

  onSelectCondoForApartmentsChange = (e) => {

    const condoId = e.target.value
    const api = new AvainiaCore(LocalStorageService.getToken);

    api.condominiumApartmentsGet(condoId).then((apartments) => {
      if (apartments.error) { return this.setState({ error: apartments.error }); }

      this.setState({
        apartments,
        selectedTargetIds: {
          global: {}
        },
        loading: false,
        selectedCondoId: condoId,
      });
    });
  }


  render() {
    const visibilities = [
      {id: 0, type: 'global', name: I18n.t('views.notifications.global')},
      {id: 1, type: 'project', name: I18n.t('views.notifications.project')},
      ...this.props.configuration.condominiums ? [{id: 2, type: 'condominium', name: I18n.t('views.notifications.condominium')}] : [],
      ...this.props.configuration.condominiums ? [{id: 3, type: 'apartment', name: I18n.t('views.notifications.apartment')}] : [],
    ]

    return (
      <Modal show={true} onHide={this.props.onHide}>
        <Modal.Header closeButton>
          <Modal.Title>{I18n.t('views.notifications.edit-notification')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.error && <Error error={this.state.error} inline />}
          {this.state.loading && <Loading inline />}
          {!this.state.loading && (
            <>
              <Form.Group controlId='edit-modal-title'>
                <Form.Label>{I18n.t('views.notifications.title')}</Form.Label>
                <Form.Control
                  type='text'
                  onChange={this.onChange}
                  name='title'
                  value={this.state.title}
                />
                <Badge variant='danger'>{this.state.requiredError}</Badge>
              </Form.Group>
              <Form.Group controlId='edit-modal-body'>
                <Form.Label>{I18n.t('views.notifications.body')}</Form.Label>
                <Form.Control
                  as="textarea" rows="5"
                  onChange={this.onChange}
                  name='body'
                  value={this.state.body}
                />
                <Badge variant='danger'>{this.state.requiredError}</Badge>
              </Form.Group>
              <Form.Group controlId="publish-checkbox">
                <Form.Check
                  type='checkbox'
                  label={I18n.t('views.notifications.publish')}
                  checked={this.state.published}
                  onChange={this.handlePublishChange}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>{I18n.t('views.notifications.attachment')}</Form.Label><br/>
                  {/* If no file has been added previously */}
                  {!this.state.attachment && <>
                    <Form.Control
                      type="file"
                      id="inputfile"
                      className="inputfile"
                      onChange={this.handleFileChange}
                      name="attachment"
                    />
                    <label className="label-select-file" htmlFor="inputfile">
                      {I18n.t('views.notifications.select-file')}
                    </label>
                  </>}
                  {this.state.attachment ? <p>{this.state.attachment.name}
                    <button className="notification-button" onClick={() => this.removeOldFile()}>
                      {I18n.t('general.delete')}
                    </button>
                    {this.state.hasAttachment &&
                    <button className="notification-button" onClick={() => this.props.downloadAttachment(this.props.notification)}>
                      {I18n.t('views.notifications.download-file')}
                    </button>}
                  </p> : null}
              </Form.Group>

              <Form.Group>
                <FormControl
                  as="select"
                  onChange={ this.onSelectChange }
                  name="visibility"
                  placeholder={I18n.t('views.notifications.visibility')}
                >
                {
                  visibilities.map((visibility) => {
                      return <option selected={this.state.visibility === visibility.type} key={visibility.id} value={visibility.type}>{visibility.name} </option>;
                  })
                }
                </FormControl>
              </Form.Group>

              {
                this.state.visibility && this.state.visibility === 'project' &&
                  this.state.projects.map((project) =>
                    <Form.Check
                      type='checkbox'
                      label={project.name}
                      checked={this.state.selectedTargetIds.project ? this.state.selectedTargetIds.project[project.id] : false}
                      onChange={this.handleNotificationRangeChange}
                      name={project.id}
                    />
                  )
              }
              {
                this.props.configuration.condominiums && this.state.visibility && this.state.visibility === 'condominium' &&
                  this.state.condominiums.map((condo) =>
                    <Form.Check
                      type='checkbox'
                      label={condo.name}
                      checked={this.state.selectedTargetIds.condominium ? this.state.selectedTargetIds.condominium[condo.id] : false}
                      onChange={this.handleNotificationRangeChange}
                      name={condo.id}
                    />
                  )
              }

              {
                this.props.configuration.condominiums && this.state.visibility && this.state.visibility === 'apartment' && <>
                    <FormControl
                      as="select"
                      onChange={ this.onSelectCondoForApartmentsChange }
                      name="Select from condo"
                      placeholder={I18n.t('views.notifications.visibility')}
                    >
                    {
                      this.state.condominiums.map((condo) => {
                          return <option selected={this.state.condominium} key={condo.id} value={condo.id}>{condo.name} </option>;
                      })
                    }
                    </FormControl>
                    {this.state.apartments.length > 0 && this.state.apartments.map((apt) => {
                      return (
                        <Form.Check
                          type='checkbox'
                          label={apt.apartment_number + ' ' + apt.stairwell}
                          checked={this.state.selectedTargetIds.apartment && this.state.selectedTargetIds.apartment[apt.id] && this.state.selectedTargetIds.apartment[apt.id] ? true : false}
                          onChange={this.handleNotificationRangeChange}
                          name={apt.id}
                        />
                      )

                    }
                    )}
                  </>
              }
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={this.props.onHide}>
            {I18n.t('views.notifications.button-cancel')}
          </Button>
          <Button disabled={this.state.error} variant='primary' onClick={this.updateNotification}>
            {I18n.t('views.notifications.button-save')}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ModalNotificationEdit;
