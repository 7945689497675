import React, { Component } from 'react';
import I18n from 'i18n-js';
import { Button, FormControl, Modal, Badge } from 'react-bootstrap';
import AvainiaCore from 'avainia-core-api';
import Error from '../Error/Error.js';
import Loading from '../Loading/Loading.js';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';

class ModalFolderAdd extends Component {
  constructor(props) {
    super(props);

    this.state = {
      foldername: '',
      parentId: null,
      visibility: 'global',
      loading: false,
      error: false, // TODO: Implement
      requiredError: '',
    };
  }

  create = async (e) => {
    if (this.state.foldername) {
      const payload = {
        name: this.state.foldername,
        parentId: this.props.parentFolder ? this.props.parentFolder.id : null,
        visibility: this.state.visibility
      };

      this.setState({ loading: true, requiredError: '' }, () => {
        const api = new AvainiaCore(LocalStorageService.getToken);
        api.projectFolderCreate(this.props.project, payload).then((result) => {
          if (result.error) { return this.setState({ error: result.error, loading: false }); }

          window.location.reload(); // TODO: Improve
        });
      });
    } else {
      this.setState({ requiredError: I18n.t('general.required') });
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    const options = [{id: 'global', name: I18n.t('defaultfolders.global')}, {id: 'project', name: I18n.t('defaultfolders.project')}, {id: 'owner', name: I18n.t('defaultfolders.owner')}, {id: 'ownertenant', name: I18n.t('defaultfolders.ownertenant')}];

    return <Modal show={true} onHide={this.props.hideModal}>
      <Modal.Header closeButton>
        <Modal.Title>{I18n.t('views.infraproject.add-folder')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {this.props.parentFolder && <>
          <FormControl
            value={`${I18n.t('folders.parentFolder')} ${this.props.parentFolder.name}`}
            disabled
            name="name"
            type="text"
          />
          <br />
        </>}

        {this.state.error && <Error error={this.state.error} inline />}
        {this.state.loading && <Loading error={this.state.error} inline />}
        {!this.state.loading &&
          <>
            <FormControl
              placeholder={I18n.t('folders.name')}
              name="foldername"
              type="text"
              value={this.state.name}
              onChange={this.onChange}
            />
            <Badge variant="danger">{this.state.requiredError}</Badge>

            { this.props.configuration.folderVisibilitySelector && 
              <FormControl as="select" onChange={this.onChange} name="visibility">
              {options.map((x) => <option value={x.id} key={x.id}>{x.name}</option>)}
              </FormControl>
            }

          </>
        }
      </Modal.Body>
      <Modal.Footer>
        {!this.state.loading && <>
          <Button variant="secondary" onClick={this.props.hideModal}>{I18n.t('general.cancel')}</Button>
          <Button variant="primary" onClick={this.create}>{I18n.t('general.save')}</Button>
        </>}
      </Modal.Footer>
    </Modal>;
  }
}

export default ModalFolderAdd;
