import React, { Component } from 'react';
import I18n from 'i18n-js';
import AvainiaCore from 'avainia-core-api';
import { Check, Close } from '../../multiview/Icon/Icon.js';
import Error from '../../multiview/Error/Error.js';
import Loading from '../../multiview/Loading/Loading.js';
import AvainiaPermissions from '../../../AvainiaTools/AvainiaPermissions.js';
import AvainiaTable from '../../multiview/AvainiaTable/AvainiaTable.js';
import CustomSelect from '../../multiview/Select/CustomSelect.js';
import { TopbarContext } from '../../../contexts/TopbarContext.js';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';

class Permissions extends Component {
  static contextType = TopbarContext;

  constructor(props) {
    super(props);

    this.state = {
      users: [],
      user: false,
      allPermissions: [],
      owner: [],
      typedRoles: [],
      loading: true,
      error: false,
      key: Math.random(),
    };
  }

  componentDidMount() {
    const api = new AvainiaCore(LocalStorageService.getToken);

    // TODO: Convert to Promise.all
    api.permissionsGet().then((permissions) => {
      if (permissions.error) { return this.setState({ error: permissions.error }); }

      api.permissionsForOwnerGet().then((owner) => {
        if (owner.error) { return this.setState({ error: owner.error }); }

        api.rolesTypedGet().then((typedRoles) => {
          if (typedRoles.error) { return this.setState({ error: typedRoles.error }); }

          api.usersGet().then((users) => {
            if (users.error) { return this.setState({ error: users.error }); }

            return this.setState({
              users,
              owner,
              typedRoles,
              allPermissions: permissions,
              loading: false,
            }, this.context.resetTopbar);
          });
        });
      });
    });
  }

  updatePermissions = () => {
    const api = new AvainiaCore(LocalStorageService.getToken);

    api.permissionsGet().then((permissions) => {
      if (permissions.error) { return this.setState({ error: permissions.error }); }

      api.permissionsForOwnerGet().then((owner) => {
        if (owner.error) { return this.setState({ error: owner.error }); }

        api.rolesTypedGet().then((typedRoles) => {
          if (typedRoles.error) { return this.setState({ error: typedRoles.error }); }

          api.usersGet().then((users) => {
            if (users.error) { return this.setState({ error: users.error }); }

            return this.setState({
              owner,
              allPermissions: permissions,
              loading: false,
              key: Math.random(),
            });
          });
        });
      });
    });
  }

  handleUserSelectChange = (selectName, selectedValue) => {
    const user = this.state.users.find((x) => x.id === Number(selectedValue.value));
    this.setState({ user });
  }

  savePermission = (role, permission) => {
    if (this.state.loading) { return; }
    if (permission.name === AvainiaPermissions.PermissionsManage) {
      const p = I18n.t(`permissions.${permission.name.replace('.', '_')}`);
      const r = I18n.t(`constants.companyTypes.${role}`, role);
      const msg = I18n.t('views.permissions.confirm-permission', { role: r, permission: p });
      if (!window.confirm(msg)) {
        return;
      }
    }

    this.setState({ loading: true }, () => {
      const api = new AvainiaCore(LocalStorageService.getToken);
      api.permissionsSave(role, permission).then((result) => {
        if (result.error) { return this.setState({ error: result.error }); }
        this.updatePermissions();
      });
    });
  }

  deletePermission = (role, permission) => {
    if (this.state.loading) { return; }
    if (permission.name === AvainiaPermissions.PermissionsManage) {
      const p = I18n.t(`permissions.${permission.name.replace('.', '_')}`);
      const r = I18n.t(`constants.companyTypes.${role}`, role);
      const msg = I18n.t('views.permissions.confirm-permission-revoke', { role: r, permission: p });
      if (!window.confirm(msg)) {
        return;
      }
    }

    this.setState({ loading: true }, () => {
      const api = new AvainiaCore(LocalStorageService.getToken);
      api.permissionsDelete(role, permission).then((result) => {
        if (result.error) { return this.setState({ error: result.error }); }
        this.updatePermissions();
      });
    });
  }

  render() {
    if (this.state.error) { return <Error error={this.state.error} />; }
    if (this.state.loading) { return <Loading />; }

    const permissionData = this.state.allPermissions.map((permission) => {
      const permissionObject = {
        id: permission.id,
        name: permission.name,
        displayName: I18n.t(`permissions.${permission.name.replace('.', '_')}`),
        employee: !!this.state.owner.permissions.employee.find((p) => p.name === permission.name),
        editor: !!this.state.owner.permissions.editor.find((p) => p.name === permission.name),
        manager: !!this.state.owner.permissions.manager.find((p) => p.name === permission.name),
      };

      // Add Typed roles; Typed === based on companyType
      this.state.typedRoles.forEach((typedRole) => {
        permissionObject[typedRole.name] = typedRole.permissions.find((p) => p.name === permission.name);
      });

      return permissionObject;
    });

    return (
      <div className="App-container" key={this.state.key}>
        <h1>{I18n.t('permissions.permissions')}</h1>
        {/*
        <div>
          Joel muistio<br />
          <ul>
            <li>Luvat kumuloituvat posiiivisesti</li>
            <li>Lupia annetaan rooleille alla</li>
            <li>Tulevaisuudessa lupia voidaan_antaa myös henkilökohtaisesti</li>
            <li>Pitäisikö sisältöluvat olla tässä myös listattuna?</li>
          </ul>
        </div>
        */}

        <AvainiaTable
          condensed
          keyField="id"
          columns={[
            { dataField: 'displayName', text: '' },
            {
              dataField: 'employee',
              text: I18n.t('constants.userTypes.employee'),
              formatter: (cell, row) => {
                if (cell) {
                  return <Check className="clickable" style={{ color: '#008000' }} onClick={() => this.deletePermission('employee', row)} title={I18n.t('folders.click-to-revoke-permission')} />;
                }
                return <Close className="clickable" onClick={() => {this.savePermission('employee', row)}} title={I18n.t('folders.click-to-grant-permission')} />;
              },
            },
            {
              dataField: 'editor',
              text: I18n.t('constants.userTypes.editor'),
              formatter: (cell, row) => {
                if (cell) {
                  return <Check className="clickable" style={{ color: '#008000' }} onClick={() => this.deletePermission('editor', row)} title={I18n.t('folders.click-to-revoke-permission')} />;
                }
                return <Close className="clickable" onClick={() => this.savePermission('editor', row)} title={I18n.t('folders.click-to-grant-permission')} />;
              },
            },
            {
              dataField: 'manager',
              text: I18n.t('constants.userTypes.manager'),
              formatter: (cell, row) => {
                if (cell) {
                  return <Check className="clickable" style={{ color: '#008000' }} onClick={() => this.deletePermission('manager', row)} title={I18n.t('folders.click-to-revoke-permission')} />;
                }
                return <Close className="clickable" onClick={() => this.savePermission('manager', row)} title={I18n.t('folders.click-to-grant-permission')} />;
              },
            },
          ].concat(this.state.typedRoles.map((typedRole) => {
            return {
              dataField: typedRole.name,
              text: I18n.t(`constants.companyTypes.${typedRole.name}`),
              formatter: (cell, row) => {
                if (cell) {
                  return <Check className="clickable" style={{ color: '#008000' }} onClick={() => this.deletePermission(typedRole.name, row)} title={I18n.t('folders.click-to-revoke-permission')} />;
                }
                return <Close className="clickable" onClick={() => this.savePermission(typedRole.name, row)} title={I18n.t('folders.click-to-grant-permission')} />;
              },
            };
          }))}
          data={permissionData}
        />

        <hr />
        <p>Tarkista käyttäjän käyttöoikeudet</p>
        <CustomSelect
          name="user"
          placeholder={I18n.t('views.permissions.search')}
          handleChange={this.handleUserSelectChange}
          selectOptions={this.state.users.map((u) => { return { label: u.name, value: `${u.id}` }; })}
        />

        {this.state.user && this.state.user.permissions.length !== 0 && I18n.t('views.permissions.tab-permissions')}
        <ul>
          {this.state.user && this.state.user.permissions.length === 0 && <li>-</li>}
          {this.state.user && this.state.user.permissions.map((p) => (
            <li key={p.id}>{I18n.t(`permissions.${p.name.replace('.', '_')}`)}</li>
          ))}
        </ul>

        {this.state.user && this.state.user.contentPermissions.length !== 0 && <div data-todo="TODO! TRANSLATE">Sisältöoikeudet</div>}
        <ul>
          {this.state.user && this.state.user.contentPermissions.map((p) => (
            <li key={p.id}>{I18n.t(`permissions.contentpermission.types.${p.content_type}`)} {p.content_id}</li>
          ))}
        </ul>
        <hr />
      </div>
    );
  }
}

export default Permissions;
