import React, { Component } from 'react';
import { Button, ListGroup, InputGroup, Form } from 'react-bootstrap';
import I18n from 'i18n-js';
import AvainiaCore from 'avainia-core-api';
import { Trash, Cog, Edit, Plus, Undo, FolderPlus } from '../../multiview/Icon/Icon.js';
import AvainiaTable from '../../multiview/AvainiaTable/AvainiaTable.js';
import AvainiaTableHeading from '../../multiview/AvainiaTable/AvainiaTableHeading.js';
import AvainiaPanel from '../../multiview/Panels/AvainiaPanel.js';
import Error from '../../multiview/Error/Error.js';
import Loading from '../../multiview/Loading/Loading.js';
import CreateProductModal from './CreateProductModal.js';
import ProductCategoryEditModal from './ProductCategoryEditModal.js';
import ProductEditModal from './ProductEditModal.js';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';
import './Products.scss';

const Modals = {
  editCategory: 1,
  editProduct: 2,
  createProduct: 3,
};

class Products extends Component {

  constructor(props) {
    super(props);

    this.state = {
      productCategories: [],
      productTags: [],
      products: [],
      activeCategory: false,
      categoryname: '',
      editing: false,
      creating: false,
      modal: false,
      loading: true,
      secondaryloading: false,
      error: false,
      secondaryerror: false,
      showHidden: false,
      productToDuplicate: null
    };
  }

  componentDidMount = () => {
    const api = new AvainiaCore(LocalStorageService.getToken);
    api.productCategoriesGet().then((productCategories) => {
      if (productCategories.error) { return this.setState({ error: productCategories.error }); }

      this.setState({ productCategories, loading: false }, this.context.resetTopbar);
    });

    api.tagsGet().then((productTags) => {
      if (productTags.error) { return this.setState({ error: productTags.error }); }
      this.setState({ productTags });
    })
  }

  onChange = (e) => { this.setState({ [e.target.name]: e.target.value }); }

  doSaveCategory = (e) => {
    this.setState({ loading: true }, () => {
      const payload = { name: this.state.categoryname };
      const api = new AvainiaCore(LocalStorageService.getToken);

      api.productCategoriesCreate(payload).then((productCategory) => {
        if (productCategory.error) { return this.setState({ error: productCategory.error }); }
        const { productCategories } = this.state;
        productCategories.push(productCategory);
        this.setState({ productCategories, loading: false, categoryname: '' });
      });
    });
  }

  deleteCategory = (id) => {
    // TODO: implement UI
    // TODO: Confirm
    this.setState({ loading: true }, () => {
      const api = new AvainiaCore(LocalStorageService.getToken);
      api.productCategoriesDelete(id).then((result) => {
        if (result.error) { return this.setState({ error: result.error }); }
        let { productCategories } = this.state;
        productCategories = productCategories.filter((x) => x.id !== id);
        this.setState({ productCategories, loading: false });
      });
    });
  }

  editCategory = (id) => {
    if (this.state.loading) { return; }

    const editingTarget = this.state.productCategories.find((x) => x.id === id);
    this.setState({ editing: editingTarget, modal: Modals.editCategory });
  }

  categoryEditCallback = (id, name) => {
    const { productCategories } = this.state;

    const target = productCategories.find((x) => x.id === id);
    if (target) { target.name = name; }

    this.setState({ productCategories });
  }

  productEditCallback = (product) => {
    const api = new AvainiaCore(LocalStorageService.getToken);

    api.productsGet(this.state.activeCategory.id).then((products) => {
      if (products.error) { return this.setState({ secondaryerror: products.error }); }
      this.setState({ products });
    });

  }

  toggleHideProduct = (product) => {
    this.setState({ loading: 1, error: false }, () => {
      const api = new AvainiaCore(LocalStorageService.getToken);

      const tagsArr = [];
      product.tags.map((tag) => {
        return tagsArr.push(tag.id);
      });
      product.tags = tagsArr;

      product.hidden = product.hidden ? 0 : 1;
      delete product.product_photo;
      delete product.product_context_photo;

      api.productsEdit(product.id, product).then((result) => {
        if (result.error) { return this.setState({ error: result.error, loading: false }); }
        window.location.reload();
      });
    });
  }


  deleteProduct = (product) => {
    // TODO: Confirm
    this.setState({ loading: true }, () => {
      const api = new AvainiaCore(LocalStorageService.getToken);
      api.productsDelete(product.id).then((result) => {
        if (result.error) { return this.setState({ error: result.error }); }
        let { products } = this.state;
        products = products.filter((x) => x.id !== product.id);
        this.setState({ products, loading: false });
      });
    });
  }

  editProduct = (product) => {
    if (this.state.loading) { return; }

    const editingTarget = this.state.products.find((x) => x.id === product.id);
    this.setState({ editing: editingTarget, modal: Modals.editProduct });
  }

  activateCategory = (activeCategory, e) => {
    const act = document.querySelector('.pseudolink.active');
    if (act) { act.classList.toggle('active'); }
    e.target.classList.toggle('active');
    this.setState({ activeCategory, secondaryloading: true }, () => {
      const api = new AvainiaCore(LocalStorageService.getToken);
      api.productsGet(activeCategory.id).then((products) => {
        if (products.error) { return this.setState({ secondaryerror: products.error }); }
        this.setState({ products, loading: false, secondaryloading: false });
      });
    });
  }

  productCreateCallback = (product) => {
    const { products } = this.state;
    products.push(product);
    this.setState({ products, loading: false, error: false });
    window.location.reload();
  }

  hideModal = () => { this.setState({ modal: false }); }

  createProduct = (id) => {
    if (this.state.loading) { return; }

    const creatingTarget = this.state.productCategories.find((x) => x.id === id);
    this.setState({
      activeCategory: creatingTarget,
      productToDuplicate: null,
      modal: Modals.createProduct
    });
  }

  duplicateProduct = (row) => {
    if (this.state.loading) { return; }
    const creatingTarget = this.state.productCategories.find((x) => x.id === row.product_category_id);
    this.setState({
      activeCategory: creatingTarget,
      productToDuplicate: row,
      modal: Modals.createProduct
    });
  }

  renderActions = (cell, row) => {
    return <>
      {!this.state.showHidden && <Trash onClick={() => { this.toggleHideProduct(row); }} className="clickable" /> }
      {this.state.showHidden && <Undo onClick={() => { this.toggleHideProduct(row); }} className="clickable" /> }
      <Cog onClick={() => { this.editProduct(row); }} className="clickable" />
      <FolderPlus onClick={() => { this.duplicateProduct(row); }} className="clickable" />
    </>;
  }

  render() {
    if (this.state.error) { return <Error error={this.state.error} />; }
    if (this.state.loading) { return <Loading />; }

    var filteredProducts = this.state.products;

    if(!this.state.showHidden){
      filteredProducts = filteredProducts.filter((x) => x.hidden === 0);
    } else {
      filteredProducts = filteredProducts.filter((x) => x.hidden === 1);
    }

    return (

      <div className="App-container">
        <h1>{I18n.t('views.products.productcategories')}</h1>
        <ListGroup className="listing">
          {this.state.productCategories.map((pg) => <ListGroup.Item key={pg.id}>
            <span className="clickable" onClick={(e) => this.activateCategory(pg, e)}>{pg.name}</span>
            <Trash onClick={() => { this.deleteCategory(pg.id); }} />
            <Edit onClick={() => { this.editCategory(pg.id); }} />
            <Plus onClick={(e) => { this.activateCategory(pg, e); this.createProduct(pg.id); }} />
          </ListGroup.Item>)}
        </ListGroup>
        {this.state.modal === Modals.editCategory &&
          <ProductCategoryEditModal productCategory={this.state.editing} onHide={this.hideModal} editCallback={this.categoryEditCallback} />
        }

        {this.state.modal === Modals.createProduct &&
          <CreateProductModal 
            onHide={this.hideModal} 
            successCallback={this.productCreateCallback} 
            productCategories={this.state.productCategories} 
            selectedCategory={this.state.activeCategory}  
            productTags={this.state.productTags}
            productToDuplicate={this.state.productToDuplicate}
          />
        }

        <h3>{I18n.t('views.products.create-new-product-category')}</h3>

        <InputGroup>
          <Form.Control placeholder={I18n.t('views.products.name')}type="text" onChange={this.onChange} name="categoryname" value={this.state.categoryname} />
          <InputGroup.Append>
          <Button variant="primary" onClick={this.doSaveCategory}>
            {I18n.t('views.products.button-create')}
          </Button>
          </InputGroup.Append>
        </InputGroup>

        {this.state.activeCategory && <div className="categoryproducts">
          <AvainiaPanel icon={null} heading={null}>

            { this.state.secondaryerror && <Error inline error={this.state.secondaryerror} /> }
            { this.state.secondaryloading && <Loading inline /> }

            <AvainiaTableHeading
              title={`${I18n.t('views.products.products-for')} ${this.state.activeCategory.name}`}
              button={
		           <div style={{display:"flex"}}>
                <Button onClick={() => this.createProduct(this.state.activeCategory.id)} size="m" style={{ marginRight: 15 }}>
                  {I18n.t('views.products.create-new-product')}
                </Button>
	              <Button style={{marginLeft: "15px"}} variant="secondary" onClick={() => {this.setState({ showHidden: !this.state.showHidden });}} size="m">
                  {!this.state.showHidden && <span>Näytä piilotetut</span> } {/* TODO translate */}
                  {this.state.showHidden && <span>Näytä aktiiviset</span> } {/* TODO translate */}
    		        </Button>
		           </div>
              }
            />
            <AvainiaTable data={filteredProducts} keyField="id" columns={[
              { dataField: 'id', text: I18n.t('general.id'), headerStyle: { width: '60px' } },
              { dataField: 'manufacturer', text: I18n.t('views.products.manufacturer') },
              { dataField: 'code', text: I18n.t('views.products.code') },
              { dataField: 'name', text: I18n.t('views.products.name') },
              { dataField: 'description', text: I18n.t('views.products.description') },
              { dataField: 'price', text: I18n.t('views.products.price') },
              { dataField: 'measurements', text: I18n.t('views.products.measurements') },
              { dataField: 'external_link', text: I18n.t('views.products.external_link') },
              { dataField: 'loss_percentage', text: I18n.t('views.products.loss_percentage') },
              { dataField: 'package_size', text: I18n.t('views.products.package_size') },
              { dataField: 'actions', text: I18n.t('general.table-actions'), headerStyle: { width: '120px' }, formatter: this.renderActions },
            ]} />

            {this.state.modal === Modals.editProduct && <ProductEditModal product={this.state.editing} productTags={this.state.productTags} onHide={this.hideModal} editProductCallback={this.productEditCallback} />}
          </AvainiaPanel>
        </div>}
      </div>
    );
  }
}

export default Products;
