import React, { Component } from 'react';
import AvainiaCore from 'avainia-core-api';
import DatePicker from 'react-datepicker';
import { Button, Badge, Modal } from 'react-bootstrap';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';

class QuoteDeadlineModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      quote: {},
      changed: false,
    };
  }

  componentDidMount() {
    const quote = { ...this.props.quote };
    quote.deadline = Date.parse(quote.deadline);
    this.setState({ quote });
  }

  onChangeDatePicker = (date) => {
    const { quote } = this.state;
    quote.deadline = date;
    this.setState({ quote, changed: true });
  };

  saveDeadline = () => {
    const api = new AvainiaCore(LocalStorageService.getToken);

    const payload = this.state.quote;
    payload.deadline = new Date(
      payload.deadline.getTime() - payload.deadline.getTimezoneOffset() * 60000,
    );
    payload.rows.forEach((row) => {
      row.pid = row.product_id;
    });

    api.apartmentMaterialQuoteSave(payload.project_id, payload).then(() => {
      this.props.onHide();
      window.location.reload();
    });
  };

  deleteDeadline = () => {
    const api = new AvainiaCore(LocalStorageService.getToken);

    const payload = this.state.quote;
    payload.deadline = '0000-00-00 00:00:00';

    api.apartmentMaterialQuoteSave(payload.project_id, payload).then(() => {
      this.props.onHide();
      window.location.reload();
    });
  };

  render() {
    return (
      <Modal show={true} onHide={this.props.onHide} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            {this.state.quote.id ? 'Muokkaa aikataulua' : 'Aseta aikataulu'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ minHeight: '400px' }}>
            <p>Tarjous hyväksyttävä viimeistään:</p>
            <DatePicker
              name="deadline"
              selected={this.state.quote.deadline}
              onChange={(date) => this.onChangeDatePicker(date)}
              className="datepicker-input"
              dateFormat="dd.MM.yyyy HH:mm"
              showTimeSelect
              timeFormat="HH:mm"
            />
            <div>
              <label style={{ paddingRight: '15px' }}>
                Muistutus lähetetty:
              </label>
              <input
                id="sent"
                disabled={true}
                type="checkbox"
                checked={this.state.quote.sent}
              />
              <p style={{ paddingTop: '30px' }}>
                Muistutus lähetetään viikkoa ennen deadlinea.
              </p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {this.state.quote.id && (
            <Button variant="secondary" onClick={this.deleteDeadline}>
              Poista
            </Button>
          )}
          <Button
            variant="primary"
            disabled={!this.state.changed}
            onClick={this.saveDeadline}
          >
            Tallenna
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
export default QuoteDeadlineModal;
